import {ReloadOutlined, SettingOutlined} from '@ant-design/icons';
import {Button} from 'antd';

type Props = {
  tableName: string;
  toggleSetting: (value: boolean) => void;
  reloadData?: () => void;
  headerWidgers?: JSX.Element[];
};

const TableHeader: React.FC<Props> = ({
  toggleSetting,
  reloadData,
  headerWidgers,
  tableName,
}) => {
  return (
    <div className="bg-lightGray p-4 flex items-center gap-3">
      <div className="flex-1">
        <div className="font-medium text-lg">{tableName}</div>
      </div>
      <Button
        className="hidden sm:block text-sm"
        shape="round"
        type="text"
        onClick={() => toggleSetting(true)}
        icon={<SettingOutlined />}
      >
        Хүснэгтийн тохиргоо
      </Button>
      <Button
        className="block sm:hidden text-sm"
        shape="circle"
        onClick={() => toggleSetting(true)}
        icon={<SettingOutlined />}
      />
      {headerWidgers}
      {reloadData !== undefined && (
        <Button
          className="text-sm"
          shape="circle"
          onClick={reloadData}
          icon={<ReloadOutlined />}
        />
      )}
    </div>
  );
};

export default TableHeader;
