import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Input,
  Modal,
  Alert,
  message,
  Form,
  DatePicker,
  Upload,
} from 'antd';

import {UploadOutlined} from '@ant-design/icons';
import type {UploadProps} from 'antd';
import * as _ from 'lodash';

import LogoImage from '../../assets/images/logo.png';
import {AuditGuideModel} from '../../types';
import {uploadImageBlob} from '../../helpers/firebase_storage';
import {dateFormat} from '../../constants/app_data';
import {UserContext} from '../../context/app.contexts';
import {saveData} from '../../helpers/firestore';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {auditRoles} from '../../helpers/role_helper';
import {guidanceRules} from '../../helpers/validations';
import type {UploadFile} from 'antd/es/upload/interface';

dayjs.extend(customParseFormat);

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  auditUid: string;
  selectedGuide?: AuditGuideModel;
  otherGuides: AuditGuideModel[];
};

const GuidanceEdit: React.FC<Props> = ({
  open = false,
  toggle,
  auditUid,
  selectedGuide,
  otherGuides,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const {user, userRole} = useContext(UserContext);

  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [writePermitted, setWritePermitted] = useState(false);
  const [attachment, setAttachment] = useState<string | Blob>();
  const onCancel = () => {
    form.resetFields();
    setError('');
    setLoadingSave(false);
    toggle(false);
  };
  const props: UploadProps = {
    name: 'file',
    accept: 'image/*,.pdf',
    multiple: false,
    onChange({file, fileList}) {
      if (file.status !== 'uploading') {
        if (file.status === 'removed') {
          setAttachment(undefined);
          setFileList([]);
        }
      }
    },
  };

  const handleUpload = async (file: Blob) => {
    try {
      const result = await uploadImageBlob(
        file,
        `files/audit/${auditUid}/guidances`,
      );
      return result as string;
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: e ?? 'File upload хийхэд алдаа гарлаа!',
      });
      return '';
    }
  };

  const validate = () => {
    if (_.isEmpty(auditUid) || loadingSave || !writePermitted) return false;
    return true;
  };
  const addFile = async (file: any) => {
    setAttachment(file as Blob);
    setFileList([file]);
    setFileChanged(true);
  };
  const onSave = async (values: any) => {
    if (!attachment) {
      messageApi.open({
        type: 'error',
        content: `Тушаалын хавсралт file заавал оруулна уу`,
      });
      return;
    }
    if (!validate() || _.isEmpty(user) || _.isEmpty(user.uid)) return;
    setLoadingSave(true);
    let attachmentUrl = selectedGuide?.attachmentUrl.toString();

    if (fileChanged) {
      attachmentUrl = attachment?.toString();
      if (attachment instanceof Blob)
        attachmentUrl = await handleUpload(attachment as Blob);
    }

    const newGuideData: AuditGuideModel = {
      ...values,
      guidanceDate: dayjs(values.guidanceDate).format(dateFormat),
      guidanceStart: dayjs(values.guidanceStart).format(dateFormat),
      guidanceEnd: dayjs(values.guidanceEnd).format(dateFormat),
      attachmentUrl: attachmentUrl || '',
    };
    await saveData(
      `audits/${auditUid}/guides`,
      selectedGuide?.uid,
      newGuideData,
    );
    await saveData(`audits`, auditUid, {
      guideFile: attachmentUrl || '',
      guide: [...otherGuides, newGuideData]
        .map((g) => `${g.guidanceDate} - ний өдрийн ${g.number} тоот`)
        .join(', '),
    });
    onCancel();
    messageApi.open({
      type: 'success',
      content: `Тушаал ${!selectedGuide ? 'нэмэгдлээ' : 'засагдлаа'}.`,
    });
  };

  useEffect(() => {
    setWritePermitted(auditRoles.addEditAuditTeam.includes(userRole));
  }, [userRole]);

  useEffect(() => {
    if (_.isEmpty(selectedGuide)) {
      form.resetFields();
      setAttachment(undefined);
      setFileChanged(false);
      setFileList([]);
    } else {
      form.setFieldsValue({
        ...selectedGuide,
        guidanceDate: dayjs(selectedGuide?.guidanceDate, dateFormat),
        guidanceStart: dayjs(selectedGuide?.guidanceStart, dateFormat),
        guidanceEnd: dayjs(selectedGuide?.guidanceEnd, dateFormat),
      });
      setAttachment((selectedGuide as AuditGuideModel).attachmentUrl);
      setFileList([
        {
          uid: '1',
          name: selectedGuide?.attachmentUrl,
          status: 'done',
          url: selectedGuide?.attachmentUrl,
        },
      ]);
      setFileChanged(false);
    }
  }, [selectedGuide]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            className="rounded-xl"
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Аудитын тушаал
            </div>
            <Form form={form} onFinish={onSave} scrollToFirstError>
              <div>Тушаалын огноо</div>
              <Form.Item rules={guidanceRules.guidanceDate} name="guidanceDate">
                <DatePicker placeholder="Тушаалын огноо" className="w-full" />
              </Form.Item>
              <div>Тушаалын дугаар</div>
              <Form.Item rules={guidanceRules.number} name="number">
                <Input placeholder="Тушаалын дугаар" />
              </Form.Item>
              <div>Удирдамжийн хамрах хүрээ</div>
              <Form.Item rules={guidanceRules.scope} name="scope">
                <Input.TextArea placeholder="Удирдамжийн хамрах хүрээ" />
              </Form.Item>
              <div>Удирдамжийн эхлэх хугацаа</div>
              <Form.Item
                rules={guidanceRules.guidanceStart}
                name="guidanceStart"
              >
                <DatePicker
                  placeholder="Удирдамжийн эхлэх хугацаа"
                  className="w-full"
                />
              </Form.Item>
              <div>Удирдамжийн дуусах хугацаа</div>
              <Form.Item rules={guidanceRules.guidanceEnd} name="guidanceEnd">
                <DatePicker
                  placeholder="Удирдамжийн дуусах хугацаа"
                  className="w-full"
                  format={dateFormat}
                />
              </Form.Item>
              <div>Тушаалын хавсралт</div>
              <Upload
                fileList={fileList}
                {...props}
                beforeUpload={(file) => {
                  addFile(file);
                  return false;
                }}
              >
                {_.isEmpty(attachment) && (
                  <>
                    <Button
                      icon={<UploadOutlined />}
                      className="mt-2"
                      shape="round"
                    >
                      Файл оруулах
                    </Button>
                  </>
                )}
              </Upload>
            </Form>
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GuidanceEdit;
