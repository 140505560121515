import {functions} from '../firebase';
import * as _ from 'lodash';
import {AuthRoles} from '../types';
import {isAllowedRole} from './role_helper';

export const createAppUser = async (
  email: string,
  role: AuthRoles,
  userData: any,
) => {
  try {
    console.log('createAppUser starting', email, role);
    if (!isAllowedRole(role))
      return {status: false, msg: 'Role is not allowed'};
    const data = await functions.httpsCallable('createAppUser')({
      email,
      role,
      userData,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const updateRole = async (auditorUid: string, role: AuthRoles) => {
  try {
    if (!isAllowedRole(role))
      return {status: false, msg: 'Role is not allowed'};
    const data = await functions.httpsCallable('updateAdminRole')({
      auditorUid,
      role,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const verifyFieldRequest = async (requestUid: string) => {
  try {
    const data = await functions.httpsCallable('verifyFieldHistory')({
      requestUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const changeContractType = async (
  certificateRequestUid: string,
  contractType: string,
) => {
  try {
    const data = await functions.httpsCallable('changeContractType')({
      certificateRequestUid,
      contractType,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const assignCertificateRequest = async (
  certificateRequestUid: string,
  contractorUid: string,
) => {
  try {
    console.log('assignCertificateRequest');
    const data = await functions.httpsCallable('assignCertificateRequest')({
      certificateRequestUid,
      contractorUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const assignFieldRequest = async (
  fieldRequestUid: string,
  contractorUid: string,
) => {
  try {
    console.log('assignFieldRequest');
    const data = await functions.httpsCallable('assignFieldRequest')({
      fieldRequestUid,
      contractorUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const verifyCertificateRequest = async (requestUid: string) => {
  try {
    const data = await functions.httpsCallable('verifyCertificateRequest')({
      requestUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const companyRegistrationRequest = async (request: any) => {
  try {
    const data = await functions.httpsCallable('companyRegistrationRequest')(
      request,
    );
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const verifyCompanyRequest = async (requestUid: string) => {
  try {
    const data = await functions.httpsCallable('verifyCompanyRequest')({
      requestUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const rejectCompanyRequest = async (requestUid: string) => {
  try {
    const data = await functions.httpsCallable('rejectCompanyRequest')({
      requestUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const createContractApplication = async (contractUid: string) => {
  try {
    const data = await functions.httpsCallable('createContractApplication')({
      contractUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const sendApplication = async (requestId: string, companyId: string) => {
  try {
    const data = await functions.httpsCallable('sendApplication')({
      companyId,
      requestId,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const acceptApplication = async (requestUid: string) => {
  try {
    const data = await functions.httpsCallable('acceptApplication')({
      requestUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const rejectApplication = async (
  requestUid: string,
  rejectReason: string,
) => {
  try {
    const data = await functions.httpsCallable('rejectApplication')({
      requestUid,
      rejectReason,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const assignAuditMembers = async (
  auditUid: string,
  members: string[],
  teamLead: string,
  boardMembers: string[],
  applicationName: string,
) => {
  try {
    const data = await functions.httpsCallable('assignAuditMembers')({
      auditUid,
      members,
      teamLead,
      boardMembers,
      applicationName,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const qrHistory = async (qrCode: string) => {
  try {
    const data = await functions.httpsCallable('companyHistory')({
      qrCode,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const sendAudit = async (auditUid: string) => {
  try {
    const data = await functions.httpsCallable('sendAudit')({
      auditUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const sendToBoard = async (auditUid: string) => {
  try {
    const data = await functions.httpsCallable('sendToBoard')({
      auditUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};

export const finishAudit = async (
  auditUid: string,
  applicationRequestUid: string,
) => {
  try {
    const data = await functions.httpsCallable('finishAudit')({
      auditUid,
      applicationRequestUid,
    });
    return _.get(data, 'data');
  } catch (e: any) {
    console.log(e);
    return {status: false, msg: e.toString()};
  }
};
