import React, {useContext, useEffect, useState} from 'react';
import {Button, Input, Modal, message, Form, Select, DatePicker} from 'antd';
import {UserContext} from '../../context/app.contexts';
import {getUidFirebase, saveData, useCollection} from '../../helpers/firestore';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import {CertificateModel} from '../../types';
import {dateFormat} from '../../constants/app_data';
import moment from 'moment';

type Props = {
  open?: boolean;
  selected?: any;
  type: string;
  applicantName: string;
  applicantUid: string;
  certificateUid?: string;
  toggle: (value: boolean) => void;
  onSuccess?: () => void;
};

const CreateCertificate: React.FC<Props> = ({
  open = false,
  toggle,
  type,
  applicantName,
  applicantUid,
  certificateUid,
  selected,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const {userCompany} = useContext(UserContext);
  const [certificatesData] = useCollection('certificates');
  const [companies] = useCollection('companies');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    form.resetFields();
    setLoadingSave(false);
    toggle(false);
  };

  const onSave = async (values: any) => {
    setLoadingSave(true);
    let docUid = selected?.uid;
    let cert = certificatesData.find(
      (c: any) => c.uid === values.certificate,
    ) as CertificateModel;
    if (_.isEmpty(selected) && _.isEmpty(docUid)) {
      docUid = await getUidFirebase();
    }
    const data = {
      ...values,
      type,
      certificateName: cert?.name || '',
      certificateDesc: cert?.desc || '',
      auditCompanyUid: userCompany,
      applicantUid,
      applicantQr:
        (companies as any[]).find((c) => c.uid === applicantUid)?.qrCode || '',
      date: dayjs(values.date).format(dateFormat),
      certValidDate: dayjs(values.certValidDate).format(dateFormat),
    };
    await saveData('grantedCertificates', docUid, data, _.isEmpty(selected));
    if (onSuccess != null) onSuccess();
    onCancel();
    messageApi.open({
      type: 'success',
      content: 'Гэрчилгээний мэдээлэл хадгалагдлаа.',
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...selected,
      date: dayjs(
        selected?.date ?? moment(new Date()).format(dateFormat),
        dateFormat,
      ),
      certValidDate: dayjs(
        selected?.certValidDate ?? moment(new Date()).format(dateFormat),
        dateFormat,
      ),
    });
  }, [selected]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key={1}
            type="primary"
            shape="round"
            className="w-full"
            disabled={loadingSave}
            loading={loadingSave}
            onClick={() => {
              form.submit();
            }}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="px-8">
            <div className="font-medium text-lg text-center my-5">
              Гэрчилгээний мэдээлэл
            </div>
            <Form form={form} onFinish={onSave} scrollToFirstError>
              <div className="font-medium">Компани / Иргэн</div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Компани/Иргэн сонгоно уу!',
                  },
                ]}
                name="applicantName"
                initialValue={applicantName}
              >
                <Input />
              </Form.Item>
              <div className="font-medium">Гэрчилгээ</div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Баталгаажуулалтын төрөл сонгоно уу!',
                  },
                ]}
                name="certificate"
                initialValue={certificateUid}
              >
                <Select
                  disabled={certificateUid !== null}
                  options={certificatesData.map((c: any) => ({
                    value: c.uid,
                    label: c.name,
                  }))}
                />
              </Form.Item>
              <div className="font-medium">Гэрчилгээний дугаар</div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Гэрчилгээний дугаар сонгоно уу!',
                  },
                ]}
                name="certificateNumber"
              >
                <Input />
              </Form.Item>
              <div className="font-medium">Компаний/Иргэний хаяг</div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Компаний/Иргэний хаяг оруулна уу!',
                  },
                ]}
                name="applicantAddress"
              >
                <Input.TextArea showCount maxLength={200} />
              </Form.Item>
              <div className="font-medium">Стандартын шаардлага</div>
              <Form.Item
                rules={[
                  {required: true, message: 'Стандартын шаардлага оруулна уу!'},
                ]}
                name="desc"
              >
                <Input.TextArea showCount />
              </Form.Item>
              <div className="font-medium">Хамрах хүрээ</div>
              <Form.Item
                rules={[{required: true, message: 'Хамрах хүрээ оруулна уу!'}]}
                name="auditScope"
              >
                <Input.TextArea showCount maxLength={200} />
              </Form.Item>
              <div>Олгосон огноо</div>
              <Form.Item
                rules={[{required: true, message: 'Олгосон огноо оруулна уу!'}]}
                name="date"
              >
                <DatePicker
                  placeholder="Олгосон огноо"
                  className="w-full"
                  format={dateFormat}
                />
              </Form.Item>
              <div>Хүчинтэй огноо</div>
              <Form.Item
                rules={[
                  {required: true, message: 'Хүчинтэй огноо оруулна уу!'},
                ]}
                name="certValidDate"
              >
                <DatePicker
                  placeholder="Хүчинтэй огноо"
                  className="w-full"
                  format={dateFormat}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateCertificate;
