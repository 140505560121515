import {Link, useNavigate, useParams} from 'react-router-dom';
import {useCollection, useDoc} from '../../helpers/firestore';
import {Button, Col, Row} from 'antd';
import {Routes} from '../../routes/route_helper';
import {ArrowLeftOutlined, PlusOutlined} from '@ant-design/icons';
import {ApplicationRequestModel, AuditModel, AuditStatus} from '../../types';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import DataTable from '../../components/table/Index';
import {useContext, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import AuditAdd from './AuditAdd';
import {cityData} from '../../constants/city_data';
import {
  appRequestAuditTypes,
  auditorAuditTypes,
} from '../../constants/app_data';
import {auditRoles, certificateRoles} from '../../helpers/role_helper';
import {auditStatusTag, emptyText} from '../../helpers/theme_helper';
import CreateCertificate from '../certificates/CreateCertificate';
import {firestore} from '../../firebase';

const ApplicationAudits = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole, user} = useContext(UserContext);
  const [applicationData, applicationLoading] = useDoc(
    `applicationRequests/${id}`,
  );
  const [company, companyLoading] = useDoc(
    `companies/${
      (applicationData as ApplicationRequestModel).companyUid || '0'
    }`,
  );
  const [farmer, farmerLoading] = useDoc(
    `users/${(applicationData as ApplicationRequestModel).farmerUid || '0'}`,
  );
  const [audits, auditsLoading] = useCollection('audits', 'createdAt', {
    field: 'applicationRequestUid',
    op: '==',
    value: id,
  });
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const [openCert, setOpenCert] = useState(false);
  const toggleModalCert = () => setOpenCert(!openCert);

  const goBack = () => navigate(Routes.applicationRequests);

  const onCertificateCreated = async () => {
    await firestore.doc(`applicationRequests/${id}`).set(
      {
        certificateGranted: true,
      },
      {merge: true},
    );
    await firestore.collection(`applicationRequests/${id}/logs`).add({
      value: 'certificateGranted',
      description: 'Гэрчилгээ олгосон',
      createdAt: new Date(),
      by: user.uid,
    });
  };

  const columns: ColumnsType<AuditModel> = [
    {
      key: 'auditType',
      title: 'Аудитын төрөл',
      dataIndex: 'auditType',
      render: (_, record) => (
        <>
          {auditRoles.viewAuditDetail.includes(userRole) ? (
            <Link
              target="_blank"
              to={Routes.compilePath(Routes.auditDetail, {id: record.uid})}
            >
              {
                auditorAuditTypes.find((a) => a.value === record.auditType)
                  ?.name
              }
            </Link>
          ) : (
            <>
              {
                auditorAuditTypes.find((a) => a.value === record.auditType)
                  ?.name
              }
            </>
          )}
        </>
      ),
    },
    {
      key: 'auditNumber',
      title: 'Аудитын дугаар',
      dataIndex: 'auditNumber',
    },
    {
      key: 'guide',
      title: 'Тушаал',
      dataIndex: 'guide',
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (_, record) => <>{auditStatusTag(record.status, record.type)}</>,
    },
    {
      key: 'auditReport',
      title: 'Тайлан',
      dataIndex: 'auditReport',
      render: (_, record) => (
        <>
          {record.status !== AuditStatus.created ? (
            <Link
              target="_blank"
              to={Routes.compilePath(Routes.auditReport, {id: record.uid})}
            >
              Тайлан харах
            </Link>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      key: 'createdAt',
      title: 'Огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Аудитын ажлууд
        </Button>
        {certificateRoles.createEdit.includes(userRole) && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Button
              type="primary"
              shape="round"
              onClick={toggleModalCert}
              disabled={
                applicationData.certificateGranted === true ||
                applicationLoading
              }
              icon={<PlusOutlined />}
            >
              {applicationData.certificateGranted === true
                ? 'Гэрчилгээ үүсгэсэн'
                : 'Гэрчилгээ үүсгэх'}
            </Button>
          </div>
        )}
      </div>
      <hr />
      <div className="card-container p-6 my-8">
        <div className="text-xl font-medium">Өргөдлийн мэдээлэл</div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <div className="mt-5 text-sm text-gray">
              {(applicationData as ApplicationRequestModel).type !== 'finy'
                ? 'Байгууллага'
                : 'Тариаланч'}
            </div>
            <div className="mt-1">
              {(applicationData as ApplicationRequestModel).type !== 'finy'
                ? (applicationData as ApplicationRequestModel).companyName
                : (applicationData as ApplicationRequestModel).farmerName}
            </div>
            <div className="mt-5 text-sm text-gray">Баталгаажуулалт</div>
            <div className="mt-1">
              {(applicationData as ApplicationRequestModel).applicationName}
            </div>
          </Col>
          <Col xs={24} sm={12}>
            {(applicationData as ApplicationRequestModel).type !== 'finy' ? (
              <>
                <div className="mt-5 text-sm text-gray">
                  Баталгаажуулалт хүсч буй хамрах хүрээ
                </div>
                <div className="mt-1">
                  {(applicationData as ApplicationRequestModel).auditScope ||
                    emptyText}
                </div>
              </>
            ) : (
              <>
                <div className="mt-5 text-sm text-gray">Гэрээний дугаар</div>
                <div className="mt-1">
                  {(applicationData as ApplicationRequestModel)
                    .contractNumber || emptyText}
                </div>
              </>
            )}
            <div className="mt-5 text-sm text-gray">Аудитын төрөл</div>
            <div className="mt-1">
              {
                appRequestAuditTypes.find(
                  (a) =>
                    a.value ===
                    (applicationData as ApplicationRequestModel).auditType,
                )?.name
              }
            </div>
          </Col>
          {(applicationData as ApplicationRequestModel).type === 'local' && (
            <Col xs={24} sm={12}>
              <div className="mt-5">
                <Link
                  target="_blank"
                  to={Routes.compilePath(Routes.applicationRequestInfo, {id})}
                >
                  Өргөдлийн дэлгэрэнгүй харах
                </Link>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <DataTable
        tableName="Аудитын ажлууд"
        columns={columns}
        data={audits}
        loading={auditsLoading}
        headerWidgers={
          auditRoles.addEditAudit.includes(userRole)
            ? [
                <Button
                  key={'add1'}
                  className="hidden sm:block text-sm"
                  shape="round"
                  disabled={auditsLoading || companyLoading || farmerLoading}
                  onClick={toggleModal}
                  icon={<PlusOutlined />}
                >
                  Аудит нэмэх
                </Button>,
                <Button
                  key={'add2'}
                  className="block sm:hidden text-sm"
                  shape="circle"
                  onClick={toggleModal}
                  disabled={auditsLoading || companyLoading || farmerLoading}
                  icon={<PlusOutlined />}
                />,
              ]
            : []
        }
      />
      <CreateCertificate
        open={openCert}
        toggle={toggleModalCert}
        onSuccess={onCertificateCreated}
        certificateUid={
          (applicationData as ApplicationRequestModel).certificateUid
        }
        applicantUid={
          applicationData.type === 'local'
            ? applicationData.companyUid
            : applicationData.farmerUid
        }
        applicantName={
          applicationData.type === 'local'
            ? applicationData.companyName
            : applicationData.farmerName
        }
        type={applicationData.type}
      />
      <AuditAdd
        open={open}
        toggle={toggleModal}
        applicationRequestUid={id || ''}
        applicationUid={
          (applicationData as ApplicationRequestModel).applicationUid
        }
        applicationName={
          (applicationData as ApplicationRequestModel).applicationName
        }
        location={`${
          applicationData.type === 'local'
            ? cityData.find((c) => c.code === company.city)?.name
            : farmer.city
        }, ${(applicationData.type === 'local' ? company : farmer).district}`}
        companyUid={(applicationData as ApplicationRequestModel).companyUid}
        companyName={(applicationData as ApplicationRequestModel).companyName}
        farmerUid={(applicationData as ApplicationRequestModel).farmerUid}
        farmerName={(applicationData as ApplicationRequestModel).farmerName}
        type={(applicationData as ApplicationRequestModel).type}
      />
    </div>
  );
};

export default ApplicationAudits;
