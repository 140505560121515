import React, {useContext, useState} from 'react';
import {Button, Input, Modal, Alert, message, Select} from 'antd';
import * as _ from 'lodash';

import LogoImage from '../../assets/images/logo.png';
import {AuthRoles} from '../../types';

import {authRoleData, expression} from '../../constants/app_data';
import {createAppUser} from '../../helpers/firebase_functions';
import {auditorRoles} from '../../helpers/role_helper';
import {UserContext} from '../../context/app.contexts';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  onSuccess: () => void;
};

const AuditAuditorAdd: React.FC<Props> = ({
  open = false,
  toggle,
  onSuccess,
}) => {
  const {userRole} = useContext(UserContext);
  const [role, setRole] = useState<AuthRoles | undefined>();
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setEmail('');
    setCode('');
    setName('');
    setError('');
    setRole(undefined);
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (
      !auditorRoles.addEditAuditor.includes(userRole) ||
      _.isEmpty(role) ||
      _.isEmpty(email) ||
      _.isEmpty(code) ||
      _.isEmpty(name) ||
      !expression.test(email) ||
      loadingSave
    )
      return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    const result = await createAppUser(email, role!, {code, name});
    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.msg,
      });
    } else {
      onCancel();
      onSuccess();
      messageApi.open({
        type: 'success',
        content: `${
          authRoleData.find((r) => r.value === role)?.label
        } нэмэгдлээ.`,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            className="rounded-xl"
            type="primary"
            onClick={onSave}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Аудитор нэмэх
            </div>
            <Input
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Имейл"
              className="mb-3"
            />
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Нэр"
              className="mb-3"
            />
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Код"
              className="mb-3"
            />
            <Select
              allowClear
              className="w-full mb-3"
              value={role}
              placeholder="Эрх"
              onChange={setRole}
              options={authRoleData.map((a: any) => ({
                label: a.label,
                value: a.value,
              }))}
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuditAuditorAdd;
