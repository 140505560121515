import {Col, Row} from 'antd';
import {emptyText} from '../../../helpers/theme_helper';

type Props = {
  auditCompanyUid?: String;
};
const tableClass =
  'border-t-0 border-l-0 border-r-0 border-solid border-surface';

const AuditReportAuditor: React.FC<Props> = ({auditCompanyUid}) => {
  return (
    <div className="border my-4 border-solid border-surface p-5">
      <div className="mb-4 text-info">2.1 Нэр, үйл ажиллагаа</div>
      <Row gutter={[16, 12]}>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Нэр</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>"Эс Эф Си Эс" ХХК</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Үйл ажиллагааны чиглэл</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            ISO 9001, ISO 14001, ISO 45001, ISO 50001, ISO 22000, ISO 27001, ISO
            17065 олон улсын стандартын сургалт, “Органик бүтээгдэхүүн”, “Хөдөө
            аж ахуйн зохистой дадал”-ын баталгаажуулалтын аудит, Бүтээгдэхүүний
            тохирол
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Бусад үйл ажиллагаа</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            Чанар, байгаль орчин, хөдөлмөрийн аюулгүй байдал ба эрүүл ахуйн
            менежментийн тогтолцооны аудит ба аудитын сургалт
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Салбар нэгж</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>1</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Бусад мэдээлэл</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{emptyText}</div>
        </Col>
      </Row>
      <div className="mb-4 mt-10 text-info">2.2 Хаяг, холбоо барих</div>
      <Row gutter={[16, 12]}>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Гүйцэтгэх захирал</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>Г.Ундаръяа</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Утас</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>(+976) 7014-9762, 85022022, 94999347</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Хаяг байршил</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>Улаанбаатар, Баянгол ,2 хороо, 2 хороолол,63 Б байр, 1 тоот</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Имэйл хаяг, цахим хуудас</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            sfcscertification@gmail.com, sfcscertification1@gmail.com ,
            http://www.sfcs.mn/
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuditReportAuditor;
