import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {AuditModel, AuthRoles} from '../../types';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import {auditRoles} from '../../helpers/role_helper';
import DataTable from '../../components/table/Index';
import {tcAuditListBoard} from '../../helpers/table_columns';
import {Input} from 'antd';

const AuditSent = () => {
  const {userRole, user} = useContext(UserContext);
  const [audits, setAudits] = useState<AuditModel[]>([]);
  const [showData, setShowData] = useState<AuditModel[]>([]);
  const [searchNumber, setSearchNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    if (
      !_.isEmpty(userRole) &&
      !_.isEmpty(user.uid) &&
      auditRoles.viewSent.includes(userRole)
    ) {
      setLoading(true);
      let ref: any = firestore.collection('audits');
      if (userRole === AuthRoles.AUDIT_AUDITOR) {
        ref = ref.where('members', 'array-contains', user.uid);
      }
      const data = await ref.orderBy('sentAt', 'desc').get();
      setAudits(
        data.docs.map((d: any) => ({...d.data(), uid: d.id} as AuditModel)),
      );
      setShowData(
        data.docs.map((d: any) => ({...d.data(), uid: d.id} as AuditModel)),
      );
      setLoading(false);
    }
  };

  const search = () => {
    setShowData(
      audits.filter((d) =>
        d.auditNumber.toLowerCase().includes(searchNumber.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    getData();
  }, [userRole, user]);

  return (
    <DataTable
      tableName="Аудитын ажлууд"
      columns={tcAuditListBoard}
      data={showData}
      loading={loading}
      reloadData={getData}
      onSearch={search}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Дугаар"
          value={searchNumber}
          onChange={(e) => {
            setSearchNumber(e.target.value);
          }}
        />,
      ]}
    />
  );
};

export default AuditSent;
