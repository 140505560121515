import {Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import * as _ from 'lodash';

const CertificateDocument = ({certificate = {}}) => {
  const styles = StyleSheet.create({
    page: {
      width: '100vw',
      height: '100vh',
      fontSize: 12,
      fontFamily: 'Roboto',
      flexDirection: 'row',
      display: 'flex',
      fontWeight: 'normal',
    },
  });

  return (
    <Page size="A4" style={styles.page}>
      <View style={{width: '25vw'}} fixed={true}>
        <Image
          src="/sidebar.png"
          style={{width: '100%', height: '100vh', objectFit: 'cover'}}
        />
      </View>
      <View style={{flex: 1, paddingHorizontal: 28, paddingVertical: 12}}>
        <Text style={{fontSize: 43, textAlign: 'center'}}>ГЭРЧИЛГЭЭ</Text>
        <Text style={{fontSize: 15, textAlign: 'center', color: '#1865D8'}}>
          {certificate.certificateNumber}
        </Text>
        <View style={{display: 'flex', flexDirection: 'row', marginTop: 32}}>
          <Text style={{fontWeight: 'medium', fontSize: 16}}>
            "{certificate.applicantName}"
          </Text>
          {certificate.type === 'local' && (
            <Text style={{fontSize: 16}}> XXK</Text>
          )}
        </View>
        <Text style={{fontSize: 10, marginTop: 4}}>
          {certificate.applicantAddress}
        </Text>
        <Text
          style={{
            marginTop: 24,
            fontWeight: 'medium',
            marginLeft: 16,
          }}
        >
          {certificate.certificateDesc} - ыг
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 8,
          }}
        >
          <Text>{certificate.desc}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 24,
          }}
        >
          <Text>Хамрах хүрээ: </Text>
          <Text style={{fontWeight: 'medium'}}>{certificate.auditScope}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 80,
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <Text>Олгосон огноо: {certificate.date}</Text>
          <Text>Хүчинтэй огноо: {certificate.certValidDate}</Text>
        </View>
        {/* <Text style={{flex: 1, marginTop: 4}}>
          Анхны баталгаажуулалт: 2023 он
        </Text> */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
          }}
        >
          <View>
            <Text>Гүйцэтгэх захирал</Text>
            <Text>Эс Эф Си Эс XXK</Text>
          </View>
          <Image
            src="/signature.png"
            style={{width: 120, height: 120, objectFit: 'contain'}}
          />
          <Text>Г. УНДАРЬЯА</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 32,
            gap: 10,
          }}
        >
          <Image src="/mnas.png" style={{height: 80}} />
          <Image src="/sfcs.png" style={{height: 80}} />
          {/* <Image
            src={{
              uri: 'https://cdn.finy.app/images/certificate/1686831255490.png',
              method: 'GET',
              headers: {'Cache-Control': 'no-cache'},
              body: '',
            }}
            style={{height: 80}}
          /> */}
          <View style={{flex: 1}} />
          {!_.isEmpty(certificate.applicantQr) && (
            <Image src={certificate.applicantQr} style={{height: 80}} />
          )}
        </View>
        <Text style={{marginTop: 32, marginBottom: 12, fontSize: 8}}>
          www.sfcs.mn, Монгол улс, Улаанбаатар хот, Баянгол дүүрэг
        </Text>
      </View>
    </Page>
  );
};

export default CertificateDocument;
