export const cityData = [
  {
    code: 'UB',
    name: 'Улаанбаатар',
  },
  {
    code: 'TUV',
    name: 'Төв аймаг',
  },
  {
    code: 'BUL',
    name: 'Булган аймаг',
  },
  {
    code: 'UK',
    name: 'Өвөрхангай аймаг',
  },
  {
    code: 'GA',
    name: 'Говь-алтай аймаг',
  },
  {
    code: 'KHO',
    name: 'Ховд аймаг',
  },
  {
    code: 'UVS',
    name: 'Увс аймаг',
  },
  {
    code: 'BK',
    name: 'Баянхонгор аймаг',
  },
  {
    code: 'AK',
    name: 'Архангай аймаг',
  },
  {
    code: 'KHE',
    name: 'Хэнтий аймаг',
  },
  {
    code: 'DUG',
    name: 'Дундговь аймаг',
  },
  {
    code: 'UG',
    name: 'Өмнөговь аймаг',
  },
  {
    code: 'BU',
    name: 'Баян-өлгий аймаг',
  },
  {
    code: 'DOR',
    name: 'Дорнод аймаг',
  },
  {
    code: 'SB',
    name: 'Сүхбаатар аймаг',
  },
  {
    code: 'DU',
    name: 'Дархан-уул аймаг',
  },
  {
    code: 'SEL',
    name: 'Сэлэнгэ аймаг',
  },
  {
    code: 'ORK',
    name: 'Орхон аймаг',
  },
  {
    code: 'KHUV',
    name: 'Хөвсгөл аймаг',
  },
  {
    code: 'ZAV',
    name: 'Завхан аймаг',
  },
  {
    code: 'DOG',
    name: 'Дорноговь аймаг',
  },
  {
    code: 'GS',
    name: 'Говьсүмбэр аймаг',
  },
];
