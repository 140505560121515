import {compile} from 'path-to-regexp';

export const Routes = {
  dashboard: '/',
  otp: `/otp`,
  auditors: `/audit/auditors`,
  auditorDetail: `/audit/auditor/:id`,
  //application
  standarts: `/standart/list`,
  standartDetail: `/standart/:id`,
  finyRequests: `/finy/request/list`,
  finyContractorRequests: `/finy/contractor/list`,
  finyRequestDetail: `/finy/request/:id`,
  //contract
  contracts: `/contract/list`,
  certContracts: `/contract/certlist`,
  contractDetail: `/contract/:id`,
  //company registration requests
  companySendRequest: '/company/request/send',
  companyRequests: '/company/request/list',
  companyRequestDetail: '/company/request/:id',
  companyList: '/company/list',
  companyDetail: '/company/:id',
  //application request
  applicationRequests: '/application/request/list',
  applicationRequestCreate: '/application/request/create',
  applicationRequestDetail: '/application/request/:id',
  applicationRequestInfo: '/application/request/show/:id',
  certificateList: '/certificate/list',
  //audit
  applicationAudits: '/application/:id/audits',
  auditDetail: '/audit/detail/:id',
  auditReport: '/audit/report/:id',
  auditSent: '/audit/sent',
  auditBoard: '/audit/board',
  //auth
  login: '/login',
  notfound: '/notfound',
  qr: '/qr/:id',
  certificateView: '/certificate/view/:id',
  compilePath(path: string, param: object) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};
