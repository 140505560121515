import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {ContractModel, ContractStatus, ContractType} from '../../types';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import DataTable from '../../components/table/Index';
import {Routes} from '../../routes/route_helper';
import {cityData} from '../../constants/city_data';
import {firestore} from '../../firebase';
import {contractRoles} from '../../helpers/role_helper';
import ContractPDF from './ContractPDF';
import * as _ from 'lodash';
import {Button, Input} from 'antd';
import {MenuOutlined} from '@ant-design/icons';

const CertContractList = () => {
  const navigate = useNavigate();
  const {user, userRole} = useContext(UserContext);
  const [requests, setRequests] = useState<ContractModel[]>([]);
  const [showData, setShowData] = useState<ContractModel[]>([]);
  const [searchNumber, setSearchNumber] = useState('');
  const [searchAuditNumber, setSearchAuditNumber] = useState('');
  const [requestLoading, setRequestLoading] = useState(false);
  const [selected, setSelected] = useState<any | undefined>();
  const [openViewer, setOpenViewer] = useState(false);
  const toggleModalViewer = () => setOpenViewer(!openViewer);

  const columns: ColumnsType<ContractModel> = [
    {
      key: 'contractNumber',
      title: 'Гэрээний дугаар',
      dataIndex: 'contractNumber',
      render: (_, record) => (
        <div
          className="text-info cursor-pointer"
          onClick={() => {
            setSelected(record);
            toggleModalViewer();
          }}
        >
          {record.contractNumber}
        </div>
      ),
    },
    {
      key: 'applicationRequestNumber',
      title: 'Өргөдлийн дугаар',
      dataIndex: 'applicationRequestNumber',
      render: (_, record) => (
        <div>{record.applicationRequestNumber || '-'}</div>
      ),
    },
    {
      key: 'userPhone',
      title: 'Утас',
      dataIndex: 'userPhone',
    },
    {
      key: 'userName',
      title: 'Хэрэглэгч',
      dataIndex: 'userName',
      responsive: ['lg'],
    },
    {
      key: 'userCity',
      title: 'Аймаг',
      dataIndex: 'userCity',
      render: (userCity: string) =>
        cityData.find((c) => c.code === userCity)?.name,
    },
    {
      key: 'signedAt',
      title: 'Огноо',
      dataIndex: 'signedAt',
      render: (signedAt: Timestamp) => convertDateTimeFromTimestamp(signedAt),
      responsive: ['lg'],
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      responsive: ['lg'],
      render: (_, record) => (
        <Button
          icon={<i className="bx bx-detail"></i>}
          size="small"
          type="primary"
          className="bg-lightBlue shadow-none"
          onClick={() => {
            navigate(
              Routes.compilePath(Routes.contractDetail, {id: record.uid}),
            );
          }}
        />
      ),
    },
  ];

  const getRequests = async () => {
    if (contractRoles.viewRequests.includes(userRole)) {
      setRequestLoading(true);
      var ref: any = firestore
        .collection('contracts')
        .where('contractType', '==', ContractType.batalgaajuulaltiinGeree)
        .where('status', '==', ContractStatus.signed);
      const data = await ref.orderBy('createdAt', 'desc').get();
      setRequests(
        data.docs.map((d: any) => ({...d.data(), uid: d.id} as ContractModel)),
      );
      setShowData(
        data.docs.map((d: any) => ({...d.data(), uid: d.id} as ContractModel)),
      );
      setRequestLoading(false);
    }
  };

  const search = () => {
    let data = requests;
    if (!_.isEmpty(searchNumber)) {
      data = data.filter((d) =>
        d.contractNumber.toLowerCase().includes(searchNumber.toLowerCase()),
      );
    }
    if (!_.isEmpty(searchAuditNumber)) {
      data = data.filter((d) =>
        (d.applicationRequestNumber || '')
          .toString()
          .toLowerCase()
          .includes(searchAuditNumber.toLowerCase()),
      );
    }
    setShowData(data);
  };

  useEffect(() => {
    if (!_.isEmpty(userRole) && !_.isElement(user)) {
      getRequests();
    }
  }, [userRole, user]);

  return (
    <>
      <DataTable
        tableName="Баталгаажуулалтын гэрээнүүд"
        columns={columns}
        data={showData}
        loading={requestLoading}
        reloadData={getRequests}
        onSearch={search}
        filter={[
          <Input
            key="number"
            size="small"
            className="w-auto"
            placeholder="Гэрээний дугаар"
            value={searchNumber}
            onChange={(e) => {
              setSearchNumber(e.target.value);
            }}
          />,
          <Input
            key="auditNumber"
            size="small"
            className="w-auto"
            placeholder="Өргөдлийн дугаар"
            value={searchAuditNumber}
            onChange={(e) => {
              setSearchAuditNumber(e.target.value);
            }}
          />,
        ]}
      />
      <ContractPDF
        open={openViewer}
        toggle={toggleModalViewer}
        contract={selected}
      />
    </>
  );
};

export default CertContractList;
