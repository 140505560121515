import {Button, Modal, Spin} from 'antd';
import {
  BlobProvider,
  Document,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import {Document as DocumentReader, Page as PageReader} from 'react-pdf';
import AuditDocument from './AuditPrintDocument';
import {useState} from 'react';

const AuditReportPrint = ({
  open = false,
  toggle,
  audit = {},
  company = {},
  farmer = {},
  auditPlan = {},
  auditProcess = {},
  auditReport = {},
  application = {},
  auditMembers = [],
  boardMembers = [],
  evaluations = [],
  comments = [],
}) => {
  const [pages, setPages] = useState(0);

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    fontWeight: 'normal',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    fontWeight: 'medium',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 'bold',
  });

  const handleLoadSuccess = (data) => {
    setPages(data.numPages);
  };

  return (
    <Modal
      open={open}
      onCancel={toggle}
      className="pdf-modal"
      width={Math.min(900, window.innerWidth)}
      closeIcon={<></>}
      centered
      footer={[]}
    >
      <BlobProvider
        document={
          <Document>
            <AuditDocument
              audit={audit}
              company={company}
              farmer={farmer}
              auditMembers={auditMembers}
              boardMembers={boardMembers}
              auditPlan={auditPlan}
              application={application}
              auditProcess={auditProcess}
              auditReport={auditReport}
              evaluations={evaluations}
              comments={comments}
            />
          </Document>
        }
      >
        {({blob, url, loading}) => {
          return loading ? (
            <div className="mt-20 text-center">
              <Spin />
            </div>
          ) : (
            <DocumentReader
              file={url}
              loading={<></>}
              onLoadSuccess={handleLoadSuccess}
            >
              {Array.from({length: pages}).map((p, i) => (
                <PageReader
                  key={`page-${i}]`}
                  loading={<></>}
                  renderTextLayer={false}
                  pageNumber={i + 1}
                  width={Math.min(900, window.innerWidth)}
                />
              ))}
            </DocumentReader>
          );
        }}
      </BlobProvider>
      <div className="text-right">
        <PDFDownloadLink
          className="bg-primary py-3 px-5 text-white rounded-xl hover:text-white"
          key={12}
          document={
            <Document>
              <AuditDocument
                audit={audit}
                company={company}
                farmer={farmer}
                auditMembers={auditMembers}
                boardMembers={boardMembers}
                auditPlan={auditPlan}
                application={application}
                auditProcess={auditProcess}
                auditReport={auditReport}
                evaluations={evaluations}
                comments={comments}
                download={true}
              />
            </Document>
          }
          fileName={`SFCS_REPORT-${audit.auditNumber}`}
        >
          {({blob, url, loading, error}) =>
            loading ? <Spin spinning={true} /> : 'Татах'
          }
        </PDFDownloadLink>
        <Button key={1} shape="round" onClick={toggle} className="m-3">
          Буцах
        </Button>
      </div>
    </Modal>
  );
};

export default AuditReportPrint;
