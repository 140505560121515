import {useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {Button, Col, Row, Spin, message} from 'antd';
import {Routes} from '../../routes/route_helper';
import {useDoc} from '../../helpers/firestore';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {CompanyRequestModel, RequestStatus} from '../../types';
import {
  rejectCompanyRequest,
  verifyCompanyRequest,
} from '../../helpers/firebase_functions';
import {emptyText, requestTag} from '../../helpers/theme_helper';
import {companyRoles} from '../../helpers/role_helper';
import {cityData} from '../../constants/city_data';

const CompanyRequestDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [request, requestLoading] = useDoc(`companyRequests/${id}`);

  const [loading, setLoading] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const goBack = () => navigate(Routes.companyRequests);

  const verify = async () => {
    if (id === null || !companyRoles.verifyRejectRequest.includes(userRole))
      return;
    const result = window.confirm(
      'Бүртгэлийн хүсэлт баталгаажуулахдаа итгэлтэй байна уу?',
    );
    if (!result || id == null) return;
    setLoading(true);
    const requestResult = await verifyCompanyRequest(id);
    if (!requestResult.status) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: requestResult.msg,
      });
    } else {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: 'Компаний бүртгэлийн хүсэлт баталгаажлаа.',
      });
    }
  };

  const reject = async () => {
    if (id === null || !companyRoles.verifyRejectRequest.includes(userRole))
      return;
    const result = window.confirm(
      'Бүртгэлийн хүсэлт буцаахдаа итгэлтэй байна уу?',
    );
    if (!result || id == null) return;
    setLoadingReject(true);
    const requestResult = await rejectCompanyRequest(id!);
    if (!requestResult.status) {
      setLoadingReject(false);
      messageApi.open({
        type: 'error',
        content: requestResult.msg,
      });
    } else {
      setLoadingReject(false);
      messageApi.open({
        type: 'success',
        content: 'Компаний бүртгэлийн хүсэлтийг буцаалаа.',
      });
    }
  };

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Бүртгэлийн хүсэлт
        </Button>
        {companyRoles.verifyRejectRequest.includes(userRole) && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Button
              loading={loadingReject}
              disabled={
                [RequestStatus.created, RequestStatus.verified].includes(
                  (request as any).status,
                ) ||
                loading ||
                loadingReject ||
                requestLoading
              }
              shape="round"
              onClick={reject}
              icon={<CloseOutlined />}
            >
              Буцаах
            </Button>
            <Button
              loading={loading}
              disabled={
                [RequestStatus.created, RequestStatus.verified].includes(
                  (request as any).status,
                ) ||
                loading ||
                loadingReject ||
                requestLoading
              }
              shape="round"
              type="primary"
              onClick={verify}
              icon={<CheckOutlined />}
            >
              Хүлээн авах
            </Button>
          </div>
        )}
      </div>
      <hr />
      <Spin spinning={requestLoading}>
        <Row gutter={[16, 16]} className="my-8 pb-16">
          <Col xs={24} lg={12} xl={12}>
            <div className="card-container p-6">
              <div className="text-lg font-medium mb-5">
                Байгууллагын мэдээлэл
              </div>
              <div className="mt-5 text-sm text-gray">Байгууллагын нэр</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).companyName || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">
                Улсын бүртгэлийн дугаар
              </div>
              <div className="mt-1">
                {(request as CompanyRequestModel).registrationNumber ||
                  emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">
                Үйл ажиллагааны чиглэл
              </div>
              <div className="mt-1">
                {(request as CompanyRequestModel).activity || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">
                Хүсч буй баталгаажуулалтын төрөл
              </div>
              <div className="mt-1">
                {(request as CompanyRequestModel).targetCertificateName ||
                  emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">
                Хууль эрх зүйн статус
              </div>
              <div className="mt-1">
                {(request as CompanyRequestModel).legalStatus || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Аймаг, хот</div>
              <div className="mt-1">
                {cityData.find(
                  (c) => c.code === (request as CompanyRequestModel).city,
                )?.name || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Сум, дүүрэг</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).district || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Байгууллагын хаяг</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).address || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Шуудангийн хаяг, код</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).postalCode || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Утас</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).phone || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Имэйл</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).email || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Веб хуудас</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).webAddress || emptyText}
              </div>
              <div className="mt-5 mb-2 text-sm text-gray">Статус</div>
              {requestTag((request as any).status)}
            </div>
          </Col>
          <Col xs={24} lg={12} xl={12}>
            <div className="card-container p-6">
              <div className="text-lg font-medium mb-5">
                Байгууллагын төлөөллийн мэдээлэл
              </div>
              <div className="mt-5 text-sm text-gray">Эцэг/эхийн нэр</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).repLastname || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Нэр</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).repName || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Имэйл</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).repEmail || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Утас</div>
              <div className="mt-1">
                {(request as CompanyRequestModel).repPhone || emptyText}
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
export default CompanyRequestDetail;
