import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {Button, Col, Row, Select, Spin, Tag, message} from 'antd';
import {Routes} from '../../routes/route_helper';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  emptyText,
  getCertificateModalChild,
  getFieldModalChild,
  requestTag,
} from '../../helpers/theme_helper';
import {AuthRoles, ContractType, RequestStatus} from '../../types';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {finyRequestRoles} from '../../helpers/role_helper';
import RequestFormModal from './RequestFormModal';
import DataTable from '../../components/table/Index';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {firestore} from '../../firebase';
import {
  assignCertificateRequest,
  assignFieldRequest,
  changeContractType,
} from '../../helpers/firebase_functions';
import {saveData, useCollection, useDoc} from '../../helpers/firestore';
import * as _ from 'lodash';
import NTUpdate from './NTUpdate';
import UpdateContractType from './UpdateContractType';
import {tcUserLog} from '../../helpers/table_columns';
import SaveUserLog from './SaveLog';

const FinyRequestDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole, userCompany} = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [user, userLoading] = useDoc(`users/${id}`);
  const [userLogs, userLogsLoading] = useCollection(`users/${id}/requestLogs`);
  const [requests, setRequests] = useState<any[]>([]);
  const [loadingOthers, setLoadingOthers] = useState(false);
  const [writePermitted, setWritePermitted] = useState(false);
  const [loadingAssign, setLoadingAssign] = useState(false);

  const [contractors, setContractors] = useState<any[]>([]);
  const [selectedContractor, setSelectedContractor] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = (value: boolean) => setOpenModal(value);
  const [modalChild, setModalChild] = useState<JSX.Element>(<></>);

  const [selectedReq, setSelectedReq] = useState<any>({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const toggleUpdateModal = (value: boolean) => setOpenUpdate(value);

  const [openLog, setOpenLog] = useState(false);
  const toggleLogModal = () => setOpenLog(!openLog);

  const goBack = () => navigate(Routes.finyRequests);

  const columns: ColumnsType<any> = [
    {
      key: 'verifyItem',
      title: 'Хүсэлт',
      dataIndex: 'verifyItem',
      render: (verifyItem: string) => <Tag>{verifyItem}</Tag>,
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => requestTag(status),
    },
    {
      key: 'date',
      title: 'Огноо',
      dataIndex: 'date',
      render: (date: Timestamp) => convertDateTimeFromTimestamp(date),
    },
    {
      key: 'assignedTo',
      title: 'Ажилтан',
      dataIndex: 'assignedTo',
      render: (assignedTo?: string) => (
        <div>
          {_.isEmpty(assignedTo) ? (
            <span className="text-sm text-gray">
              <ExclamationCircleOutlined /> Томилоогүй
            </span>
          ) : (
            <div className="text-sm">
              <CheckOutlined /> Томилсон
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <div className="flex gap-2">
          {record.type === 'certificate' &&
            record.isContract &&
            record.status === RequestStatus.sent && (
              <Button
                icon={<i className="bx bx-edit-alt"></i>}
                size="small"
                type="primary"
                className="bg-warning"
                onClick={() => {
                  setSelectedReq(record);
                  toggleUpdateModal(true);
                }}
              />
            )}
          <Button
            icon={<i className="bx bx-show"></i>}
            size="small"
            type="primary"
            className="bg-lightBlue"
            onClick={() => {
              showDetail(record, record.type);
            }}
          />
        </div>
      ),
    },
  ];

  const getRequests = async () => {
    setLoadingOthers(true);
    const fieldPromise = firestore
      .collection(`fieldRequests`)
      .where('status', 'in', [RequestStatus.sent, RequestStatus.verified])
      .where('userUid', '==', id)
      .orderBy('sentAt', 'desc');
    const certPromise = firestore
      .collection(`certificateRequests`)
      .where('status', 'in', [
        RequestStatus.sent,
        RequestStatus.confirmed,
        RequestStatus.verified,
      ])
      .where('userUid', '==', id)
      .orderBy('sentAt', 'desc');
    const data = await Promise.all([fieldPromise.get(), certPromise.get()]);
    const fieldRequests = data[0].docs.map((d) => ({...d.data(), uid: d.id}));
    const certificateRequests = data[1].docs.map((d) => ({
      ...d.data(),
      uid: d.id,
    }));
    const field = (fieldRequests as any[]).map((l) => ({
      ...l,
      date: l.sentAt,
      verifyItem: `Талбайн түүх - ${l.year}`,
      type: 'field',
    }));
    const certificate = (certificateRequests as any[]).map((c) => ({
      ...c,
      date: c.sentAt,
      verifyItem: c.isContract
        ? c.contractType === ContractType.amlaltUurgiinGeree
          ? `Амлалт үүргийн гэрээ - ${c.certificateName}`
          : `Баталгаажуулалтын гэрээ - ${c.certificateName}`
        : `Гэрчилгээ баталгаажуулах - ${c.certificateName}`,
      type: 'certificate',
    }));
    const allRequests = [...field, ...certificate];
    const sorted = allRequests.sort(function (x, y) {
      return y.date - x.date;
    });
    setRequests(sorted);
    setLoadingOthers(false);
  };

  const getAuditors = async () => {
    const docs = await firestore
      .collection('auditors')
      .where('companyId', '==', userCompany)
      .where('role', '==', AuthRoles.AUDIT_CONTRACTOR)
      .orderBy('createdAt', 'desc')
      .get();
    setContractors(docs.docs.map((d) => ({...d.data(), uid: d.id})));
  };

  const assign = async () => {
    if (
      id === null ||
      !writePermitted ||
      _.isEmpty(requests) ||
      _.isEmpty(selectedContractor)
    ) {
      return;
    }
    setLoadingAssign(true);
    for (var r of requests) {
      if (r.status !== RequestStatus.verified) {
        if (r.type === 'field') {
          await assignFieldRequest(r.uid, selectedContractor);
        } else {
          await assignCertificateRequest(r.uid, selectedContractor);
        }
      }
    }
    await getRequests();
    setLoadingAssign(false);
    messageApi.open({
      type: 'success',
      content: 'Баталгаажуулалтын ажилтан томилогдлоо',
    });
  };

  const saveLogData = async (text: string) => {
    if (userRole === AuthRoles.AUDIT_ADMIN) {
      await saveData(`users/${id}/requestLogs`, undefined, {
        text,
        createdBy: user.uid,
      });
      return {status: true};
    } else return {status: false};
  };

  const updateContract = async (value: string, request: any) => {
    if (id == null) return;
    const requestResult = await changeContractType(request.uid, value);
    await getRequests();
    return requestResult;
  };

  const showDetail = (request: any, type: string) => {
    setModalChild(
      type === 'field'
        ? getFieldModalChild(request)
        : getCertificateModalChild(request),
    );
    toggleModal(true);
  };

  useEffect(() => {
    if (!_.isEmpty(requests)) {
      setSelectedContractor(requests[0].assignedTo);
    }
  }, [requests]);

  useEffect(() => {
    if (!_.isEmpty(userCompany)) getAuditors();
  }, [userCompany]);

  useEffect(() => {
    if (finyRequestRoles.viewRequests.includes(userRole)) {
      getRequests();
    }
    if (finyRequestRoles.assign.includes(userRole)) {
      setWritePermitted(true);
    }
  }, [userRole]);

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Фини хүсэлтийн дэлгэрэнгүй
        </Button>
      </div>
      <hr />
      <Spin spinning={loadingOthers || userLoading} size="small">
        <Row gutter={[16, 16]} className="my-8">
          <Col xs={24} md={12}>
            <div className="card-container p-6 h-full">
              <div className="text-lg font-medium">Хэрэглэгчийн мэдээлэл</div>
              <div className="mt-5 text-sm text-gray">Хэрэглэгч</div>
              <div className="mt-1">
                {user.userType === 'citizen' ? 'Иргэн' : 'Компани'}
              </div>
              <div className="mt-5 text-sm text-gray">Овог, нэр</div>
              <div className="mt-1">
                {user.lastname} {user.firstname}
              </div>
              {user.userType === 'company' && (
                <>
                  <div className="mt-5 text-sm text-gray">Компаний нэр</div>
                  <div className="mt-1">{user.companyName}</div>
                </>
              )}
              <div className="mt-5 text-sm text-gray">Регистр </div>
              <div className="mt-1">{user.registerNumber || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
              <div className="mt-1">{user.phone || emptyText}</div>
              <div className="mt-5 text-sm text-gray">Аймаг, сум</div>
              <div className="mt-1">
                {[user.city, user.district]
                  .filter((u) => !_.isEmpty(u))
                  .join(', ')}
              </div>
              <div className="mt-5 text-sm text-gray">НТ дугаар</div>
              <NTUpdate farmerUid={id || ''} ntNumber={user.ntDugaar} />
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="card-container p-6 mb-10 h-full">
              <div className="text-lg font-medium">
                Баталгаажуулалтын ажилтан томилох
              </div>
              <div className="w-full">
                <Select
                  allowClear
                  className="w-full sm:w-64 my-4"
                  value={selectedContractor}
                  onChange={setSelectedContractor}
                  options={contractors.map((a: any) => ({
                    label: `${a.code} - ${a.name}`,
                    value: a.uid,
                  }))}
                />
              </div>
              <Button
                loading={loadingAssign}
                disabled={
                  loadingAssign ||
                  _.isEmpty(selectedContractor) ||
                  !writePermitted
                }
                shape="round"
                type="primary"
                className="w-full sm:w-auto"
                icon={<i className="bx bx-user mr-2"></i>}
                onClick={assign}
              >
                Томилох
              </Button>
            </div>
          </Col>
        </Row>
      </Spin>
      <RequestFormModal
        open={openModal}
        toggle={toggleModal}
        children={modalChild}
      />
      <div className="pt-10"></div>
      <DataTable
        tableName={`Хүсэлтүүд`}
        columns={columns}
        data={requests}
        loading={loadingOthers}
        reloadData={getRequests}
      />
      <DataTable
        tableName={`Хэрэглэгчийн лог`}
        columns={tcUserLog}
        data={userLogs}
        loading={userLogsLoading}
        headerWidgers={[
          <Button
            key={'add1'}
            className="hidden sm:block text-sm"
            shape="round"
            onClick={toggleLogModal}
            icon={<PlusOutlined />}
          >
            Бүртгэх
          </Button>,
          <Button
            key={'add2'}
            className="block sm:hidden text-sm"
            shape="circle"
            onClick={toggleLogModal}
            icon={<PlusOutlined />}
          />,
        ]}
      />
      <UpdateContractType
        open={openUpdate}
        toggle={toggleUpdateModal}
        onSubmit={updateContract}
        selected={selectedReq}
      />
      <SaveUserLog
        open={openLog}
        toggle={toggleLogModal}
        onSubmit={saveLogData}
      />
    </div>
  );
};

export default FinyRequestDetail;
