import React, {useEffect, useState} from 'react';
import {Button, Col, Input, InputNumber, message, Row, Select} from 'antd';
import {FromGroup, FromQuestion, QuestionOptions} from '../../types';
import {removeData, saveData, updateData} from '../../helpers/firestore';
import {defaultEvaluation, defaultMismatch} from '../../constants/app_data';
import * as _ from 'lodash';
import {DeleteOutlined} from '@ant-design/icons';

type Props = {
  colName: string;
  docUid?: string | undefined;
  certFormQuestions: FromQuestion[];
  groups: FromGroup[];
};

const FormQuestion: React.FC<Props> = ({
  certFormQuestions,
  groups,
  docUid,
  colName,
}) => {
  const addQuestion = async () => {
    if (_.isEmpty(docUid)) return;
    await saveData(
      `${colName}/${docUid ?? 0}/form`,
      undefined,
      {
        mismatch: defaultMismatch,
        evaluation: defaultEvaluation,
      },
      true,
    );
  };

  return (
    <>
      <div className="mb-4 font-medium text-info flex gap-2 justify-between items-center">
        Асуултууд ({certFormQuestions.length})
        <Button key="submit" size="small" shape="round" onClick={addQuestion}>
          Нэмэх
        </Button>
      </div>
      <Row gutter={16}>
        {(certFormQuestions as FromQuestion[]).map((q) => (
          <Col key={q.uid} xs={24} md={12} lg={8}>
            <Question
              certificateUid={docUid}
              formQuestion={q}
              groups={groups}
              docName={colName}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

type QuestionProps = {
  docName: string;
  certificateUid?: string | undefined;
  formQuestion: FromQuestion;
  groups: FromGroup[];
};

const Question: React.FC<QuestionProps> = ({
  groups,
  docName,
  formQuestion,
  certificateUid,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [question, setQuestion] = useState('');
  const [groupId, setGroupId] = useState<number | undefined>();
  const [mismatch, setMismatch] = useState([] as QuestionOptions[]);
  const [evaluation, setEvaluation] = useState([] as QuestionOptions[]);

  const validate = () => {
    return (
      !_.isEmpty(certificateUid) &&
      !_.isEmpty(question) &&
      !_.isEmpty(mismatch) &&
      !_.isEmpty(evaluation) &&
      groupId != null
    );
  };

  const editQuestion = async () => {
    if (!validate()) return;
    if (_.isEmpty(certificateUid) || _.isEmpty(formQuestion.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Асуулга хадгалахад алдаа гарлаа.',
      });
      return;
    }
    setLoading(true);
    await updateData(`${docName}/${certificateUid}/form/${formQuestion.uid}`, {
      question,
      groupId,
      mismatch,
      evaluation,
    } as FromQuestion);
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'success',
      content: 'Асуулга хадгалагдлаа.',
    });
  };

  const removeQuestion = async () => {
    if (
      _.isEmpty(certificateUid) ||
      _.isEmpty(formQuestion.uid) ||
      _.isEmpty(docName)
    ) {
      messageApi.open({
        type: 'error',
        content: 'Асуулга устгахад алдаа гарлаа.',
      });
      return;
    }
    setLoading(true);
    await removeData(`${docName}/${certificateUid}/form/${formQuestion.uid}`);
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'warning',
      content: 'Асуулга устгагдлаа.',
    });
  };

  useEffect(() => {
    if (!_.isEmpty(formQuestion)) {
      setQuestion(formQuestion.question ?? '');
      setGroupId(formQuestion.groupId);
      setMismatch(formQuestion.mismatch ?? []);
      setEvaluation(formQuestion.evaluation ?? []);
    }
  }, [formQuestion]);

  return (
    <>
      {contextHolder}
      <div
        key={`cert-form-question-${formQuestion.uid}`}
        className="border border-surface rounded-sm p-4 border-solid bg-lightGray mb-3"
      >
        {expanded || _.isEmpty(formQuestion.question) ? (
          <>
            <div className="flex items-center justify-between mb-4 gap-3">
              <div className="font-medium">Асуулт</div>
              {!_.isEmpty(formQuestion.question) && (
                <div
                  className="cursor-pointer text-info text-sm"
                  onClick={() => setExpanded(false)}
                >
                  Хураах
                </div>
              )}
            </div>
            <Select
              value={groupId}
              onChange={(value) => setGroupId(value)}
              options={groups.map((c) => ({
                value: c.id,
                label: c.name,
              }))}
              placeholder="Бүлэг"
              className="w-full overflow-hidden"
            />
            <Input.TextArea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Асуулт"
              className="mb-3"
            />
            <div className="flex justify-between items-center mb-3">
              <div className="font-medium">Үнэлгээ</div>
              <Button
                key="save"
                size="small"
                type="link"
                shape="round"
                onClick={() =>
                  setEvaluation([
                    ...evaluation,
                    {id: evaluation.length, value: '', score: 0},
                  ])
                }
              >
                Сонголт нэмэх
              </Button>
            </div>
            {evaluation.map((o) => (
              <div
                className="flex gap-1 mb-2 w-full"
                key={`evaluation-${o.id}`}
              >
                <Input
                  addonBefore={
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setEvaluation(
                          evaluation.filter((item) => item.id !== o.id),
                        );
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  }
                  value={o.value}
                  onChange={(e) => {
                    setEvaluation(
                      evaluation.map((item) =>
                        item.id === o.id ? {...o, value: e.target.value} : item,
                      ),
                    );
                  }}
                  placeholder="Утга"
                  className="flex-1 overflow-hidden"
                />
                <InputNumber
                  value={o.score}
                  onChange={(e) => {
                    setEvaluation(
                      evaluation.map((item) =>
                        item.id === o.id ? {...o, score: e || 0} : item,
                      ),
                    );
                  }}
                  placeholder="Оноо"
                />
              </div>
            ))}
            <div className="flex justify-between items-center my-3">
              <div className="font-medium">Үл тохирол</div>
              <Button
                key="save"
                size="small"
                type="link"
                shape="round"
                onClick={() =>
                  setMismatch([
                    ...mismatch,
                    {id: mismatch.length, value: '', score: 0},
                  ])
                }
              >
                Сонголт нэмэх
              </Button>
            </div>
            {mismatch.map((o) => (
              <div className="flex gap-1 mb-2 w-full" key={`mismatch-${o.id}`}>
                <Input
                  addonBefore={
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setMismatch(
                          mismatch.filter((item) => item.id !== o.id),
                        );
                      }}
                    >
                      <DeleteOutlined />
                    </div>
                  }
                  value={o.value}
                  onChange={(e) => {
                    setMismatch(
                      mismatch.map((item) =>
                        item.id === o.id ? {...o, value: e.target.value} : item,
                      ),
                    );
                  }}
                  placeholder="Утга"
                  className="flex-1 overflow-hidden"
                />
                <InputNumber
                  value={o.score}
                  onChange={(e) => {
                    setMismatch(
                      mismatch.map((item) =>
                        item.id === o.id ? {...o, score: e || 0} : item,
                      ),
                    );
                  }}
                  placeholder="Оноо"
                />
              </div>
            ))}
            <div className="flex justify-center gap-3 pt-2">
              <Button
                key="delete"
                size="small"
                shape="round"
                loading={loading}
                disabled={_.isEmpty(formQuestion.uid)}
                onClick={removeQuestion}
                danger
              >
                Устгах
              </Button>
              <Button
                key="save"
                size="small"
                shape="round"
                type="primary"
                loading={loading}
                disabled={!validate()}
                onClick={editQuestion}
              >
                Хадгалах
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between items-center gap-3">
              <div>
                <div className="text-sm text-gray mb-1">
                  {groups.find((g) => g.id === groupId)?.name || ''}
                </div>
                <div>{question}</div>
              </div>
              <div
                className="cursor-pointer text-info text-sm"
                onClick={() => setExpanded(true)}
              >
                Засах
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FormQuestion;
