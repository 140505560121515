import {Spin} from 'antd';
import {useEffect, useState} from 'react';
import {BlobProvider, Document, Font} from '@react-pdf/renderer';
import {Document as DocumentReader, Page as PageReader, pdfjs} from 'react-pdf';
import {qrHistory} from '../../helpers/firebase_functions';
import {useParams} from 'react-router-dom';
import * as _ from 'lodash';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import AuditHistoryDocument from './AuditHistory';
import CertificateDocument from './Certificate';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const QRRead = () => {
  const {id} = useParams();
  const [pages, setPages] = useState(0);
  const [loadingData, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getData = async () => {
    const result = await qrHistory(id || '');
    if (!result.status) {
      //error
    } else {
      setData(result.data);
      console.log(result.data);
    }
    setLoading(false);
  };

  const handleLoadSuccess = (data) => {
    setPages(data.numPages);
  };

  useEffect(() => {
    getData();
  }, []);

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    fontWeight: 'normal',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    fontWeight: 'medium',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 'bold',
  });

  return (
    <div className="flex justify-center h-screen w-screen">
      <Spin spinning={loadingData}>
        {/* <PDFViewer className="h-screen w-screen">
          <Document>
            <CertificateDocument data={data} />
            <AuditHistoryDocument data={data} />
          </Document>
        </PDFViewer> */}
        {!_.isEmpty(data) && (
          <BlobProvider
            document={
              <Document>
                <AuditHistoryDocument data={data} />
                {!_.isEmpty(data.certificates) && (
                  <CertificateDocument certificate={data.certificates[0]} />
                )}
              </Document>
            }
          >
            {({blob, url, loading}) => {
              return loading ? (
                <div className="mt-10">
                  <Spin />
                </div>
              ) : (
                <DocumentReader
                  file={url}
                  loading={<></>}
                  onLoadSuccess={handleLoadSuccess}
                >
                  {Array.from({length: pages}).map((p, i) => (
                    <PageReader
                      key={`[age-${i}]`}
                      loading={<></>}
                      renderTextLayer={false}
                      pageNumber={i + 1}
                      width={Math.min(900, window.innerWidth)}
                    />
                  ))}
                </DocumentReader>
              );
            }}
          </BlobProvider>
        )}
      </Spin>
      {/* {!_.isEmpty(data) && (
        <div className="absolute">
          <PDFDownloadLink
            document={
              <Document>
                <AuditHistoryDocument data={data} />
                <CertificateDocument data={data} />
              </Document>
            }
            fileName="sfcs"
          >
            {({blob, url, loading, error}) =>
              loading ? <Spin spinning={true || loadingData} /> : 'Татах'
            }
          </PDFDownloadLink>
        </div>
      )} */}
    </div>
  );
};

export default QRRead;
