import {Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import {auditorAuditTypes} from '../../../constants/app_data';
import {
  convertDateFromTimestamp,
  convertDateReport,
} from '../../../helpers/utils';
import {cityData} from '../../../constants/city_data';
import * as _ from 'lodash';

const AuditDocument = ({
  audit = {},
  auditPlan = {},
  auditProcess = {},
  auditReport = {},
  application = {},
  company,
  farmer,
  auditMembers = [],
  boardMembers = [],
  evaluations = [],
  comments = [],
  download = false,
}) => {
  const styles = StyleSheet.create({
    page: {
      width: '100vw',
      height: '100vh',
      fontSize: 12,
      lineHeight: 1.5,
      fontFamily: 'Roboto',
      flexDirection: 'column',
      display: 'flex',
    },
  });

  const getApplicantName = () => {
    if (audit.type === 'local') {
      return `${company.companyName} ${company.legalStatus}`;
    } else {
      return farmer.userType === 'citizen'
        ? `${farmer.lastname} ${farmer.firstname}`
        : `${farmer.companyName}`;
    }
  };

  return (
    <Page size="A4" style={styles.page}>
      {download && (
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '2px solid #4f81bd',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginHorizontal: 28,
            marginTop: 6,
            marginBottom: 12,
          }}
        >
          <Image src="/sfcs.png" style={{height: 36}} />
          <Text
            style={{
              color: '#4f81bd',
              fontSize: 16,
              marginBottom: '6px',
              marginLeft: '40px',
            }}
          >
            ЭС ЭФ СИ ЭС ХХК
          </Text>
          <Text style={{color: '#4f81bd', fontSize: 10}}>SFCS-CA-WD-08</Text>
        </View>
      )}
      <View style={{flex: 1, paddingHorizontal: 28, paddingVertical: 12}}>
        {!download && (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '2px solid #4f81bd',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Image src="/sfcs.png" style={{height: 36}} />
            <Text
              style={{
                color: '#4f81bd',
                fontSize: 16,
                marginBottom: '6px',
                marginLeft: '40px',
              }}
            >
              ЭС ЭФ СИ ЭС ХХК
            </Text>
            <Text style={{color: '#4f81bd', fontSize: 10}}>SFCS-CA-WD-08</Text>
          </View>
        )}
        <View
          style={{
            margin: '200px 0 0 0',
            backgroundColor: '#4F81BD',
            padding: '10px',
          }}
        >
          <Text
            style={{fontSize: 18, fontWeight: 'medium', textAlign: 'center'}}
          >
            {`${
              auditorAuditTypes.find((t) => t.value === audit.auditType)
                ?.name || 'Аудит'
            }ын тайлан`.toUpperCase()}
          </Text>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            backgroundColor: '#ccecff',
            fontSize: '10px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{fontWeight: 'medium'}}>АУДИТЫН БАЙГУУЛЛАГА</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text> "ЭС ЭФ СИ ЭС" ХХК</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{fontWeight: 'medium'}}>АУДИТЫН ТӨРӨЛ</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {audit.applicationName}-ын{' '}
                {auditorAuditTypes
                  .find((t) => t.value === audit.auditType)
                  ?.name.toUpperCase() || 'АУДИТ'}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{fontWeight: 'medium'}}>АУДИТЫН ДУГААР №</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{audit.auditNumber}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{fontWeight: 'medium'}}>АУДИТЫН ХИЙХ ШАЛГУУР</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{marginBottom: 10}}>{application.shalguur}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{fontWeight: 'medium'}}>
                АУДИТЛҮҮЛЭГЧ{' '}
                {audit.type === 'local' ? 'БАЙГУУЛЛАГА' : 'ТАРИАЛАНЧ'}
              </Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{getApplicantName()}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text style={{fontWeight: 'medium'}}>ОГНОО</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{convertDateReport(audit.startedAt)}</Text>
            </View>
          </View>
        </View>
        <Text style={{marginTop: '30px', textAlign: 'center', fontSize: 9}}>
          Энэхүү аудитын тайлан нь аудитын үйлчлүүлэгч, шалгуулагч болох{' '}
          {getApplicantName()}-ийн өмч бөгөөд {getApplicantName()}-ийн албан
          ёсны зөвшөөрөлгүйгээр ашиглахыг хориглоно.
        </Text>
        <Text style={{textAlign: 'center', fontSize: 9}}>
          Баталгаажуулалтын байгууллага болох Эс Эф Си Эс ХХК нь аудитын явцад
          танилцсан аудитын баримт, мэдэж авсан мэдээлэл болон энэхүү тайланг
          нууцлах, гуравдагч этгээдэд дамжуулахгүй байх үүргийг хүлээнэ.
        </Text>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              1. АУДИТЛҮҮЛЭГЧ{' '}
              {audit.type === 'local' ? 'БАЙГУУЛЛАГЫН' : 'ТАРИАЛАНЧЫН'} МЭДЭЭЛЭЛ
            </Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>1.1 Нэр, үйл ажиллагаа</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {farmer.userType === 'company' ? 'Компаний нэр' : 'Овог нэр'}
              </Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{getApplicantName()}</Text>
            </View>
          </View>
          {audit.userType === 'local' && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>Байгуулагдсан он</Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text></Text>
              </View>
            </View>
          )}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Үндсэн үйл ажиллагаа</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {audit.type === 'local'
                  ? company.activity
                  : farmer.userType === 'citizen'
                  ? 'Иргэн'
                  : 'Компани'}
              </Text>
            </View>
          </View>
          {audit.type === 'local' && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>Бусад үйл ажиллагаа</Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text></Text>
              </View>
            </View>
          )}
          {audit.type === 'local' ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>Нийт ажилтны тоо</Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text></Text>
              </View>
            </View>
          ) : (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>НТ дугаар</Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>{farmer.ntDugaar}</Text>
              </View>
            </View>
          )}
          {audit.type === 'local' && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>Салбар нэгж</Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text></Text>
              </View>
            </View>
          )}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Бусад мэдээлэл</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text></Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>1.2 Хаяг, холбоо</Text>
          </View>
          {audit.type === 'local' && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>Гүйцэтгэх захирал</Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>
                  {company.repLastname} {company.repName}
                </Text>
              </View>
            </View>
          )}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Хаяг, байршил</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {audit.type === 'local'
                  ? `${
                      cityData.find((c) => c.code === company.city)?.name || ''
                    } ${company.address}`
                  : `${farmer.city} ${farmer.district}`}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {audit.type === 'local'
                  ? 'Утас, цахим хуудас, цахим шуудан'
                  : 'Утас'}
              </Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              {audit.type === 'local' ? (
                <Text>
                  {company.phone} {company.webAddress} {company.postalCode}
                </Text>
              ) : (
                <Text>{farmer.phone}</Text>
              )}
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              2. АУДИТЫН БАЙГУУЛЛАГЫН МЭДЭЭЛЭЛ
            </Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>2.1 Нэр, үйл ажиллагаа</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Нэр</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>"Эс Эф Си Эс" ХХК</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Байгуулагдсан он</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>2014</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Үндсэн үйл ажиллагаа</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                ISO 9001, ISO 14001, ISO 45001, ISO 50001, ISO 22000, ISO 27001,
                ISO 17065 олон улсын стандартын сургалт, “Органик бүтээгдэхүүн”,
                “Хөдөө аж ахуйн зохистой дадал”-ын баталгаажуулалтын аудит,
                Бүтээгдэхүүний тохирол
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Бусад үйл ажиллагаа</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                Чанар, байгаль орчин, хөдөлмөрийн аюулгүй байдал ба эрүүл ахуйн
                менежментийн тогтолцооны аудит ба аудитын сургалт
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Нийт ажилтны тоо</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>5</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>1.2 Хаяг, холбоо</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Гүйцэтгэх захирал</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Г.Ундаръяа</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Хаяг, байршил</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                Улаанбаатар Баянгол 2 хороо, 2 хороолол,63 Б байр, 1 тоот
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Утас, цахим хуудас, цахим шуудан</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                (+976) 7014-9762, 85022022, 94999347 http://www.sfcs.mn/
                sfcscertification@gmail.com, sfcscertification1@gmail.com
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              3. АУДИТЫН ТАЛААРХ МЭДЭЭЛЭЛ
            </Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>3.1. Аудитын баг</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Томилсон тушаал, шийдвэр</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                "Эс Эф Си Эс" ХХК-ийн Гүйцэтгэх захирлын {audit.guide} тушаал
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Багийн ахлагч</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {auditMembers.find((m) => m.uid === audit?.teamLead)?.name ||
                  ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Багийн гишүүд</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {auditMembers
                  .filter((m) => m.uid !== audit?.teamLead)
                  .map((m) => m.name)
                  .join(', ')}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              3.2. Аудитын талаарх ерөнхий мэдээлэл
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудитын хамрах хүрээ</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{audit.auditScope}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудитын зорилго</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{audit?.objective || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудит явуулах үед баримталсан стандарт, норматив</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{application.standartCode}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудит явуулсан хугацаа, огноо</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>
                {!_.isEmpty(auditPlan.auditStart) &&
                !_.isEmpty(auditPlan.auditEnd)
                  ? `${convertDateFromTimestamp(
                      auditPlan.auditStart,
                    )} ~ ${convertDateFromTimestamp(auditPlan.auditEnd)}`
                  : ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудит явуулсан газар</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditReport.location}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудитын албан ёсны хэл</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Монгол</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>4. АУДИТЫН ЯВЦ</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудитын явцын талаарх товч мэдээлэл</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.brief || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Сайжруулах саналын мөрөөр хийсэн ажлын биелэлт</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.implementation || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Аудитаар шалгагдсан баримт бичиг</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.documents || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Дээд шатны удирдлагатай хийсэн ярилцлага</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.seniorInterview || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Дунд шатны удирдлагатай хийсэн ярилцлага</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.middleInterview || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Ажилтнуудтай хийсэн ярилцлага</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.interview || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Шалгасан санал, гомдол, аюултай тохиолдол</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.suggestion || ''}</Text>
            </View>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View
              style={{flex: 1, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>Бусад мэдээлэл</Text>
            </View>
            <View
              style={{flex: 2, border: '0.5px solid #000', padding: '1px 4px'}}
            >
              <Text>{auditProcess.other || ''}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>5. АУДИТЫН ҮР ДҮН</Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              5.1. Сайн туршлага, давуу тал
            </Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              width: '100%',
            }}
          >
            <Text>{auditReport.result || ''}</Text>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              5.2. Шалгуур үзүүлэлтийн үнэлгээ
            </Text>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                  width: '30px',
                }}
              >
                <Text>№</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                }}
              >
                <Text>Стандартын шаардлага, шалгуур үзүүлэлт</Text>
              </View>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                  width: '60px',
                }}
              >
                <Text>Үнэлгээ</Text>
              </View>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                  width: '100px',
                }}
              >
                <Text>Нотолгоо</Text>
              </View>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                  width: '100px',
                }}
              >
                <Text>Шаардлагын биелэлт</Text>
              </View>
            </View>
            {evaluations.map((e, i) => (
              <View
                key={`evaluation-${i}`}
                style={{display: 'flex', flexDirection: 'row'}}
                wrap={!download}
              >
                <View
                  style={{
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                    width: '30px',
                  }}
                >
                  <Text>{i + 1}</Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                  }}
                >
                  <Text>{e.question}</Text>
                </View>
                <View
                  style={{
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                    width: '60px',
                  }}
                >
                  <Text>
                    {_.get(e, 'evaluation') === 'Хангаж байна' ? '(+)' : ''}{' '}
                    {_.get(e, 'mismatch') === 'Сайжруулах боломж' ? 'СБ' : ''}{' '}
                    {e.mismatch === 'Том үл тохирол' ||
                    e.mismatch === 'Жижиг үл тохирол'
                      ? 'ҮТ'
                      : ''}
                  </Text>
                </View>
                <View
                  style={{
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                    width: '100px',
                  }}
                >
                  {(e.evidence.images || []).map((image, i) => (
                    <Image
                      key={`evidence-image-${image}-${i}`}
                      cache={false}
                      src={image}
                      style={{height: 80, margin: 2}}
                    />
                  ))}
                </View>
                <View
                  style={{
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                    width: '100px',
                  }}
                >
                  <Text>{e.desc || ''}</Text>
                </View>
              </View>
            ))}
            <View
              style={{
                border: '0.5px solid #000',
                padding: '1px 4px',
                width: '100%',
              }}
            >
              <Text>Аудитын үр дүнгийн матрицын тайлбар</Text>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  width: '30px',
                }}
              >
                <Text>(+)</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>Стандартын тухайн элементийн шаардлага биелэгдсэн</Text>
              </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  width: '30px',
                }}
              >
                <Text>ҮТ</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>
                  Үл тохирол, стандартаар тогтоосон шаардлага биелэгдээгүй
                </Text>
              </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  width: '30px',
                }}
              >
                <Text>СБ</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>
                  Сайжруулах боломж: стандартаар тогтоосон шаардлага биелэгдсэн,
                  гэвч илүү боловсронгуй болгох, сайжруулах шаардлагатай
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              5.3. Аудитаар илэрсэн үл тохирол
            </Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              width: '100%',
            }}
          >
            <Text>
              Аудитын баг нь аудитын үр дүнгээр дараах 0 үл тохирол буюу
              стандартаар тогтоосон шаардлага биелэгдсэн боловч илүү
              боловсронгуй болгох шаардлагатай саналыг гаргасан. Үүнд:
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontWeight: 'medium',
            }}
          >
            <View
              style={{
                flex: 1,
                border: '0.5px solid #000',
                padding: '1px 4px',
              }}
            >
              <Text>ҮЛ ТОХИРОЛ</Text>
            </View>
            <View
              style={{
                flex: 2,
                border: '0.5px solid #000',
                padding: '1px 4px',
              }}
            >
              <Text>ТАЙЛБАР</Text>
            </View>
          </View>
          {evaluations
            .filter(
              (e) =>
                e.mismatch === 'Том үл тохирол' ||
                e.mismatch === 'Жижиг үл тохирол',
            )
            .map((e, i) => (
              <View
                key={`evaluation-${i}`}
                style={{display: 'flex', flexDirection: 'row'}}
              >
                <View
                  style={{
                    flex: 1,
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                  }}
                >
                  <Text>{e.question}</Text>
                </View>
                <View
                  style={{
                    flex: 2,
                    border: '0.5px solid #000',
                    padding: '1px 4px',
                  }}
                >
                  <Text>{e.desc}</Text>
                </View>
              </View>
            ))}
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#d3d3d3',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>
              5.4. Аудитаар тогтоосон сайжруулах боломж, зөвлөмж
            </Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              width: '100%',
            }}
          >
            <Text>
              Аудитын баг нь аудитын үр дүнгээр нийт 49 үзүүлэлтээс дараах 5
              сайжруулах буюу стандарт, журмаар тогтоосон шаардлага биелэгдсэн
              боловч илүү боловсронгуй болгох шаардлагатай саналыг гаргасан.
              Үүнд:
            </Text>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>САЙЖРУУЛАХ БОЛОМЖ</Text>
          </View>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <View
                style={{
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                  width: '30px',
                }}
              >
                <Text>№</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                }}
              >
                <Text>Стандартын шаардлага, шалгуур үзүүлэлт</Text>
              </View>
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  backgroundColor: '#ccecff',
                }}
              >
                <Text>Шаардлагын биелэлт</Text>
              </View>
            </View>
            {evaluations
              .filter((e) => e.mismatch === 'Сайжруулах боломж')
              .map((e, i) => (
                <View
                  key={`evaluation-${i}`}
                  style={{display: 'flex', flexDirection: 'row'}}
                >
                  <View
                    style={{
                      border: '0.5px solid #000',
                      padding: '1px 4px',
                      width: '30px',
                    }}
                  >
                    <Text>{i + 1}</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      border: '0.5px solid #000',
                      padding: '1px 4px',
                    }}
                  >
                    <Text>{e.question}</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      border: '0.5px solid #000',
                      padding: '1px 4px',
                    }}
                  >
                    <Text>{e.desc || ''}</Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>6. АУДИТЫН ДҮГНЭЛТ</Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              width: '100%',
            }}
          >
            <Text>{auditReport.conclusion}</Text>
          </View>
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              width: '100%',
            }}
          >
            <Text>ТАЙЛБАР</Text>
            <Text>{auditReport.description}</Text>
          </View>
        </View>
        <View
          style={{
            border: '0.5px solid #000',
            marginTop: '30px',
            fontSize: '10px',
          }}
          wrap={!download}
        >
          <View
            style={{
              border: '0.5px solid #000',
              padding: '1px 4px',
              backgroundColor: '#ccecff',
              width: '100%',
            }}
          >
            <Text style={{fontWeight: 'medium'}}>ЗӨВЛӨЛИЙН САНАЛ</Text>
          </View>
          {comments.map((c) => (
            <View
              key={c.uid}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                }}
              >
                <Text>
                  {boardMembers.find((m) => m.uid === c.uid)?.name || ''}
                </Text>
              </View>
              <View
                style={{
                  flex: 2,
                  border: '0.5px solid #000',
                  padding: '1px 4px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {(c.comment || []).map((com, i) => (
                  <Text key={`${c.uid}-${i}`}>{com}</Text>
                ))}
              </View>
            </View>
          ))}
        </View>
        <View style={{marginTop: '40px', marginLeft: '60px'}} break>
          <Text style={{fontWeight: 'medium'}}>ТАЙЛАН ГАРГАСАН:</Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              gap: '10px',
            }}
          >
            <Text style={{width: '30%', fontSize: 11}}>
              АУДИТОРЫН БАГИЙН АХЛАГЧ, АУДИТОР
            </Text>
            <View style={{flex: 1}}></View>
            <Text style={{fontSize: 11}}>
              {auditMembers
                .find((m) => m.uid === audit?.teamLead)
                ?.name.toUpperCase() || ''}
            </Text>
          </View>
        </View>
        <View style={{marginTop: '40px', marginLeft: '60px'}}>
          <Text style={{fontWeight: 'medium'}}>
            ТАЙЛАНТАЙ ТАНИЛЦАЖ ЗӨВШӨӨРСӨН:
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '30px',
              gap: '10px',
            }}
          >
            <Text style={{width: '30%', fontSize: 11}}>
              {getApplicantName()}
            </Text>
            <View style={{flex: 1}}></View>
          </View>
        </View>
      </View>
      {download && (
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '2px solid #4f81bd',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginHorizontal: 28,
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <Text style={{fontSize: 9, marginBottom: '6px', color: '#4f81bd'}}>
            Эс Эф Си Эс ХХК
          </Text>
          <Text
            style={{
              fontSize: 9,
              flex: 1,
              textAlign: 'center',
              marginRight: '60px',
              marginBottom: '6px',
              color: '#4f81bd',
            }}
          >
            Тайлан № {audit.auditNumber}
          </Text>
        </View>
      )}
    </Page>
  );
};

export default AuditDocument;
