import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {CompanyModel} from '../../types';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {Routes} from '../../routes/route_helper';
import {companyRoles} from '../../helpers/role_helper';
import {firestore} from '../../firebase';
import DataTable from '../../components/table/Index';

const CompanyList = () => {
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [loading, setLoading] = useState(true);

  const columns: ColumnsType<CompanyModel> = [
    {
      key: 'companyName',
      title: 'Байгууллага',
      dataIndex: 'companyName',
    },
    {
      key: 'registrationNumber',
      title: 'УБД',
      dataIndex: 'registrationNumber',
    },
    {
      key: 'phone',
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      key: 'address',
      title: 'Хаяг',
      dataIndex: 'address',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.companyDetail, {id: d.uid}));
  };

  const getCompanyList = async () => {
    setLoading(true);
    const data = await firestore
      .collection('companies')
      .orderBy('createdAt', 'desc')
      .get();
    setCompanies(
      data.docs.map((d) => ({...d.data(), uid: d.id} as CompanyModel)),
    );
    setLoading(false);
  };

  useEffect(() => {
    if (companyRoles.viewRequest.includes(userRole)) {
      getCompanyList();
    }
  }, [userRole]);

  return (
    <DataTable
      tableName="Компаниуд"
      columns={columns}
      data={companies}
      loading={loading}
      onClickRow={onClickRow}
    />
  );
};

export default CompanyList;
