import React, {useContext, useState} from 'react';
import {Button, Input, Modal, Alert, message, Select} from 'antd';
import * as _ from 'lodash';

import LogoImage from '../../assets/images/logo.png';
import {AuditStatus} from '../../types';

import {UserContext} from '../../context/app.contexts';
import {saveData} from '../../helpers/firestore';
import {auditorAuditTypes} from '../../constants/app_data';
import {auditRoles} from '../../helpers/role_helper';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  applicationRequestUid: string;
  applicationName: string;
  applicationUid: string;
  location?: string;
  companyUid?: string;
  companyName?: string;
  farmerUid?: string;
  farmerName?: string;
  type: string;
};

const AuditAdd: React.FC<Props> = ({
  open = false,
  toggle,
  applicationRequestUid,
  applicationName,
  applicationUid,
  location,
  companyUid,
  companyName,
  farmerUid,
  farmerName,
  type,
}) => {
  const {user, userRole, userCompany} = useContext(UserContext);
  const [auditType, setAuditType] = useState<string | undefined>();
  const [auditNumber, setAuditNumber] = useState<string>('');
  const [auditScope, setAuditScope] = useState<string>('');
  const [objective, setObjective] = useState<string>('');

  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setAuditType(undefined);
    setAuditScope('');
    setAuditNumber('');
    setObjective('');
    setError('');
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (
      _.isEmpty(applicationRequestUid) ||
      _.isEmpty(auditType) ||
      _.isEmpty(auditScope) ||
      _.isEmpty(auditNumber) ||
      _.isEmpty(objective) ||
      loadingSave
    )
      return false;
    return true;
  };

  const onSave = async () => {
    if (!validate() || _.isEmpty(user) || _.isEmpty(user.uid)) return;
    if (!auditRoles.addEditAudit.includes(userRole)) {
      messageApi.open({
        type: 'error',
        content: `Та аудитын төрөл нэмэх эрхгүй байна`,
      });
      return;
    }
    try {
      setLoadingSave(true);
      await saveData('audits', undefined, {
        auditNumber,
        auditScope,
        auditType,
        objective,
        applicationRequestUid,
        applicationName,
        applicationUid,
        location: location || '',
        companyUid: companyUid || '',
        companyName: companyName || '',
        farmerUid: farmerUid || '',
        farmerName: farmerName || '',
        type,
        createdBy: user.uid,
        auditCompanyId: userCompany,
        status: AuditStatus.created,
      });
      onCancel();
      messageApi.open({
        type: 'success',
        content: `${
          auditorAuditTypes.find((r) => r.value === auditType)?.name
        } нэмэгдлээ.`,
      });
    } catch (e) {
      console.log(e);
      onCancel();
      messageApi.open({
        type: 'error',
        content: `Алдаа гарлаа`,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            shape="round"
            type="primary"
            onClick={onSave}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Аудитын төрөл нэмэх 
            </div>
            <Input
              value={auditNumber}
              onChange={(e) => setAuditNumber(e.target.value)}
              placeholder="Аудитын дугаар"
              className="mb-3"
            />
            <Select
              allowClear
              className="w-full mb-3"
              value={auditType}
              placeholder="Аудитын төрөл"
              onChange={setAuditType}
              options={auditorAuditTypes.map((a: any) => ({
                label: a.name,
                value: a.value,
              }))}
            />
            <Input.TextArea
              value={auditScope}
              onChange={(e) => setAuditScope(e.target.value)}
              placeholder="Хамрах хүрээ"
              className="mb-3"
            />
            <Input.TextArea
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              placeholder="Аудитын зорилго"
              className="mb-3"
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuditAdd;
