import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Image, Row, Spin, Tag, message} from 'antd';
import {useDoc} from '../../helpers/firestore';
import {Routes} from '../../routes/route_helper';
import {ArrowLeftOutlined, PlusOutlined} from '@ant-design/icons';
import {contractTag, emptyText} from '../../helpers/theme_helper';
import {AuthRoles, CertificateRequestModel, ContractModel} from '../../types';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {UserContext} from '../../context/app.contexts';
import {createContractApplication} from '../../helpers/firebase_functions';

const ContractDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [request, requestLoading] = useDoc(`contracts/${id}`);
  const [user] = useDoc(`users/${request.userUid || '0'}`);
  const [certificateRequest, setCertificate] = useState<
    CertificateRequestModel | undefined
  >();
  const [applicationLoading, setApplicationLoading] = useState(false);

  const goBack = () => navigate(-1);

  const getCertificateRequestData = async (uid: string) => {
    const data = await firestore.doc(`certificateRequests/${uid}`).get();
    setCertificate({...data.data(), uid: data.id} as CertificateRequestModel);
  };

  const createApplication = async () => {
    if (_.isEmpty(id)) return;
    if (!_.isEmpty((request as ContractModel).applicationRequestUid)) {
      message.warning('Өргөдөл аль хэдийн үүсгэсэн байна');
      return;
    }
    setApplicationLoading(true);
    const requestResult = await createContractApplication(id!);
    if (!requestResult.status) {
      setApplicationLoading(false);
      message.error(requestResult.msg);
    } else {
      setApplicationLoading(false);
      message.success('Өргөдөл үүсгэлээ');
    }
  };

  useEffect(() => {
    if (
      !_.isEmpty((request as ContractModel).certificateUid) &&
      !_.isEmpty((request as ContractModel).userUid)
    ) {
      getCertificateRequestData(
        `${(request as ContractModel).userUid}${
          (request as ContractModel).certificateUid
        }`,
      );
    }
  }, [request]);

  return (
    <div className="pb-16">
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Гэрээний дэлгэрэнгүй
        </Button>
        {userRole === AuthRoles.AUDIT_ADMIN &&
          _.isEmpty((request as ContractModel).applicationRequestUid) && (
            <div className="flex flex-1 justify-end gap-x-4">
              <Button
                type="primary"
                shape="round"
                onClick={createApplication}
                loading={applicationLoading}
                disabled={
                  !_.isEmpty(
                    (request as ContractModel).applicationRequestUid,
                  ) ||
                  requestLoading ||
                  applicationLoading
                }
                icon={<PlusOutlined />}
              >
                Өргөдөл үүсгэх
              </Button>
            </div>
          )}
        {!_.isEmpty((request as ContractModel).applicationRequestUid) && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Tag color="processing" className="rounded-xl py-2 px-4">
              Өргөдлийн дугаар:{' '}
              {(request as ContractModel).applicationRequestNumber || '-'}
            </Tag>
          </div>
        )}
      </div>
      <hr />
      <Spin spinning={requestLoading || _.isEmpty(certificateRequest)}>
        <Row gutter={[16, 16]} className="my-8">
          <Col xs={24} lg={16} xxl={14}>
            <div className="card-container p-6 mb-8">
              <div className="text-lg font-medium"> Гэрээний мэдээлэл</div>
              <div className="mt-5 text-sm text-gray">Гэрээний дугаар</div>
              <div className="mt-2">{request.contractNumber}</div>
              <div className="mt-5 text-sm text-gray">Баталгаажуулалт</div>
              <div className="mt-1">
                {(request as ContractModel).certificateName || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">
                Тариаланчын гарын үсэг
              </div>
              <div className="mt-2 ml-5">
                {(request as ContractModel).farmerSignatureUrl && (
                  <Image
                    preview={false}
                    src={(request as ContractModel).farmerSignatureUrl || ''}
                    height={60}
                  />
                )}
              </div>
              <div className="mt-5 text-sm text-gray">
                Хүсэлт илгээсэн огноо
              </div>
              <div className="mt-2">
                {convertDateTimeFromTimestamp(request.createdAt)}
              </div>
              <div className="mt-5 text-sm text-gray">
                Гэрээ байгуулсан огноо
              </div>
              <div className="mt-2">
                {convertDateTimeFromTimestamp(request.signedAt)}
              </div>
              <div className="mt-5 text-sm text-gray">Статус</div>
              <div className="mt-2">
                {contractTag((request as ContractModel).status)}
              </div>
            </div>
            <div className="card-container p-6 mb-8">
              <div className="text-lg font-medium">Хэрэглэгчийн мэдээлэл</div>
              <div className="mt-5 text-sm text-gray">Хэрэглэгч</div>
              <div className="mt-1">
                {(request as ContractModel).userType === 'citizen'
                  ? 'Иргэн'
                  : (request as ContractModel).userType === 'company'
                  ? 'Компани'
                  : ''}
              </div>
              <div className="mt-5 text-sm text-gray">Овог, нэр</div>
              <div className="mt-1">
                {user.lastname} {user.firstname}
              </div>
              {user.userType === 'company' && (
                <>
                  <div className="mt-5 text-sm text-gray">Компаний нэр</div>
                  <div className="mt-1">{user.companyName}</div>
                </>
              )}
              <div className="mt-5 text-sm text-gray">Регистр</div>
              <div className="mt-1">
                {(request as ContractModel).userRegister || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
              <div className="mt-1">
                {(request as ContractModel).userPhone || emptyText}
              </div>
              <div className="mt-5 text-sm text-gray">Аймаг, сум</div>
              <div className="mt-1">
                {[user.city, user.district]
                  .filter((u) => !_.isEmpty(u))
                  .join(', ')}
              </div>
              <div className="mt-5 text-sm text-gray">НТ дугаар</div>
              <div className="mt-1">{user.ntDugaar || emptyText}</div>
            </div>
            {!_.isEmpty((certificateRequest as any)?.requestData) && (
              <div className="card-container p-6">
                <div className="text-lg font-medium mb-5">
                  Өөрийгөө үнэлэх хуудас
                </div>
                {(
                  JSON.parse((certificateRequest as any)?.requestData) as any[]
                ).map((q, i) => {
                  return (
                    <div className="mb-3" key={`question-${i}`}>
                      <div className="mb-1 text-gray text-sm">{q.question}</div>
                      {typeof q.answer == 'object' ? (
                        <div className="mb-1">
                          {(q.answer as any[]).map((f, fi) => (
                            <div
                              className="border border-solid border-surface mt-3 p-2 rounded-md"
                              key={`first-${fi}`}
                            >
                              {typeof f == 'string' ? (
                                <div>{f}</div>
                              ) : (
                                (f as any[]).map((s, si) => {
                                  return (
                                    <div className="mb-3" key={`second-${si}`}>
                                      <div className="mb-1 text-gray text-sm">
                                        {JSON.parse(s).question}:
                                      </div>
                                      <div>{JSON.parse(s).answer}</div>
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="mb-1">{q.answer}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ContractDetail;
