import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  message,
} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {
  AnswerModel,
  ApplicationRequestModel,
  ApplicationRequestStatus,
  CompanyModel,
  ApplicationModel,
} from '../../types';
import {firestore} from '../../firebase';
import {Routes} from '../../routes/route_helper';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  FileTextOutlined,
  FolderOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  getUidFirebase,
  saveData,
  useCollection,
  useDoc,
} from '../../helpers/firestore';
import {sendApplication} from '../../helpers/firebase_functions';
import {applicationRequestRules} from '../../helpers/validations';
import {appRequestAuditTypes} from '../../constants/app_data';
import {applicationRoles} from '../../helpers/role_helper';
import {uploadImageBlob} from '../../helpers/firebase_storage';
import * as _ from 'lodash';

const ApplicationCreate = () => {
  const {id} = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [docUid, setDocUid] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const [loadingData, setLoadingData] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [savedData, setSavedData] = useState(false);
  const [sentData, setSentData] = useState(false);

  //Company information
  const {userCompany, userRole} = useContext(UserContext);
  const [company, companyLoading] = useDoc(`companies/${userCompany}`);
  //Selected application
  const [applications, setApplications] = useState<ApplicationModel[]>([]);
  const [selectedApplication, setSelectedApplication] = useState<
    ApplicationModel | undefined
  >();
  //certifiate questionnaire in application
  const [certificate, certificateLoading] = useDoc(
    `certificates/${selectedApplication?.certificate || '0'}`,
  );
  const [formAnswers, setFormAnswers] = useState<AnswerModel[]>([]);
  const [applicationForm, loadingForm] = useCollection(
    `certificates/${selectedApplication?.certificate || '0'}/applicationForm`,
    'createdAt',
    undefined,
    undefined,
    false,
  );
  const [applicationScope, loadingScope] = useCollection(
    `applications/${selectedApplication?.uid || '0'}/scope`,
    'name',
  );
  const [applicationDocuments, loadingDocument] = useCollection(
    `applications/${selectedApplication?.uid || '0'}/documents`,
    'name',
  );
  const [fileList, setFileList] = useState<any[]>([]);
  //Application request data
  const [applicationRequest, setApplicationRequest] = useState<
    ApplicationRequestModel | undefined
  >();

  const goBack = () => navigate(Routes.applicationRequests);

  const getApplications = async () => {
    const data = await firestore.collection('applications').get();
    setApplications(
      data.docs.map((d) => ({...d.data(), uid: d.id} as ApplicationModel)),
    );
  };

  const getApplicationRequest = async () => {
    setLoadingData(true);
    const data = await firestore
      .doc(`companies/${userCompany}/requests/${id}`)
      .get();
    const applicationData = {
      ...data.data(),
      uid: data.id,
    } as ApplicationRequestModel;
    setApplicationRequest(applicationData);
    setSentData(['sent', 'accepted'].includes(applicationData?.status || ''));
    setFileList(applicationData.documents || []);
    form.setFieldsValue(applicationData);
    if (!_.isEmpty(applicationData.formData)) {
      setFormAnswers(JSON.parse(applicationData.formData) ?? []);
    }
    setLoadingData(false);
  };

  const validate = () => {
    return (
      applicationRoles.addEditRequest.includes(userRole) &&
      !_.isEmpty(form.getFieldValue('applicationUid')) &&
      !_.isEmpty(form.getFieldValue('auditType')) &&
      !_.isEmpty(form.getFieldValue('ceo')) &&
      !_.isEmpty(selectedApplication) &&
      !_.isEmpty(certificate) &&
      !sentData
    );
  };

  const saveRequest = async () => {
    form.validateFields();
    if (!validate()) return;
    setLoadingSave(true);
    let docUid = id;
    if (_.isEmpty(docUid)) {
      docUid = await getUidFirebase();
    }
    setDocUid(docUid!);
    var documents: any[] = [];
    for (var file of fileList) {
      const imageUrl = await handleUpload(file.file, docUid!, file.ext);
      documents.push({...file, file: imageUrl});
    }
    await saveData(`companies/${userCompany}/requests`, docUid, {
      ...form.getFieldsValue(),
      organizationStructure: form.getFieldsValue().organizationStructure ?? [],
      status: ApplicationRequestStatus.created,
      certificateUid: selectedApplication?.certificate,
      applicationUid: selectedApplication?.uid,
      applicationName: selectedApplication?.name,
      formData: JSON.stringify(formAnswers),
      companyUid: userCompany,
      documents,
      companyName: (company as any).companyName,
      auditCompanyId: selectedApplication?.companyId,
      createdAt: applicationRequest?.createdAt || new Date(),
      type: 'local',
    });
    setLoadingSave(false);
    setSavedData(true);
    messageApi.open({
      type: 'success',
      content: 'Өргөдөл хадгалагдлаа.',
    });
  };

  const sendRequest = async () => {
    form.validateFields();
    if (!validate()) return;
    setLoadingSend(true);
    const result = await sendApplication(docUid, userCompany);
    setLoadingSend(false);
    if (result.status) {
      setSentData(true);
      messageApi.open({
        type: 'success',
        content: 'Өргөдлийн хүсэлт илгээлээ',
      });
    } else {
      messageApi.open({
        type: 'error',
        content: result?.msg ?? 'Өргөдлийн хүсэлт илгээхэд алдаа гарлаа',
      });
    }
  };

  useEffect(() => {
    getApplications();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(applicationRequest) && !_.isEmpty(applications)) {
      setSelectedApplication(
        (applications as ApplicationModel[]).find(
          (a) => a.uid === applicationRequest.applicationUid,
        ),
      );
    }
  }, [applicationRequest, applications]);

  useEffect(() => {
    if (
      applicationRoles.addEditRequest.includes(userRole) &&
      !_.isEmpty(userCompany)
    ) {
      getApplicationRequest();
    }
  }, [userCompany, userRole]);

  const onChangeFormAnswer = (
    value: any,
    questionId: string,
    questionData: any,
  ) => {
    const exist = formAnswers.filter((a) => a.id === questionId).length > 0;
    if (exist) {
      setFormAnswers(
        formAnswers.map((f) => {
          if (f.id === questionId) return {...f, answer: value};
          return f;
        }),
      );
    } else {
      setFormAnswers([
        ...formAnswers,
        {
          id: questionId,
          groupId: questionData.groupId,
          question: questionData.question,
          answer: value,
        },
      ]);
    }
  };

  const onRemoveFile = (docId: string, file: UploadFile) => {
    const newFileList = fileList.map((f) => {
      if (f.uid === docId) {
        return {uid: f.uid, file: null};
      } else {
        return f;
      }
    });
    setFileList(newFileList);
  };

  const beforeUpload = (docId: string, name: string, file: UploadFile) => {
    let newFileList: any[] = [];
    const exist = fileList.filter((f) => f.uid === docId).length > 0;
    if (exist) {
      newFileList = fileList.map((f) => {
        if (f.uid === docId) {
          return {uid: f.uid, file: file};
        } else {
          return f;
        }
      });
    } else {
      newFileList = [
        ...fileList,
        {uid: docId, name, ext: `.${file.name.split('.').pop()}`, file: file},
      ];
    }
    setFileList(newFileList);
  };

  const handleUpload = async (file: Blob, uid: string, ext: string) => {
    try {
      const result = await uploadImageBlob(
        file,
        `images/application/${uid}`,
        ext,
      );
      return result as string;
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: e ?? 'Зураг upload хийхэд алдаа гарлаа!',
      });
      return '';
    }
  };

  const requireLabel = <span className="text-error">*</span>;

  return (
    <div>
      {contextHolder}
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="circle"
          size="small"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        />
        <div className="text-lg font-medium">Баталгаажуулалтын өргөдлүүд</div>
      </div>
      <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
      <Spin
        spinning={
          loadingData ||
          companyLoading ||
          _.isEmpty(applications) ||
          certificateLoading ||
          loadingDocument ||
          loadingScope ||
          loadingForm
        }
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={16} xxl={10}>
            <div className="card-container p-6">
              <div className="text-xl font-medium">Байгууллагын мэдээлэл</div>
              <div className="mt-5 text-sm text-gray">Байгууллага</div>
              <div className="mt-1">
                {(company as CompanyModel).companyName}
              </div>
              <div className="mt-5 text-sm text-gray">Регистрийн дугаар</div>
              <div className="mt-1">
                {(company as CompanyModel).registrationNumber}
              </div>
              <div className="mt-5 text-sm text-gray">Хаяг</div>
              <div className="mt-1">
                {[
                  (company as CompanyModel).city,
                  (company as CompanyModel).district,
                  (company as CompanyModel).address,
                ].join(', ')}
              </div>
              <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
              <div className="mt-1">{(company as CompanyModel).phone}</div>
              <div className="mt-5 text-sm text-gray">Имэйл хаяг</div>
              <div className="mt-2">{(company as CompanyModel).email}</div>
            </div>
          </Col>
          <Col xs={24} xl={16} xxl={14}>
            <div className="card-container p-6">
              <Form form={form} scrollToFirstError>
                <div className="text-xl font-medium mb-5">
                  <FileTextOutlined /> <span className="mx-2">Өргөдөл</span>
                </div>
                <div>Баталгаажуулалтын төрөл {requireLabel}</div>
                <Form.Item
                  rules={applicationRequestRules.certificateUid}
                  name="applicationUid"
                >
                  <Select
                    onChange={(value) => {
                      setSelectedApplication(
                        applications.find(
                          (a: ApplicationModel) => a.uid === value,
                        ),
                      );
                      setFormAnswers([]);
                      setFileList([]);
                    }}
                    options={applications.map((c: any) => ({
                      value: c.uid,
                      label: c.name,
                    }))}
                  />
                </Form.Item>
                <div>Аудитын төрөл {requireLabel}</div>
                <Form.Item
                  rules={applicationRequestRules.auditType}
                  name="auditType"
                >
                  <Select
                    options={appRequestAuditTypes.map((c: any) => ({
                      value: c.value,
                      label: c.name,
                    }))}
                  />
                </Form.Item>
                <div>Баталгаажуулалт хүсч буй хамрах хүрээ</div>
                <Form.Item initialValue="" name="auditScope">
                  <Select
                    options={applicationScope.map((c: any) => ({
                      value: c.name,
                      label: c.name,
                    }))}
                  />
                </Form.Item>
                <div>Зөвлөх үйлчилгээ үзүүлсэн байгууллагын мэдээлэл</div>
                <Form.Item initialValue="" name="advisor">
                  <Input />
                </Form.Item>
                <div>Гүйцэтгэх захирал (Ерөнхий захирал) {requireLabel}</div>
                <Form.Item rules={applicationRequestRules.ceo} name="ceo">
                  <Input />
                </Form.Item>
                <div>Байгуулагын бүтэц, бүрэлдэхүүн</div>
                <Form.List name="organizationStructure">
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map(({key, name, ...restField}) => (
                        <Space key={key} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            className="flex-1"
                          >
                            <Input placeholder="Бүтцийн нэр" />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'employees']}>
                            <Input placeholder="Ажилчдын тоо" />
                          </Form.Item>
                          <DeleteOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          onClick={() => add()}
                          block
                          className="text-sm"
                          icon={<PlusCircleOutlined />}
                        >
                          Нэмэх
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div>Бусад тайлбар</div>
                <Form.Item initialValue="" name="description">
                  <Input.TextArea />
                </Form.Item>
                <div>Үргэлжилсэн байнгын үйл ажиллагаатай</div>
                <Form.Item initialValue={false} name="continuous">
                  <Radio.Group>
                    <Radio value={true}> Тийм </Radio>
                    <Radio value={false}> Үгүй </Radio>
                  </Radio.Group>
                </Form.Item>
                <div>Улирлын чанартай</div>
                <Form.Item initialValue={false} name="seasonal">
                  <Radio.Group>
                    <Radio value={true}>Тийм</Radio>
                    <Radio value={false}>Үгүй</Radio>
                  </Radio.Group>
                </Form.Item>
                <div>Бүтээгдэхүүн/үйлчилгээ бүр онцлогтой</div>
                <Form.Item initialValue={false} name="special">
                  <Radio.Group>
                    <Radio value={true}>Тийм</Radio>
                    <Radio value={false}>Үгүй</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
              {certificate?.applicationGroups?.map((a: any) => (
                <div key={`appgroups-${a.id}`}>
                  <div className="bg-primary mt-5 mb-5 h-[1px]"></div>
                  <div className="text-xl font-medium mb-5">
                    <FileTextOutlined /> <span className="mx-2">{a.name}</span>
                  </div>
                  {(applicationForm as any[])
                    .filter((f) => f.groupId === a.id)
                    .map((f) => (
                      <div key={f.uid} className="mb-4">
                        <div className="mb-1">{f.question}</div>
                        {f.type === 'single_choice' ? (
                          <Radio.Group
                            value={
                              formAnswers.find((a) => a.id === f.uid)?.answer
                            }
                            onChange={(e) => {
                              onChangeFormAnswer(e.target.value, f.uid, f);
                            }}
                          >
                            {(f.options as any[]).map((o) => (
                              <Radio
                                key={`option-${o.id}`}
                                className="my-2"
                                value={o.value}
                              >
                                {o.value}
                              </Radio>
                            ))}
                          </Radio.Group>
                        ) : (
                          <Input
                            value={
                              formAnswers.find((a) => a.id === f.uid)?.answer
                            }
                            onChange={(e) => {
                              onChangeFormAnswer(e.target.value, f.uid, f);
                            }}
                          />
                        )}
                      </div>
                    ))}
                </div>
              ))}
              <div className="bg-primary mt-5 mb-5 h-[1px]"></div>
              <div className="text-xl font-medium mb-5">
                <FolderOutlined />{' '}
                <span className="mx-2">Хавсаргах баримт бичиг</span>
              </div>
              {(applicationDocuments as any[]).map((d) => (
                <div key={d.uid} className="flex flex-col my-2 gap-2">
                  {d.name}
                  <Upload
                    onRemove={(file) => {
                      onRemoveFile(d.uid, file);
                    }}
                    beforeUpload={(file) => {
                      beforeUpload(d.uid, d.name, file);
                    }}
                    fileList={fileList.filter((f) => f.uid === d.uid)}
                    maxCount={1}
                  >
                    <Button
                      shape="round"
                      size="small"
                      block
                      icon={<UploadOutlined />}
                    >
                      <span className="text-sm">Файл сонгох</span>
                    </Button>
                  </Upload>
                  <div className="bg-surface my-2 h-[1px]"></div>
                </div>
              ))}
              <div className="flex gap-4 justify-center mt-5">
                <Button
                  type="primary"
                  shape="round"
                  className="w-64 mt-4"
                  disabled={
                    loadingSave || loadingSend || loadingData || sentData
                  }
                  loading={loadingSave}
                  icon={<SaveOutlined />}
                  onClick={saveRequest}
                >
                  Хадгалах
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  className="w-64 mt-4"
                  disabled={
                    loadingSave ||
                    loadingSend ||
                    loadingData ||
                    !savedData ||
                    sentData
                  }
                  loading={loadingSend}
                  icon={<SendOutlined />}
                  onClick={sendRequest}
                >
                  Илгээх
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ApplicationCreate;
