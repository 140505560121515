import {useCollection} from '../../../helpers/firestore';
import DataTable from '../../../components/table/Index';
import {AuditPlan} from '../../../types';
import {ColumnsType} from 'antd/es/table';

type Props = {
  uid?: String;
};

const columns: ColumnsType<AuditPlan> = [
  {
    key: 'name',
    title: 'Үйл ажиллагаа',
    dataIndex: 'name',
  },
  {
    key: 'date',
    title: 'Огноо',
    dataIndex: 'date',
  },
  {
    key: 'startHour',
    title: 'Хугацаа',
    dataIndex: 'startHour',
    render: (_, record) => (
      <div>
        {record.startHour} - {record.endHour}
      </div>
    ),
  },
  {
    key: 'auditor',
    title: 'Оролцох ажилтнууд',
    dataIndex: 'auditor',
  },
  {
    key: 'location',
    title: 'Байршил',
    dataIndex: 'location',
  },
];
const AuditReportPlan: React.FC<Props> = ({uid}) => {
  const [plans] = useCollection(`audits/${uid}/plans`);

  return (
    <DataTable
      tableName="Аудитын төлөвлөгөө"
      columns={columns}
      data={plans}
      pagination={false}
    />
  );
};

export default AuditReportPlan;
