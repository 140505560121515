import {Button, Col, Row, Spin, message} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {
  ApplicationRequestModel,
  ApplicationRequestStatus,
  CertificateRequestModel,
  CompanyModel,
} from '../../types';
import {firestore} from '../../firebase';
import {Routes} from '../../routes/route_helper';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  FileTextOutlined,
  FolderOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import {useDoc} from '../../helpers/firestore';
import {appRequestAuditTypes} from '../../constants/app_data';
import RejectReason from '../../components/RejectReason';
import {
  acceptApplication,
  rejectApplication,
} from '../../helpers/firebase_functions';
import {applicationRequestTag, emptyText} from '../../helpers/theme_helper';
import {applicationRoles} from '../../helpers/role_helper';
import * as _ from 'lodash';

const ApplicationInfo = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingData, setLoadingData] = useState(true);
  const [loadingAccept, setLoadingAccept] = useState(false);
  //Company information
  const {userRole} = useContext(UserContext);
  //Application request data
  const [application, setApplication] = useState<
    ApplicationRequestModel | undefined
  >();
  const [certRequest, setCertRequest] = useState<
    CertificateRequestModel | undefined
  >();
  const [company, companyLoading] = useDoc(
    `companies/${application?.companyUid || '0'}`,
  );
  const [farmer, farmerLoading] = useDoc(
    `users/${application?.farmerUid || '0'}`,
  );
  const [certificate, certificateLoading] = useDoc(
    `certificates/${application?.certificateUid || '0'}`,
  );

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const goBack = () => navigate(Routes.applicationRequests);

  const getApplicationRequest = async () => {
    setLoadingData(true);
    const data = await firestore.doc(`applicationRequests/${id}`).get();
    if (data.data()?.type === 'finy') {
      const certReq = await firestore
        .doc(
          `certificateRequests/${data.data()?.farmerUid || '0'}${
            data.data()?.certificateUid || '0'
          }`,
        )
        .get();
      if (certReq.exists) {
        setCertRequest({
          ...certReq.data(),
          uid: certReq.id,
        } as CertificateRequestModel);
      }
    }
    setApplication({...data.data(), uid: data.id} as ApplicationRequestModel);
    setLoadingData(false);
  };

  const acceptRequest = async () => {
    if (id == null) return;
    if (application?.status === ApplicationRequestStatus.accepted) return;
    const confirm = window.confirm('Хүсэлт хүлээн авахдаа итгэлтэй байна уу ?');
    if (!confirm) return;
    setLoadingAccept(true);
    const requestResult = await acceptApplication(id);
    if (!requestResult.status) {
      setLoadingAccept(false);
      messageApi.open({
        type: 'error',
        content: requestResult.msg,
      });
    } else {
      setLoadingAccept(false);
      await getApplicationRequest();
      messageApi.open({
        type: 'success',
        content: 'Хүсэлтийг хүлээн авлаа.',
      });
    }
  };

  const rejectRequest = async (value: string) => {
    if (id == null) return;
    const requestResult = await rejectApplication(id, value);
    await getApplicationRequest();
    return requestResult;
  };

  useEffect(() => {
    if (applicationRoles.viewRequest.includes(userRole)) {
      getApplicationRequest();
    }
  }, [userRole]);

  return (
    <div>
      {contextHolder}
      <div className="flex flex-wrap items-center gap-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Өргөдлийн дэлгэрэнгүй
        </Button>
        {applicationRoles.verifyRejectRequest.includes(userRole) && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Button
              shape="round"
              onClick={toggleModal}
              disabled={
                application?.status === ApplicationRequestStatus.accepted ||
                loadingAccept ||
                loadingData
              }
              icon={<CloseOutlined />}
            >
              Буцаах
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={acceptRequest}
              loading={loadingAccept}
              disabled={
                application?.status === ApplicationRequestStatus.accepted ||
                loadingData
              }
              icon={<CheckOutlined />}
            >
              Хүлээн авах
            </Button>
          </div>
        )}
      </div>
      <hr />
      <Spin
        spinning={
          loadingData || companyLoading || certificateLoading || farmerLoading
        }
      >
        <Row gutter={[16, 16]} className="my-8 pb-16">
          <Col xs={24} lg={16} xxl={14}>
            <div className="card-container p-6">
              <div>
                <span className="mr-5">Хүсэлтийн статус:</span>
                {application?.status != null &&
                  applicationRequestTag(application?.status)}
              </div>
              {application?.status === ApplicationRequestStatus.rejected && (
                <div className="mt-4">
                  <span className="mr-3 text-sm">Буцаагдсан шалтгаан:</span>
                  <span className="text-sm text-error">
                    {application?.rejectReason || ''}
                  </span>
                </div>
              )}
            </div>
          </Col>
          {(application as ApplicationRequestModel)?.type !== 'finy' ? (
            <Col xs={24} lg={16} xxl={14}>
              <div className="card-container p-6">
                <div className="text-xl font-medium">Байгууллагын мэдээлэл</div>
                <div className="mt-5 text-sm text-gray">Байгууллага</div>
                <div className="mt-1">
                  {(company as CompanyModel).companyName}
                </div>
                <div className="mt-5 text-sm text-gray">Регистрийн дугаар</div>
                <div className="mt-1">
                  {(company as CompanyModel).registrationNumber}
                </div>
                <div className="mt-5 text-sm text-gray">Хаяг</div>
                <div className="mt-1">
                  {[company.city, company.district, company.address]
                    .filter((u) => !_.isEmpty(u))
                    .join(', ')}
                </div>
                <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
                <div className="mt-1">{(company as CompanyModel).phone}</div>
                <div className="mt-5 text-sm text-gray">Имэйл хаяг</div>
                <div className="mt-2">{(company as CompanyModel).email}</div>
              </div>
            </Col>
          ) : (
            <Col xs={24} lg={16} xxl={14}>
              <div className="card-container p-6">
                <div className="text-xl font-medium">Тариаланчийн мэдээлэл</div>
                <div className="mt-5 text-sm text-gray">Тариаланчийн нэр</div>
                <div className="mt-1">
                  {(application as ApplicationRequestModel)?.farmerName}
                </div>
                <div className="mt-5 text-sm text-gray">Регистрийн дугаар</div>
                <div className="mt-1">
                  {(application as ApplicationRequestModel)?.farmerRegister}
                </div>
                <div className="mt-5 text-sm text-gray">Хаяг</div>
                <div className="mt-1">
                  {[farmer.city, farmer.district]
                    .filter((u) => !_.isEmpty(u))
                    .join(', ')}
                </div>
                <div className="mt-5 text-sm text-gray">Утасны дугаар</div>
                <div className="mt-1">
                  {(application as ApplicationRequestModel)?.farmerPhone}
                </div>
                <div className="mt-5 text-sm text-gray">НТ дугаар</div>
                <div className="mt-1">{farmer.ntDugaar}</div>
                <div className="mt-5 text-sm text-gray">Гэрээний дугаар</div>
                <div className="mt-1">
                  {(application as ApplicationRequestModel)?.contractNumber}
                </div>
              </div>
            </Col>
          )}
          {(application as ApplicationRequestModel)?.type !== 'finy' ? (
            <>
              <Col xs={24} lg={16} xxl={14}>
                <div className="card-container p-6">
                  <div className="text-xl font-medium mb-5">Өргөдөл</div>
                  <div className="mt-5 text-sm text-gray">
                    Баталгаажуулалтын төрөл
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)
                      ?.applicationName || emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">Аудитын төрөл</div>
                  <div className="mt-1">
                    {appRequestAuditTypes.find(
                      (a) =>
                        a.value ===
                        (application as ApplicationRequestModel)?.auditType,
                    )?.name || emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">
                    Баталгаажуулалт хүсч буй хамрах хүрээ
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.auditScope ||
                      emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">
                    Зөвлөх үйлчилгээ үзүүлсэн байгууллагын мэдээлэл
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.advisor ||
                      emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">
                    Гүйцэтгэх захирал (Ерөнхий захирал)
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.ceo || emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">
                    Байгуулагын бүтэц, бүрэлдэхүүн
                  </div>
                  <div className="mt-1">
                    {!_.isEmpty(
                      (application as ApplicationRequestModel)
                        ?.organizationStructure,
                    )
                      ? application?.organizationStructure.map(
                          (o: any, i: number) => (
                            <div
                              key={`organizationStructure-${i}`}
                              className="mb-2"
                            >
                              <span>Нэр: {o.name}, </span>
                              <span>Ажилчдын тоо: {o.employees}</span>
                            </div>
                          ),
                        )
                      : emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">Бусад тайлбар</div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.description ||
                      emptyText}
                  </div>
                  <div className="mt-5 text-sm text-gray">
                    Үргэлжилсэн байнгын үйл ажиллагаатай
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.continuous
                      ? 'Тийм'
                      : 'Үгүй'}
                  </div>
                  <div className="mt-5 text-sm text-gray">Улирлын чанартай</div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.seasonal
                      ? 'Тийм'
                      : 'Үгүй'}
                  </div>
                  <div className="mt-5 text-sm text-gray">
                    Бүтээгдэхүүн/үйлчилгээ бүр онцлогтой
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel)?.special
                      ? 'Тийм'
                      : 'Үгүй'}
                  </div>
                </div>
              </Col>
              {!_.isEmpty(
                (application as ApplicationRequestModel)?.formData,
              ) && (
                <Col xs={24} lg={16} xxl={14}>
                  <div className="card-container p-6">
                    {(certificate as any)?.applicationGroups?.map((a: any) => (
                      <div key={`appgroups-${a.id}`}>
                        <div className="text-xl font-medium mb-5">
                          <FileTextOutlined />{' '}
                          <span className="mx-2">{a.name}</span>
                        </div>
                        {JSON.parse(
                          (application as ApplicationRequestModel)?.formData,
                        )
                          .filter((f: any) => f.groupId === a.id)
                          .map((f: any) => (
                            <div key={f.id} className="mb-4">
                              <div className="mt-5 text-sm text-gray">
                                {f.question}
                              </div>
                              <div className="mt-1">{f.answer}</div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                </Col>
              )}
              <Col xs={24} lg={16} xxl={14}>
                <div className="card-container p-6">
                  <div className="text-xl font-medium mb-5">
                    <FolderOutlined />{' '}
                    <span className="mx-2">Хавсаргах баримт бичиг</span>
                  </div>
                  {application?.documents?.map((d: any) => (
                    <div key={d.uid} className="mb-3">
                      <Link to={d.file} target="_blank">
                        <PaperClipOutlined /> {d.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </Col>
            </>
          ) : !_.isEmpty((certRequest as any)?.requestData) ? (
            <Col xs={24} lg={16} xxl={14}>
              <div className="card-container p-6">
                <div className="text-xl font-medium mb-5">
                  Өөрийгөө үнэлэх хуудас
                </div>
                {(JSON.parse((certRequest as any)?.requestData) as any[]).map(
                  (q, i) => {
                    return (
                      <div className="mb-3" key={`question-${i}`}>
                        <div className="mb-1 text-gray text-sm">
                          {q.question}
                        </div>
                        {typeof q.answer == 'object' ? (
                          <div className="mb-1">
                            {(q.answer as any[]).map((f, fi) => (
                              <div
                                className="border border-solid border-surface mt-3 p-2 rounded-md"
                                key={`first-${fi}`}
                              >
                                {typeof f == 'string' ? (
                                  <div>{f}</div>
                                ) : (
                                  (f as any[]).map((s, si) => {
                                    return (
                                      <div
                                        className="mb-3"
                                        key={`second-${si}`}
                                      >
                                        <div className="mb-1 text-gray text-sm">
                                          {JSON.parse(s).question}:
                                        </div>
                                        <div>{JSON.parse(s).answer}</div>
                                      </div>
                                    );
                                  })
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="mb-1">{q.answer}</div>
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </Spin>
      <RejectReason open={open} toggle={toggleModal} onSubmit={rejectRequest} />
    </div>
  );
};

export default ApplicationInfo;
