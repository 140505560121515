import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import firebase from 'firebase/compat/app';
import {getAuth} from 'firebase/auth';

const config = {
  apiKey: 'AIzaSyDbsfieAeCbtCRiruX-REkI8YmgC0FRFCg',
  authDomain: 'finyapp-766d4.firebaseapp.com',
  projectId: 'finyapp-766d4',
  storageBucket: 'cdn.finy.app',
  messagingSenderId: '557802215171',
  appId: '1:557802215171:web:d85e4fb805c2a6c3417b36',
  measurementId: 'G-SL06VLV221',
};

let app;

const initializeFirebase = () => {
  if (!firebase.apps.length) {
    app = firebase.initializeApp(config);
  }
};

initializeFirebase();

const auth = getAuth(app);
const firebaseAuth = firebase.auth(app);
const storage = firebase.storage(app);
const firestore = firebase.firestore(app);
const functions = firebase.functions(app);

export {app, firestore, storage, auth, firebaseAuth, functions};
