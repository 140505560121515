import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Input} from 'antd';
import * as _ from 'lodash';

import {loginUser} from '../../helpers/firebase_auth';
import LogoImage from '../../assets/images/logo.png';
import {Routes} from '../../routes/route_helper';
import ResetPass from './ResetPass';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const onSubmit = async () => {
    if (!validate()) return;
    try {
      setLoading(true);
      await loginUser(username, password);
      navigate(Routes.dashboard);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }
  };

  const validate = () => {
    if (_.isEmpty(username) || _.isEmpty(password)) return false;
    return true;
  };

  const companyRequest = () => {
    navigate(Routes.companySendRequest);
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="card-container">
        <div className="border rounded-md border-gray-900 w-full sm:w-auto">
          <div className="dialog-header rounded-t-md">
            <div className="text-xl font-medium">Finy App</div>
          </div>
          <div className="dialog-body">
            <div className="mt-4 flex justify-center">
              <img alt="logo" src={LogoImage} height={140} width="auto" />
            </div>
            <div className="dialog-content px-8">
              <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Нэвтрэх нэр"
                className="mb-3"
              />
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Нууц үг"
                className="mb-4"
              />
              <div className="flex justify-center mb-4">
                <div
                  onClick={toggleModal}
                  className="cursor-pointer text-info font-medium"
                >
                  Нууц үг сэргээх
                </div>
              </div>
              <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
              <div className="flex justify-center mb-4">
                <div
                  onClick={companyRequest}
                  className="cursor-pointer text-info font-medium"
                >
                  Бүртгүүлэх хүсэлт илгээх
                </div>
              </div>
              <div className="error-text text-sm pt-4 text-error">{error}</div>
            </div>
          </div>
          <div className="dialog-footer rounded-b-md">
            <Button
              disabled={loading}
              className="w-full"
              type="primary"
              shape="round"
              onClick={onSubmit}
            >
              Нэвтрэх
            </Button>
          </div>
          <ResetPass open={open} toggle={setOpen} />
        </div>
      </div>
    </div>
  );
};

export default Login;
