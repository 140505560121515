import uuid from 'react-uuid';

import {ref, uploadBytesResumable, UploadTaskSnapshot} from 'firebase/storage';
import {storage} from '../firebase';

const getBucketDownloadUrl = (filePath: string) =>
  `https://cdn.finy.app/${filePath}`;

export const uploadImageBlob = async (
  data: Blob,
  path: string,
  ext: string = '',
) => {
  if (!data) {
    return 'Please choose a file first!';
  }

  const fileName = Date.now();
  return new Promise(async function (resolve, reject) {
    const storageRef = ref(storage, `/${path}/${fileName}${ext}`);
    const uploadTask = uploadBytesResumable(storageRef, data);

    uploadTask.on(
      'state_changed',
      (snapshot) => statusTracker(snapshot),
      (err) => reject(err.message),
      () => resolve(getBucketDownloadUrl(uploadTask.snapshot.ref.fullPath)),
    );
  });
};

function statusTracker(snapshot: UploadTaskSnapshot) {
  const percent = Math.round(
    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
  );
}

function dataURLtoFile(dataurl: any) {
  const filename = uuid();
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}
