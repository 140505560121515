import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import DataTable from '../../components/table/Index';
import {cityData} from '../../constants/city_data';
import * as _ from 'lodash';
import {Button, Input, Tag, message} from 'antd';
import {
  getCertificateModalChild,
  getFieldModalChild,
  requestTag,
} from '../../helpers/theme_helper';
import {firestore} from '../../firebase';
import {finyRequestRoles} from '../../helpers/role_helper';
import RequestFormModal from './RequestFormModal';
import {
  verifyCertificateRequest,
  verifyFieldRequest,
} from '../../helpers/firebase_functions';
import {ContractType, RequestStatus} from '../../types';

const FinyContractorRequests = () => {
  const {userRole, user} = useContext(UserContext);
  const [requests, setRequests] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]);
  const [fieldRequests, setFields] = useState<any[]>([]);
  const [loadingData, setLoading] = useState(false);
  const [certificates, setCertificates] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = (value: boolean) => setOpenModal(value);
  const [modalChild, setModalChild] = useState<JSX.Element>(<></>);
  const [loadingVerify, setLoadingVerify] = useState(false);

  const columns: ColumnsType<any> = [
    {
      key: 'userPhone',
      title: 'Хэрэглэгч',
      dataIndex: 'userPhone',
    },
    {
      key: 'userCity',
      title: 'Аймаг',
      dataIndex: 'userCity',
      render: (userCity: string) =>
        cityData.find((c) => c.code === userCity)?.name || '',
    },
    {
      key: 'verifyItem',
      title: 'Хүсэлт',
      dataIndex: 'verifyItem',
      render: (_, record) => (
        <Tag
          className="cursor-pointer"
          color="processing"
          onClick={() => {
            showDetail(record, record.type);
          }}
        >
          {record.verifyItem}
        </Tag>
      ),
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => requestTag(status),
    },
    {
      key: 'sentAt',
      title: 'Огноо',
      dataIndex: 'sentAt',
      render: (sentAt: Timestamp) => convertDateTimeFromTimestamp(sentAt),
    },
    {
      key: 'action',
      title: '',
      dataIndex: 'action',
      render: (_, record) =>
        record.type === 'certificate' && record.isContract === true ? (
          <div></div>
        ) : (
          <Button
            size="small"
            type="primary"
            shape="round"
            disabled={record.status === RequestStatus.verified}
            onClick={() => {
              verify(record);
            }}
          >
            Баталгаажуулах
          </Button>
        ),
    },
  ];

  const showDetail = (request: any, type: string) => {
    setModalChild(
      type === 'field'
        ? getFieldModalChild(request)
        : getCertificateModalChild(request),
    );
    toggleModal(true);
  };

  const prepareData = () => {
    const field = (fieldRequests as any[]).map((l) => ({
      ...l,
      verifyItem: 'Талбайн түүх',
      assigned: !_.isEmpty(l.assignedTo),
      type: 'field',
    }));
    const certificate = (certificates as any[]).map((c) => ({
      ...c,
      verifyItem: c.isContract
        ? c.contractType === ContractType.amlaltUurgiinGeree
          ? 'Амлалт үүргийн гэрээ'
          : 'Баталгаажуулалтын гэрээ'
        : 'Гэрчилгээ баталгаажуулах',
      assigned: !_.isEmpty(c.assignedTo),
      type: 'certificate',
    }));
    const data = [...field, ...certificate];
    const sorted = data.sort(function (x, y) {
      return y.sentAt - x.sentAt;
    });
    setRequests(sorted);
    setShowData(sorted);
  };

  const getData = async () => {
    setLoading(true);
    const fieldPromise = firestore
      .collection('fieldRequests')
      .where('assignedTo', '==', user.uid)
      .orderBy('sentAt', 'desc');
    const certPromise = firestore
      .collection('certificateRequests')
      .where('assignedTo', '==', user.uid)
      .orderBy('sentAt', 'desc');
    const data = await Promise.all([fieldPromise.get(), certPromise.get()]);
    setFields(data[0].docs.map((d) => ({...d.data(), uid: d.id})));
    setCertificates(data[1].docs.map((d) => ({...d.data(), uid: d.id})));
    setLoading(false);
  };

  const verify = async (request: any) => {
    if (loadingVerify) return;
    if (request.type === 'certificate' && request.isContract === true) {
      return;
    }
    setLoadingVerify(true);
    let result;
    if (request.type === 'field') {
      result = await verifyFieldRequest(request.uid);
    } else {
      result = await verifyCertificateRequest(request.uid);
    }
    await getData();
    if (!result.status) {
      setLoadingVerify(false);
      messageApi.open({
        type: 'error',
        content: result.msg,
      });
    } else {
      setLoadingVerify(false);
      messageApi.open({
        type: 'success',
        content: 'Хүсэлт баталгаажлаа',
      });
    }
  };

  const searchUser = () => {
    setShowData(requests.filter((r) => r.userPhone.includes(search)));
  };

  useEffect(() => {
    if (
      finyRequestRoles.viewRequests.includes(userRole) &&
      !_.isEmpty(user.uid)
    ) {
      getData();
    }
  }, [userRole, user]);

  useEffect(() => {
    prepareData();
  }, [fieldRequests, certificates]);

  return (
    <div>
      {contextHolder}
      <RequestFormModal
        open={openModal}
        toggle={toggleModal}
        children={modalChild}
      />
      <DataTable
        tableName="Фини хүсэлтүүд"
        columns={columns}
        data={showData}
        loading={loadingData || _.isEmpty(userRole) || loadingVerify}
        reloadData={getData}
        onSearch={searchUser}
        filter={[
          <Input
            key="phone"
            size="small"
            className="w-auto"
            placeholder="Утасны дугаар"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />,
        ]}
      />
    </div>
  );
};

export default FinyContractorRequests;
