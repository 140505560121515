import firebase from 'firebase/compat/app';
import {firebaseAuth as auth} from '../firebase';
import {Routes} from '../routes/route_helper';

export const registerUser = (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    auth.createUserWithEmailAndPassword(email, password).then(
      (user) => {
        resolve(user);
      },
      (error) => {
        reject(_handleError(error));
      },
    );
  });
};

export const loginUser = (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    auth.signInWithEmailAndPassword(email, password).then(
      (user) => {
        resolve(user);
      },
      (error) => {
        reject(_handleError(error));
      },
    );
  });
};

export const reauthenticate = (pass: string) => {
  return new Promise((resolve, reject) => {
    if (auth.currentUser == null) reject();
    const email = auth.currentUser!.email;
    const credential = firebase.auth.EmailAuthProvider.credential(email!, pass);
    auth
      .currentUser!.reauthenticateWithCredential(credential)
      .then(() => resolve(true))
      .catch((error) => reject(_handleError(error)));
  });
};

export const changePassword = (newPassword: string) => {
  return new Promise((resolve, reject) => {
    if (auth.currentUser == null) reject();
    auth
      .currentUser!.updatePassword(newPassword)
      .then(() => resolve(true))
      .catch((error) => reject(_handleError(error)));
  });
};

export const forgotPassword = (email: string) => {
  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email, {
        url:
          window.location.protocol + '//' + window.location.host + Routes.login,
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(_handleError(error));
      });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(_handleError(error));
      });
  });
};

const _handleError = (error: any) => {
  var errorMessage = error.message;
  return errorMessage;
};
