import {emptyText} from '../../../helpers/theme_helper';

type Props = {
  report?: string;
};

const AuditReportResult: React.FC<Props> = ({report}) => {
  return (
    <div className="border my-4 border-solid border-surface p-5">
      <div className="mb-4 text-info">5.1 Сайн туршлага, давуу тал</div>
      <div>{report || emptyText}</div>
    </div>
  );
};

export default AuditReportResult;
