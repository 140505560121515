import {PlusOutlined} from '@ant-design/icons';
import {Button, Input} from 'antd';
import {useContext, useEffect, useState} from 'react';
import DataTable from '../../components/table/Index';
import {UserContext} from '../../context/app.contexts';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import AuditAuditorAdd from './AuditorAdd';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import {tcAuditorList} from '../../helpers/table_columns';
import {AuditorModel} from '../../types';

const AuditAuditors = () => {
  const navigate = useNavigate();
  const {userCompany} = useContext(UserContext);
  const [auditors, setAuditors] = useState([] as any);
  const [showData, setShowData] = useState([] as any[]);

  const [searchCode, setSearchCode] = useState('');
  const [searchName, setSearchName] = useState('');
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const getAuditors = async () => {
    const docs = await firestore
      .collection('auditors')
      .where('companyId', '==', userCompany)
      .orderBy('createdAt', 'desc')
      .get();
    setAuditors(docs.docs.map((d) => ({...d.data(), uid: d.id})));
    setShowData(docs.docs.map((d) => ({...d.data(), uid: d.id})));
  };

  const search = () => {
    let data: AuditorModel[] = auditors;
    if (!_.isEmpty(searchCode)) {
      data = data.filter((a) =>
        a.code.toLowerCase().startsWith(searchCode.toLowerCase()),
      );
    }
    if (!_.isEmpty(searchName)) {
      data = data.filter((a) =>
        a.name.toLowerCase().includes(searchName.toLowerCase()),
      );
    }
    setShowData(data);
  };

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.auditorDetail, {id: d.uid}));
  };

  useEffect(() => {
    if (!_.isEmpty(userCompany)) getAuditors();
  }, [userCompany]);

  return (
    <>
      <DataTable
        tableName="Аудиторууд"
        columns={tcAuditorList}
        data={showData}
        loading={_.isEmpty(auditors)}
        onClickRow={onClickRow}
        reloadData={getAuditors}
        onSearch={search}
        headerWidgers={[
          <Button
            key={'add1'}
            className="hidden sm:block text-sm"
            shape="round"
            onClick={toggleModal}
            icon={<PlusOutlined />}
          >
            Аудитор нэмэх
          </Button>,
          <Button
            key={'add2'}
            className="block sm:hidden text-sm"
            shape="circle"
            onClick={toggleModal}
            icon={<PlusOutlined />}
          />,
        ]}
        filter={[
          <Input
            key="code"
            size="small"
            className="w-auto"
            placeholder="Код"
            value={searchCode}
            onChange={(e) => {
              setSearchCode(e.target.value);
            }}
          />,
          <Input
            key="name"
            size="small"
            className="w-auto"
            placeholder="Нэр"
            value={searchName}
            onChange={(e) => {
              setSearchName(e.target.value);
            }}
          />,
        ]}
      />
      <AuditAuditorAdd
        open={open}
        toggle={toggleModal}
        onSuccess={getAuditors}
      />
    </>
  );
};

export default AuditAuditors;
