import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../../context/app.contexts';
import {AuditCommentStatus, AuditModel, AuditStatus} from '../../../types';
import {ColumnsType} from 'antd/es/table';
import {firestore} from '../../../firebase';
import DataTable from '../../../components/table/Index';
import {auditCommentTag} from '../../../helpers/theme_helper';
import {Button} from 'antd';
import AuditAcceptReject from './BoardAcceptReject';
import {finishAudit} from '../../../helpers/firebase_functions';
import * as _ from 'lodash';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

type Props = {
  audit?: AuditModel;
  comments: any[];
  boardMembers: any[];
};

const AuditBoardMembers: React.FC<Props> = ({
  audit,
  comments,
  boardMembers,
}) => {
  const {user} = useContext(UserContext);
  const [showData, setShowData] = useState<any[]>([]);

  const [open, setOpen] = useState(false);
  const [isAccept, setIsAccept] = useState<boolean | undefined>();
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<any> = [
    {
      key: 'no',
      title: '№',
      dataIndex: 'no',
    },
    {
      key: 'auditorName',
      title: 'Нэр',
      dataIndex: 'auditorName',
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (_, record) => auditCommentTag(record.status),
    },
    {
      key: 'comment',
      title: 'Санал',
      dataIndex: 'comment',
      render: (_, record) => (
        <div>
          {(record.comment || []).map((c: any, i: number) => (
            <div key={`${record.uid}-${i}`}>{c}</div>
          ))}
        </div>
      ),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <>
          {audit?.boardMembers.includes(user.uid) &&
            audit.status === AuditStatus.sentToBoard &&
            record.status !== AuditCommentStatus.accepted &&
            record.uid === user.uid && (
              <div className="flex gap-3 mt-2">
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    setIsAccept(true);
                    toggleModal();
                  }}
                  icon={<CheckOutlined />}
                >
                  Батлах
                </Button>
                <Button
                  shape="round"
                  onClick={() => {
                    setIsAccept(false);
                    toggleModal();
                  }}
                  icon={<CloseOutlined />}
                >
                  Буцаах
                </Button>
              </div>
            )}
        </>
      ),
    },
  ];

  const onSubmit = async (comment: string) => {
    const currentComments =
      comments.find((c) => c.uid === user.uid)?.comment || [];
    await firestore
      .doc(`audits/${audit?.uid || '0'}/boardComments/${user.uid}`)
      .set({
        status: isAccept
          ? AuditCommentStatus.accepted
          : AuditCommentStatus.rejected,
        comment: [...currentComments, comment],
        updatedAt: new Date(),
      });
    if (!isAccept) {
      await firestore.doc(`audits/${audit?.uid || '0'}`).set(
        {
          status: AuditStatus.rejected,
          rejectedBy: user.uid,
        },
        {merge: true},
      );
    }
    return {status: true};
  };

  const saveAuditStatus = async () => {
    if (
      (comments as any[]).filter(
        (c) => c.status === AuditCommentStatus.accepted,
      ).length === audit?.boardMembers.length &&
      audit.status !== AuditStatus.finished
    ) {
      await finishAudit(audit.uid, audit.applicationRequestUid);
    }
  };

  useEffect(() => {
    saveAuditStatus();
  }, [comments]);

  useEffect(() => {
    setShowData(
      boardMembers.map((a, i) => ({
        uid: a.uid,
        no: i + 1,
        auditorName: a.name,
        status: (comments as any[]).find((c: any) => c.uid === a.uid)?.status,
        comment: (comments as any[]).find((c: any) => c.uid === a.uid)?.comment,
      })),
    );
  }, [boardMembers, comments]);

  return (
    <>
      <DataTable
        tableName="Баталгаажуулалтын зөвлөлийн гишүүд"
        columns={columns}
        data={showData}
      />
      <AuditAcceptReject
        open={open}
        toggle={toggleModal}
        onSubmit={onSubmit}
        isAccept={isAccept}
      />
    </>
  );
};

export default AuditBoardMembers;
