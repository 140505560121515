import {Col, Row} from 'antd';
import {useDoc} from '../../../helpers/firestore';
import {cityData} from '../../../constants/city_data';
import {emptyText} from '../../../helpers/theme_helper';
import * as _ from 'lodash';

type Props = {
  companyUid?: string;
  farmerUid?: string;
  type: string;
};
const tableClass =
  'border-t-0 border-l-0 border-r-0 border-solid border-surface';

const AuditReportCompany: React.FC<Props> = ({companyUid, farmerUid, type}) => {
  const [company] = useDoc(`companies/${companyUid || '0'}`);
  const [farmer] = useDoc(`users/${farmerUid || '0'}`);

  return (
    <>
      {type === 'finy' ? (
        <div className="border my-4 border-solid border-surface p-5">
          <div className="mb-4 text-info">1.1 Ерөнхий мэдээлэл</div>
          <Row gutter={[16, 12]}>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Овог, нэр</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              {farmer.lastname} {farmer.firstname}
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Үйл ажиллагааны чиглэл</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              {farmer.userType === 'citizen' ? 'Иргэн' : 'Компани'}
            </Col>
            {farmer.userType === 'company' && (
              <>
                <Col xs={24} sm={12} md={6} className={tableClass}>
                  <div className="font-medium">Компаний нэр</div>
                </Col>
                <Col xs={24} sm={12} md={18} className={tableClass}>
                  {farmer.companyName}
                </Col>
              </>
            )}
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">НТ дугаар</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              {farmer.ntDugaar || emptyText}
            </Col>
          </Row>
          <div className="mb-4 mt-10 text-info">1.2 Хаяг, холбоо барих</div>
          <Row gutter={[16, 12]}>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Утас</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>{farmer.phone || ''}</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Хаяг байршил</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              {[farmer.city, farmer.district]
                .filter((u) => !_.isEmpty(u))
                .join(', ')}
            </Col>
          </Row>
        </div>
      ) : (
        <div className="border my-4 border-solid border-surface p-5">
          <div className="mb-4 text-info">1.1 Нэр, үйл ажиллагаа</div>
          <Row gutter={[16, 12]}>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Нэр</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>{company.companyName || emptyText}</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Үйл ажиллагааны чиглэл</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>{company.activity || emptyText}</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Бусад үйл ажиллагаа</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>{emptyText}</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Салбар нэгж</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>{emptyText}</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Бусад мэдээлэл</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>{emptyText}</div>
            </Col>
          </Row>
          <div className="mb-4 mt-10 text-info">1.2 Хаяг, холбоо барих</div>
          <Row gutter={[16, 12]}>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Гүйцэтгэх захирал</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div>
                {(company.repLastname || '').substring(0, 1)}. {company.repName}
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Утас</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div>{company.phone || emptyText}</div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Хаяг байршил</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>
                {cityData.find((c) => c.code === (company.city || ''))?.name ||
                  ''}
                , {company.district || ''}, {company.address || ''}
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} className={tableClass}>
              <div className="font-medium">Имэйл хаяг, цахим хуудас</div>
            </Col>
            <Col xs={24} sm={12} md={18} className={tableClass}>
              <div>
                {company.email || ''} {company.webAddress || ''}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AuditReportCompany;
