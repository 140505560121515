import {Button, Input} from 'antd';
import {emptyText} from '../../helpers/theme_helper';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {AuthRoles} from '../../types';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

type Props = {
  farmerUid: string;
  ntNumber: string;
};

const NTUpdate: React.FC<Props> = ({farmerUid, ntNumber}) => {
  const [isEdit, setIsEdit] = useState(false);
  const {userRole} = useContext(UserContext);
  const [ntValue, setNtValue] = useState('');
  const [loading, setLoading] = useState(false);

  const saveValue = async () => {
    if (userRole !== AuthRoles.AUDIT_ADMIN) return;
    setLoading(true);
    await firestore.doc(`users/${farmerUid}`).set(
      {
        ntDugaar: ntValue,
      },
      {merge: true},
    );
    setLoading(false);
    setIsEdit(false);
  };

  useEffect(() => {
    setNtValue(ntNumber);
  }, [ntNumber]);

  return (
    <div className="flex gap-4 mt-3 items-center">
      {isEdit ? (
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="НТ дугаар"
          value={ntValue}
          onChange={(e) => {
            setNtValue(e.target.value);
          }}
        />
      ) : (
        <div>{ntNumber || emptyText}</div>
      )}
      {userRole === AuthRoles.AUDIT_ADMIN && !isEdit && (
        <Button
          icon={<i className="bx bx-edit-alt mr-2"></i>}
          size="small"
          type="primary"
          shape="round"
          className="bg-warning"
          onClick={() => {
            setIsEdit(true);
          }}
        >
          Засах
        </Button>
      )}
      {userRole === AuthRoles.AUDIT_ADMIN && isEdit && (
        <Button
          icon={<CheckOutlined className="text-xs" />}
          size="small"
          type="primary"
          shape="round"
          disabled={_.isEmpty(ntValue)}
          onClick={saveValue}
          loading={loading}
        >
          Хадгалах
        </Button>
      )}
      {userRole === AuthRoles.AUDIT_ADMIN && isEdit && (
        <Button
          icon={<CloseOutlined className="text-xs" />}
          size="small"
          shape="round"
          disabled={loading}
          onClick={() => {
            setIsEdit(false);
          }}
        >
          Цуцлах
        </Button>
      )}
    </div>
  );
};

export default NTUpdate;
