import {ArrowLeftOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  message,
} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {isValidUrl} from '../../helpers/utils';
import * as _ from 'lodash';
import {useNavigate, useParams} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import {authRoleData, dateFormat, genderData} from '../../constants/app_data';
import {updateData, useDoc} from '../../helpers/firestore';
import {AuditorModel, AuthRoles} from '../../types';
import {uploadImageBlob} from '../../helpers/firebase_storage';
import {updateRole} from '../../helpers/firebase_functions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {auditorRules} from '../../helpers/validations';
import {auditorRoles} from '../../helpers/role_helper';
import {UserContext} from '../../context/app.contexts';

dayjs.extend(customParseFormat);

const AuditorDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addressForm] = Form.useForm();
  const [systemForm] = Form.useForm();
  const {userRole} = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [auditor, auditorLoading] = useDoc(`auditors/${id}`);

  const [loading, setLoading] = useState(false);
  const [loadingAddress, setAddressLoading] = useState(false);
  const [loadingSystem, setSystemLoading] = useState(false);
  const [image, setImage] = useState<string | Blob>();

  const goBack = () => navigate(Routes.auditors);

  const saveMainInfo = async (values: any) => {
    setLoading(true);
    let imageUrl = image?.toString();
    if (image instanceof Blob) imageUrl = await handleUpload(image as Blob);
    await updateData(`auditors/${id}`, {
      ...values,
      dateOfBirth: dayjs(values.dateOfBirth).format(dateFormat),
      imageUrl: imageUrl || '',
    });
    setLoading(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  const saveAddressInfo = async (values: any) => {
    setAddressLoading(true);
    await updateData(`auditors/${id}`, values);
    setAddressLoading(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  const saveSystemInfo = async (values: any) => {
    setSystemLoading(true);
    if (auditor.role !== values.role) {
      const verify = window.confirm('Эрх солихдоо итгэлтэй байна уу?');
      if (verify) {
        const result = await updateRole(id!, values.role);
        if (!result.status) {
          setSystemLoading(false);
          messageApi.open({
            type: 'error',
            content: result.msg,
          });
          return;
        } else {
          await updateData(`auditors/${id}`, {
            role: values.role,
          });
        }
      }
    }
    await updateData(`auditors/${id}`, {
      code: values.code,
      phone: values.phone,
    });
    setSystemLoading(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  const handleUpload = async (file: Blob) => {
    try {
      const result = await uploadImageBlob(file, `images/auditors/${id}`);
      return result as string;
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: e ?? 'Зураг upload хийхэд алдаа гарлаа!',
      });
      return '';
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...auditor,
      dateOfBirth: dayjs((auditor as AuditorModel)?.dateOfBirth, dateFormat),
    });
    addressForm.setFieldsValue(auditor);
    systemForm.setFieldsValue(auditor);
    setImage((auditor as AuditorModel).imageUrl);
  }, [auditor]);

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Аудиторын мэдээлэл
        </Button>
      </div>
      <hr />
      <Spin spinning={auditorLoading}>
        <Row gutter={[16, 16]} className="my-8">
          <Col xs={24} lg={14} xl={12}>
            <div className="card-container p-6 mb-8">
              <div className="text-lg font-medium mb-5">Үндсэн мэдээлэл</div>
              <Form form={form} onFinish={saveMainInfo} scrollToFirstError>
                <div>Зураг</div>
                <Upload
                  className="mb-4"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    setImage(file);
                    return false;
                  }}
                >
                  {_.isEmpty(image) ? (
                    <div className="w-full">Зураг</div>
                  ) : (
                    <img
                      alt="img"
                      className="w-full h-full object-cover rounded-sm"
                      src={
                        !isValidUrl(image?.toString() || '')
                          ? URL.createObjectURL(image as Blob)
                          : image?.toString()
                      }
                    />
                  )}
                </Upload>
                <div>Регистрийн дугаар</div>
                <Form.Item
                  rules={auditorRules.registrationNumber}
                  name="registrationNumber"
                >
                  <Input placeholder="ФБ99010101" />
                </Form.Item>
                <div>Ургын овог</div>
                <Form.Item rules={auditorRules.urgiinOvog} name="urgiinOvog">
                  <Input placeholder="Боржгон" />
                </Form.Item>
                <div>Эцэг/Эхийн нэр</div>
                <Form.Item rules={auditorRules.lastname} name="lastname">
                  <Input placeholder="Эцэг/Эхийн нэр" />
                </Form.Item>
                <div>Нэр</div>
                <Form.Item rules={auditorRules.name} name="name">
                  <Input placeholder="Нэр" />
                </Form.Item>
                <div>Хүйс</div>
                <Form.Item rules={auditorRules.gender} name="gender">
                  <Select
                    placeholder="Эрэгтэй"
                    options={genderData.map((c: any) => ({
                      value: c.value,
                      label: c.label,
                    }))}
                  />
                </Form.Item>
                <div>Төрсөн огноо</div>
                <Form.Item rules={auditorRules.dateOfBirth} name="dateOfBirth">
                  <DatePicker
                    placeholder="Төрсөн огноо"
                    className="w-full"
                    format={dateFormat}
                  />
                </Form.Item>
                <div className="text-center">
                  <Button
                    type="primary"
                    shape="round"
                    className=""
                    disabled={
                      loading || !auditorRoles.addEditAuditor.includes(userRole)
                    }
                    loading={loading}
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Хадгалах
                  </Button>
                </div>
              </Form>
            </div>
            <div className="card-container p-6">
              <div className="text-lg font-medium mb-5">ГЭРИЙН ХАЯГ</div>
              <Form
                form={addressForm}
                onFinish={saveAddressInfo}
                scrollToFirstError
              >
                <div>Хот, Аймаг</div>
                <Form.Item rules={auditorRules.city} name="city">
                  <Input placeholder="Хот, Аймаг" />
                </Form.Item>
                <div>Дүүрэг, Сум</div>
                <Form.Item rules={auditorRules.district} name="district">
                  <Input placeholder="Дүүрэг, Сум" />
                </Form.Item>
                <div>Хороо, Баг</div>
                <Form.Item rules={auditorRules.street} name="street">
                  <Input placeholder="Хороо, Баг" />
                </Form.Item>
                <div>Хаяг</div>
                <Form.Item name="address">
                  <Input placeholder="Хаяг" />
                </Form.Item>
                <div className="text-center">
                  <Button
                    type="primary"
                    shape="round"
                    className=""
                    disabled={
                      loadingAddress ||
                      !auditorRoles.addEditAuditor.includes(userRole)
                    }
                    loading={loadingAddress}
                    onClick={() => {
                      addressForm.submit();
                    }}
                  >
                    Хадгалах
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
          <Col xs={24} lg={14} xl={12}>
            <div className="card-container p-6 mb-8">
              <div className="text-lg font-medium mb-5">Системийн бүртгэл</div>
              <Form
                form={systemForm}
                onFinish={saveSystemInfo}
                scrollToFirstError
              >
                <div>И-Мэйл</div>
                <Form.Item name="email">
                  <Input disabled />
                </Form.Item>
                <div>Код</div>
                <Form.Item
                  rules={[
                    {required: true, message: 'Аудиторын код оруулна уу'},
                  ]}
                  name="code"
                >
                  <Input />
                </Form.Item>
                <div>Эрх</div>
                <Form.Item name="role">
                  <Select
                    options={[
                      ...authRoleData,
                      {
                        value: AuthRoles.AUDIT_ADMIN,
                        label: 'Админ',
                      },
                    ].map((a: any) => ({
                      label: a.label,
                      value: a.value,
                    }))}
                  />
                </Form.Item>
                <div>Утас</div>
                <Form.Item name="phone">
                  <Input placeholder="99876543" />
                </Form.Item>
                <div className="text-center">
                  <Button
                    type="primary"
                    shape="round"
                    className=""
                    disabled={
                      loadingSystem ||
                      !auditorRoles.addEditAuditor.includes(userRole)
                    }
                    loading={loadingSystem}
                    onClick={() => {
                      systemForm.submit();
                    }}
                  >
                    Хадгалах
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default AuditorDetail;
