import {PlusOutlined, EditOutlined, MenuOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {useNavigate} from 'react-router-dom';
import DataTable from '../../components/table/Index';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {Routes} from '../../routes/route_helper';
import {ApplicationModel} from '../../types';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import ApplicationEdit from './ApplicationEdit';

const ApplicationList = () => {
  const navigate = useNavigate();
  const {userCompany} = useContext(UserContext);
  const [applicationLoading, setLoading] = useState(true);
  const [applications, setApplications] = useState<ApplicationModel[]>([]);
  const [selected, setSelected] = useState<ApplicationModel | undefined>();
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const columns: ColumnsType<ApplicationModel> = [
    {
      key: 'name',
      title: 'Нэр',
      dataIndex: 'name',
    },
    {
      key: 'desc',
      title: 'Тайлбар',
      dataIndex: 'desc',
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн огноо',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <div className="flex gap-2">
          <Button
            icon={<i className="bx bx-edit-alt"></i>}
            size="small"
            type="primary"
            className="bg-warning"
            onClick={() => {
              onClickEdit(record);
            }}
          />
          <Button
            icon={<i className="bx bx-detail"></i>}
            size="small"
            type="primary"
            className="bg-lightBlue"
            onClick={() => {
              onClickDetail(record.uid);
            }}
          />
        </div>
      ),
    },
  ];

  const getData = async () => {
    setLoading(true);
    const result = await firestore
      .collection('applications')
      .where('companyId', '==', userCompany)
      .get();
    setApplications(
      result.docs.map((r) => ({...r.data(), uid: r.id} as ApplicationModel)),
    );
    setLoading(false);
  };

  useEffect(() => {
    if (!_.isEmpty(userCompany)) getData();
  }, [userCompany]);

  const onClickDetail = (uid: string) => {
    navigate(Routes.compilePath(Routes.standartDetail, {id: uid}));
  };

  const onClickEdit = (data: ApplicationModel) => {
    setSelected(data);
    setOpen(true);
  };

  const addApplication = () => {
    setSelected(undefined);
    setOpen(true);
  };

  return (
    <div>
      <DataTable
        tableName="Баталгаажуулалтын төрлүүд"
        columns={columns}
        data={applications}
        loading={applicationLoading}
        reloadData={getData}
        headerWidgers={[
          <Button
            key={'add1'}
            className="hidden sm:block text-sm"
            shape="round"
            onClick={addApplication}
            icon={<PlusOutlined />}
          >
            Төрөл нэмэх
          </Button>,
          <Button
            key={'add2'}
            className="block sm:hidden text-sm"
            shape="circle"
            onClick={addApplication}
            icon={<PlusOutlined />}
          />,
        ]}
      />
      <ApplicationEdit
        open={open}
        toggle={toggleModal}
        selected={selected}
        onSuccess={getData}
      />
    </div>
  );
};

export default ApplicationList;
