import React, {useEffect, useState} from 'react';
import {Button, Modal, Alert, message, Select} from 'antd';
import * as _ from 'lodash';
import LogoImage from '../../assets/images/logo.png';
import {ContractType, RequestStatus} from '../../types';
import {CheckOutlined} from '@ant-design/icons';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  onSubmit: (value: string, request: any) => any;
  selected: any;
};

const UpdateContractType: React.FC<Props> = ({
  open = false,
  toggle,
  onSubmit,
  selected,
}) => {
  const [type, setType] = useState<string>('');
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setType('');
    setError('');
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (_.isEmpty(type) || loadingSave) return false;
    return true;
  };

  const onSave = async () => {
    if (!validate() || selected.status !== RequestStatus.sent) return;
    setLoadingSave(true);
    const result = await onSubmit(type, selected);
    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.msg || 'Алдаа гарлаа',
      });
    } else {
      onCancel();
      messageApi.open({
        type: 'success',
        content: 'Амжилттай хадгаллаа.',
      });
    }
  };

  useEffect(() => {
    setType(selected.contractType || '');
  }, [selected]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate() || selected.status !== RequestStatus.sent}
            shape="round"
            type="primary"
            onClick={onSave}
            icon={<CheckOutlined />}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Гэрээний төрөл өөрчлөх
            </div>
            <Select
              allowClear
              className="w-full my-4"
              value={type}
              onChange={setType}
              options={[
                {
                  label: `Амлалт, үүргийн гэрээ`,
                  value: ContractType.amlaltUurgiinGeree,
                },
                {
                  label: `Баталгаажуулалтын гэрээ`,
                  value: ContractType.batalgaajuulaltiinGeree,
                },
              ]}
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateContractType;
