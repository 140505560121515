import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {CompanyRequestModel, RequestStatus} from '../../types';
import {ColumnsType} from 'antd/es/table';
import {requestTag} from '../../helpers/theme_helper';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import DataTable from '../../components/table/Index';
import {Routes} from '../../routes/route_helper';
import {companyRoles} from '../../helpers/role_helper';
import {firestore} from '../../firebase';

const CompanyRequests = () => {
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState<CompanyRequestModel[]>([]);

  const columns: ColumnsType<CompanyRequestModel> = [
    {
      key: 'companyName',
      title: 'Байгууллага',
      dataIndex: 'companyName',
    },
    {
      key: 'registrationNumber',
      title: 'УБД',
      dataIndex: 'registrationNumber',
    },
    {
      key: 'phone',
      title: 'Утас',
      dataIndex: 'phone',
    },
    {
      key: 'targetCertificateName',
      title: 'Баталгаажуулалт',
      dataIndex: 'targetCertificateName',
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => requestTag(status),
    },
    {
      key: 'createdAt',
      title: 'Үүсгэсэн',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
  ];

  const onClickRow = (d: any) => {
    navigate(Routes.compilePath(Routes.companyRequestDetail, {id: d.uid}));
  };

  const getCompanyRequestList = async () => {
    setLoading(true);
    const data = await firestore
      .collection('companyRequests')
      .where('status', 'in', [RequestStatus.sent, RequestStatus.rejected])
      .orderBy('createdAt', 'desc')
      .get();
    setRequests(
      data.docs.map((d) => ({...d.data(), uid: d.id} as CompanyRequestModel)),
    );
    setLoading(false);
  };

  useEffect(() => {
    if (companyRoles.viewRequest.includes(userRole)) {
      getCompanyRequestList();
    }
  }, [userRole]);

  return (
    <DataTable
      tableName="Компаний хүсэлтүүд"
      columns={columns}
      data={requests}
      loading={loading}
      onClickRow={onClickRow}
      reloadData={getCompanyRequestList}
    />
  );
};

export default CompanyRequests;
