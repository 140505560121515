import Login from '../pages/auth/Login';
import Dashboard from '../pages/dashboard/Index';
import Otp from '../pages/otp/Index';
import {Routes} from './route_helper';
import AuditAuditors from '../pages/auditors/Auditors';
import NotFound from '../pages/NotFound';
import StandartList from '../pages/certificates/ApplicationList';
import StandartDetail from '../pages/certificates/ApplicationDetail';
import AuditorDetail from '../pages/auditors/AuditorDetail';
import CompanyRequestSend from '../pages/company/SendRequest';
import CompanyRequests from '../pages/company/Requests';
import CompanyRequestDetail from '../pages/company/RequestDetail';
import ApplicationRequests from '../pages/applicationRequest/ApplicationRequests';
import ApplicationInfo from '../pages/applicationRequest/ApplicationInfo';
import ApplicationCreate from '../pages/applicationRequest/ApplicationCreate';
import ContractList from '../pages/contract/List';
import ContractDetail from '../pages/contract/Detail';
import ApplicationAudits from '../pages/audit/ApplicationAudits';
import AuditDetail from '../pages/audit/AuditDetail';
import AuditReport from '../pages/audit/report/AuditReport';
import CompanyList from '../pages/company/List';
import FinyRequests from '../pages/finyRequest/Requests';
import FinyRequestDetail from '../pages/finyRequest/RequestDetail';
import FinyContractorRequests from '../pages/finyRequest/ContractorRequests';
import QRRead from '../pages/pdf/QRRead';
import CompanyDetail from '../pages/company/Detail';
import CertificateList from '../pages/certificates/List';
import AuditSent from '../pages/audit/AuditSent';
import AuditBoard from '../pages/audit/AuditBoard';
import CertificateView from '../pages/pdf/CertificateView';
import CertContracts from '../pages/contract/CertContracts';

const authProtectedRoutes = [
  {path: Routes.dashboard, component: Dashboard},
  {path: Routes.otp, component: Otp},
  //auditors
  {path: Routes.auditors, component: AuditAuditors},
  {path: Routes.auditorDetail, component: AuditorDetail},
  //certificate
  {path: Routes.standarts, component: StandartList},
  {path: Routes.standartDetail, component: StandartDetail},
  //finy requests
  {path: Routes.finyRequests, component: FinyRequests},
  {path: Routes.finyContractorRequests, component: FinyContractorRequests},
  {path: Routes.finyRequestDetail, component: FinyRequestDetail},
  //contract
  {path: Routes.contracts, component: ContractList},
  {path: Routes.certContracts, component: CertContracts},
  {path: Routes.contractDetail, component: ContractDetail},
  //company
  {path: Routes.companyRequests, component: CompanyRequests},
  {path: Routes.companyRequestDetail, component: CompanyRequestDetail},
  {path: Routes.companyList, component: CompanyList},
  {path: Routes.companyDetail, component: CompanyDetail},
  //application
  {path: Routes.applicationRequests, component: ApplicationRequests},
  {path: Routes.applicationRequestCreate, component: ApplicationCreate},
  {path: Routes.applicationRequestDetail, component: ApplicationCreate},
  {path: Routes.applicationRequestInfo, component: ApplicationInfo},
  {path: Routes.certificateList, component: CertificateList},
  //audit
  {path: Routes.applicationAudits, component: ApplicationAudits},
  {path: Routes.auditDetail, component: AuditDetail},
  {path: Routes.auditReport, component: AuditReport},
  {path: Routes.auditSent, component: AuditSent},
  {path: Routes.auditBoard, component: AuditBoard},
];

const initialRoute = [{path: '*', component: NotFound}];
const publicRoutes = [
  {path: Routes.login, component: Login},
  {path: Routes.companySendRequest, component: CompanyRequestSend},
  {path: Routes.qr, component: QRRead},
  {path: Routes.certificateView, component: CertificateView},
];

const auditAdminRoutes = [
  //dashboard
  Routes.dashboard,
  //application
  Routes.applicationRequests,
  Routes.applicationRequestInfo,
  Routes.applicationAudits,
  Routes.auditDetail,
  Routes.auditReport,
  Routes.auditSent,
  Routes.certificateList,
  //contract
  Routes.contracts,
  Routes.certContracts,
  Routes.contractDetail,
  //finy requests
  Routes.finyRequests,
  Routes.finyRequestDetail,
  //auditors
  Routes.auditors,
  Routes.auditorDetail,
  //company requests
  Routes.companyRequests,
  Routes.companyRequestDetail,
  Routes.companyList,
  Routes.companyDetail,
  //otp
  Routes.otp,
  //application info
  Routes.standarts,
  Routes.standartDetail,
];
const getAuditAdminRoutes = () =>
  authProtectedRoutes.filter((r) => auditAdminRoutes.includes(r.path));

const auditAuditorRoutes = [
  Routes.dashboard,
  Routes.applicationRequests,
  Routes.applicationRequestInfo,
  Routes.applicationAudits,
  Routes.auditReport,
  Routes.auditDetail,
  Routes.auditBoard,
  Routes.contracts,
  Routes.certContracts,
  Routes.contractDetail,
];
const getAuditAuditorRoutes = () =>
  authProtectedRoutes.filter((r) => auditAuditorRoutes.includes(r.path));

const auditContractorRoutes = [Routes.dashboard, Routes.finyContractorRequests];
const getAuditContractorRoutes = () =>
  authProtectedRoutes.filter((r) => auditContractorRoutes.includes(r.path));

const companyRoutes = [
  Routes.dashboard,
  Routes.applicationRequests,
  Routes.applicationRequestCreate,
  Routes.applicationRequestDetail,
  Routes.applicationRequestInfo,
  Routes.applicationAudits,
  Routes.auditReport,
  Routes.certificateList,
];
const getCompanyRoutes = () =>
  authProtectedRoutes.filter((r) => companyRoutes.includes(r.path));

export {
  publicRoutes,
  initialRoute,
  getAuditAdminRoutes,
  getAuditAuditorRoutes,
  getAuditContractorRoutes,
  getCompanyRoutes,
};
