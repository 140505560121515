import {AuthRoles, QuestionOptions} from '../types';

export const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const formFieldTypes = [
  {
    value: 'textarea',
    label: 'Текст',
  },
  {
    value: 'single_choice',
    label: '1 сонголт',
  },
  {
    value: 'multi_choice',
    label: 'Олон сонголт',
  },
  {
    value: 'plant_selection',
    label: 'Таримал сонгох',
  },
];

export const genderData = [
  {
    label: 'Эрэгтэй',
    value: 'male',
  },
  {
    label: 'Эмэгтэй',
    value: 'female',
  },
];

export const authRoleData = [
  {
    value: AuthRoles.AUDIT_AUDITOR,
    label: 'Аудитор',
  },
  {
    value: AuthRoles.AUDIT_CONTRACTOR,
    label: 'Баталгаажуулалтын ажилтан',
  },
];

export const dateFormat = 'YYYY/MM/DD';

export const legalStatuses = [
  'ХХК',
  'ХК',
  'ТББ',
  'ТӨХК',
  'Хоршоо',
  'Өрхийн тариалагч',
  'Нөхөрлөл',
  'Төрийн байгууллага',
  'ТӨҮГ',
];

export const appRequestAuditTypes = [
  {
    name: 'Анхдагч баталгаажуулалтын аудит',
    value: 'primary',
  },
  {
    name: 'Давтан баталгаажуулалтын аудит',
    value: 'reverification',
  },
];

export const auditorAuditTypes = [
  {
    name: 'Нэг дэх шатны аудит',
    value: 'first',
  },
  {
    name: 'Хоёр дох шатны аудит',
    value: 'secondary',
  },
  {
    name: 'Магадлах аудит',
    value: 'probability',
  },
  {
    name: 'Давтан баталгаажуулалтын аудит',
    value: 'reverification',
  },
];

export const defaultMismatch: QuestionOptions[] = [
  {
    id: 0,
    value: 'Сайжруулах боломж',
    score: 0,
  },
  {
    id: 1,
    value: 'Жижиг үл тохирол',
    score: 0,
  },
  {
    id: 2,
    value: 'Том үл тохирол',
    score: 0,
  },
];

export const defaultEvaluation: QuestionOptions[] = [
  {
    id: 0,
    value: 'Хангаж байна',
    score: 1,
  },
  {
    id: 1,
    value: 'Хангахгүй байна',
    score: 0,
  },
];
