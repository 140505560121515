import React, {useEffect, useState} from 'react';
import {Button, Col, Input, message, Row} from 'antd';
import {ApplicationDocument} from '../../types';
import {removeData, saveData, updateData} from '../../helpers/firestore';
import * as _ from 'lodash';

type Props = {
  docUid?: string | undefined;
  documents: ApplicationDocument[];
};

const ApplicationDocuments: React.FC<Props> = ({documents, docUid}) => {
  const addDocument = async () => {
    if (_.isEmpty(docUid)) return;
    await saveData(`applications/${docUid!}/documents`, undefined, {}, true);
  };

  return (
    <>
      <div className="mb-4 mt-4 font-medium text-info flex gap-2 justify-between items-center">
        Баримт бичгүүд ({documents.length})
        <Button key="submit" size="small" shape="round" onClick={addDocument}>
          Нэмэх
        </Button>
      </div>
      <Row gutter={16}>
        {(documents as ApplicationDocument[]).map((q) => (
          <Col key={q.uid} xs={24} md={12} lg={8}>
            <Question standartId={docUid} document={q} />
          </Col>
        ))}
      </Row>
    </>
  );
};

type QuestionProps = {
  standartId?: string | undefined;
  document: ApplicationDocument;
};

const Question: React.FC<QuestionProps> = ({standartId, document}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');

  const validate = () => {
    return !_.isEmpty(name);
  };

  const editQuestion = async () => {
    if (!validate()) return;
    if (_.isEmpty(standartId) || _.isEmpty(document.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Баримт бичгийн мэдээлэл хадгалахад алдаа гарлаа!',
      });
      return;
    }
    setLoading(true);
    await updateData(`applications/${standartId}/documents/${document.uid}`, {
      name,
      desc,
    } as ApplicationDocument);
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'success',
      content: 'Баримт бичгийн мэдээлэл хадгалагдлаа.',
    });
  };

  const removeQuestion = async () => {
    if (_.isEmpty(standartId) || _.isEmpty(document.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Баримт бичгийн мэдээлэл устгахад алдаа гарлаа!',
      });
      return;
    }

    setLoading(true);
    await removeData(`applications/${standartId}/documents/${document.uid}`);
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'warning',
      content: 'Баримт бичгийн мэдээлэл устгагдлаа',
    });
  };

  useEffect(() => {
    if (!_.isEmpty(document)) {
      setName(document.name ?? '');
      setDesc(document.desc ?? '');
    }
  }, [document]);

  return (
    <>
      {contextHolder}
      <div
        key={`cert-form-question-${document.uid}`}
        className="border border-surface rounded-sm p-4 border-solid bg-lightGray mb-3"
      >
        {expanded || _.isEmpty(document.name) ? (
          <>
            <div className="flex items-center justify-between mb-4 gap-3">
              {!_.isEmpty(document.name) && (
                <div
                  className="cursor-pointer text-info text-sm"
                  onClick={() => setExpanded(false)}
                >
                  Хураах
                </div>
              )}
            </div>
            <Input.TextArea
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Баримт бичиг"
              className="mb-3"
            />
            <Input.TextArea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Тайлбар"
              className="mb-3"
            />
            <div className="flex justify-center gap-3 mt-2">
              <Button
                key="delete"
                size="small"
                shape="round"
                disabled={_.isEmpty(document.uid) || loading}
                onClick={removeQuestion}
                danger
              >
                Устгах
              </Button>
              <Button
                key="save"
                size="small"
                shape="round"
                type="primary"
                loading={loading}
                disabled={!validate()}
                onClick={editQuestion}
              >
                Хадгалах
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between items-center gap-3">
              <div>{name}</div>
              <div
                className="cursor-pointer text-info text-sm"
                onClick={() => setExpanded(true)}
              >
                Засах
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ApplicationDocuments;
