import DataTable from '../../components/table/Index';
import {useCollection} from '../../helpers/firestore';
import {OtpEmailModel} from '../../types';
import {useEffect, useState} from 'react';
import {tcOtp} from '../../helpers/table_columns';
import {Input} from 'antd';

const Otp = () => {
  const [email, setEmail] = useState('');
  const [showData, setShowData] = useState<OtpEmailModel[]>([]);
  const [otp, otpLoading] = useCollection(
    'appEmailOTP',
    'createdAt',
    undefined,
    30,
  );

  useEffect(() => {
    setShowData(otp);
  }, [otp]);

  const search = () => {
    setShowData(
      (otp as OtpEmailModel[]).filter((d) => d.email.includes(email)),
    );
  };

  return (
    <DataTable
      tableName="OTP код"
      columns={tcOtp}
      data={showData}
      loading={otpLoading}
      onSearch={search}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Имейл"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />,
      ]}
    />
  );
};

export default Otp;
