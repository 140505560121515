import {Col, Row} from 'antd';
import {ApplicationRequestModel, AuditModel} from '../../../types';
import {auditorAuditTypes} from '../../../constants/app_data';
import {useDoc} from '../../../helpers/firestore';
import {convertDateFromTimestamp} from '../../../helpers/utils';
import * as _ from 'lodash';
import {emptyText} from '../../../helpers/theme_helper';

type Props = {
  audit?: AuditModel;
  auditPlan: any;
  applicationRequest?: ApplicationRequestModel;
  auditMembers: any[];
  report?: any;
};
const tableClass =
  'border-t-0 border-l-0 border-r-0 border-solid border-surface';

const AuditReportInfo: React.FC<Props> = ({
  audit,
  auditPlan,
  applicationRequest,
  auditMembers,
  report,
}) => {
  const [application] = useDoc(
    `applications/${applicationRequest?.applicationUid}`,
  );

  return (
    <div className="border my-4 border-solid border-surface p-5">
      <div className="mb-4 text-info">3.1 Аудитын баг</div>
      <Row gutter={[16, 12]}>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Томилсон тушаал, шийдвэр</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{audit?.guide || ''}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Багийн ахлагч</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            {auditMembers.find((m) => m.uid === audit?.teamLead)?.name || ''}
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Багийн гишүүд</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            {auditMembers
              .filter((m) => m.uid !== audit?.teamLead)
              .map((m) => m.name)
              .join(', ')}
          </div>
        </Col>
      </Row>
      <div className="mb-4 mt-10 text-info">
        3.2 Аудитын талаарх ерөнхий мэдээлэл
      </div>
      <Row gutter={[16, 12]}>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудитын хамрах хүрээ</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{audit?.auditScope || ''}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудитын зорилго</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{audit?.objective || ''}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудитын шалгуур</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{application.shalguur || ''}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудитын төрөл</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            {auditorAuditTypes.find((t) => t.value === audit?.auditType || '')
              ?.name || ''}
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">
            Аудит явуулах үед баримталсан стандарт, норматив
          </div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{application.standartCode}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудит явуулсан хугацаа, огноо</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>
            {!_.isEmpty(auditPlan.auditStart) && !_.isEmpty(auditPlan.auditEnd)
              ? `${convertDateFromTimestamp(
                  auditPlan.auditStart,
                )} ~ ${convertDateFromTimestamp(auditPlan.auditEnd)}`
              : emptyText}
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудит явуулсан газар</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{report?.location || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудитын албан ёсны хэл</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>Монгол</div>
        </Col>
      </Row>
    </div>
  );
};

export default AuditReportInfo;
