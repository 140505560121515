import {Col, Row} from 'antd';
import {emptyText} from '../../../helpers/theme_helper';

type Props = {
  auditProcess?: any;
};
const tableClass =
  'border-t-0 border-l-0 border-r-0 border-solid border-surface';

const AuditReportProgress: React.FC<Props> = ({auditProcess}) => {
  return (
    <div className="border my-4 border-solid border-surface p-5">
      <div className="mb-4 text-info">4.1 Аудитын явц</div>
      <Row gutter={[16, 12]}>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Аудитын явцын талаарх товч мэдээлэл</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.brief || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">
            Сайжруулах саналын мөрөөр хийсэн ажлын биелэлт
          </div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.implementation || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">
            Аудитаар шалгагдсан баримт бичгүүдийн нэрс
          </div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.documents || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">
            Дээд шатны удирдлагатай хийсэн ярилцлага
          </div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.seniorInterview || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">
            Дунд шатны удирдлагатай хийсэн ярилцлага
          </div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.middleInterview || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Ажилтнуудтай хийсэн ярилцлага</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.interview || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">
            Шалгасан санал, гомдол, аюултай тохиолдол
          </div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.suggestion || emptyText}</div>
        </Col>
        <Col xs={24} sm={12} md={6} className={tableClass}>
          <div className="font-medium">Бусад мэдээлэл</div>
        </Col>
        <Col xs={24} sm={12} md={18} className={tableClass}>
          <div>{auditProcess.other || emptyText}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AuditReportProgress;
