import React, {useState} from 'react';
import {Button, Input, Modal, Alert, message} from 'antd';
import * as _ from 'lodash';
import LogoImage from '../assets/images/logo.png';

type Props = {
  open?: boolean;
  toggle: (value: boolean) => void;
  onSubmit: (value: string) => any;
};

const RejectReason: React.FC<Props> = ({open = false, toggle, onSubmit}) => {
  const [reason, setReason] = useState<string>('');
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setReason('');
    setError('');
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (_.isEmpty(reason) || loadingSave) return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    const result = await onSubmit(reason);
    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.msg,
      });
    } else {
      onCancel();
      messageApi.open({
        type: 'warning',
        content: 'Хүсэлт буцаагдлаа.',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            shape="round"
            type="primary"
            onClick={onSave}
          >
            Буцаах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">
              Хүсэлт буцаах шалтгаан
            </div>
            <Input.TextArea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Шалтгаан"
              className="mb-3"
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RejectReason;
