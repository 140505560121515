export const companyRequestRules = {
  companyName: [{required: true, message: 'Байгууллагын нэр оруулна уу'}],
  registrationNumber: [
    {required: true, message: 'Улсын бүртгэлийн дугаар оруулна уу'},
  ],
  targetCertificate: [
    {required: true, message: 'Баталгаажуулалтын төрөл оруулна уу'},
  ],
  city: [{required: true, message: 'Аймаг, хот оруулна уу'}],
  phone: [{required: true, message: 'Утасны дугаар оруулна уу'}],
  activity: [{required: true, message: 'Үйл ажиллагааны чиглэл оруулна уу'}],
  legalStatus: [{required: true, message: 'Хууль эрх зүйн статус оруулна уу'}],
  district: [{required: true, message: 'Сум, дүүрэг оруулна уу'}],
  email: [{required: true, message: 'Имэйл оруулна уу'}],
  repLastname: [{required: true, message: 'Эцэг/эхийн нэр оруулна уу'}],
  repName: [{required: true, message: 'Нэр оруулна уу'}],
  repEmail: [{required: true, message: 'Имэйл оруулна уу'}],
  repPhone: [{required: true, message: 'Утас оруулна уу'}],
};

export const applicationRequestRules = {
  certificateUid: [
    {required: true, message: 'Баталгаажуулалтын төрөл оруулна уу'},
  ],
  auditType: [{required: true, message: 'Аудитын төрөл оруулна уу'}],
  ceo: [{required: true, message: 'Гүйцэтгэх захирал оруулна уу'}],
};

export const auditRules = {
  auditNumber: [{required: true, message: 'Аудитын дугаар оруулна уу'}],
  auditType: [{required: true, message: 'Аудитын төрөл оруулна уу'}],
  auditScope: [{required: true, message: 'Хамрах хүрээ оруулна уу'}],
  objective: [{required: true, message: 'Аудитын зорилго оруулна уу'}],
};

export const auditorRules = {
  registrationNumber: [
    {required: true, message: 'Регистрийн дугаар оруулна уу'},
  ],
  urgiinOvog: [{required: true, message: 'Ургын овог оруулна уу'}],
  lastname: [{required: true, message: 'Эцэг/Эхийн нэр оруулна уу'}],
  name: [{required: true, message: 'Нэр оруулна уу'}],
  gender: [{required: true, message: 'Хүйс оруулна уу'}],
  dateOfBirth: [{required: true, message: 'Төрсөн огноо оруулна уу'}],
  city: [{required: true, message: 'Хот, Аймаг оруулна уу'}],
  district: [{required: true, message: 'Дүүрэг, Сум оруулна уу'}],
  street: [{required: true, message: 'Хороо, Баг оруулна уу'}],
};

export const guidanceRules = {
  guidanceDate: [{required: true, message: 'Тушаалын огноо оруулна уу'}],
  number: [{required: true, message: 'Тушаалын дугаар оруулна уу'}],
  scope: [{required: true, message: 'Удирдамжийн хамрах хүрээ оруулна уу'}],
  guidanceStart: [
    {required: true, message: 'Удирдамжийн эхлэх хугацаа оруулна уу'},
  ],
  guidanceEnd: [
    {required: true, message: 'Удирдамжийн дуусах хугацаа оруулна уу'},
  ],
};
