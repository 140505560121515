import {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {ApplicationRequestModel, ApplicationRequestStatus} from '../../types';
import DataTable from '../../components/table/Index';
import {ColumnsType} from 'antd/es/table';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';
import {Button, Input} from 'antd';
import {Routes} from '../../routes/route_helper';
import {PlusOutlined} from '@ant-design/icons';
import {firestore} from '../../firebase';
import {appRequestAuditTypes} from '../../constants/app_data';
import {applicationRequestTag} from '../../helpers/theme_helper';
import {applicationRoles} from '../../helpers/role_helper';
import * as _ from 'lodash';

const ApplicationRequests = () => {
  const navigate = useNavigate();
  const {userCompany, userRole} = useContext(UserContext);
  const [requests, setRequests] = useState<ApplicationRequestModel[]>([]);
  const [showData, setShowData] = useState<ApplicationRequestModel[]>([]);
  const [searchNumber, setSearchNumber] = useState('');
  const [requestsLoading, setRequestsLoading] = useState(true);

  const columns: ColumnsType<ApplicationRequestModel> = [
    {
      key: 'requestNumber',
      title: 'Дугаар',
      dataIndex: 'requestNumber',
    },
    {
      key: 'applicationName',
      title: 'Баталгаажуулалт',
      dataIndex: 'applicationName',
      render: (_, record) => (
        <div
          className="text-info cursor-pointer"
          onClick={() => {
            onClickRow(record);
          }}
        >
          {record?.applicationName}
        </div>
      ),
    },
    {
      key: 'auditType',
      title: 'Аудитын төрөл',
      dataIndex: 'auditType',
      responsive: ['lg'],
      render: (auditType: string) =>
        appRequestAuditTypes.find((a) => a.value === auditType)?.name,
    },
    {
      key: 'companyName',
      title: 'Байгууллага',
      dataIndex: 'companyName',
      render: (_, record) => (
        <div className="flex flex-col gap-2">
          {record?.type === 'finy' ? record.farmerName : record.companyName}
        </div>
      ),
    },
    {
      key: 'audits',
      title: 'Аудитын явц',
      dataIndex: 'audits',
      render: (_, record) => (
        <div className="flex flex-col gap-2">
          {record?.status === ApplicationRequestStatus.accepted ? (
            <Link
              to={Routes.compilePath(Routes.applicationAudits, {
                id: record.uid,
              })}
            >
              Харах
            </Link>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: 'status',
      render: (status: string) => applicationRequestTag(status),
    },
    {
      key: 'sentAt',
      title: 'Огноо',
      dataIndex: 'sentAt',
      responsive: ['lg'],
      render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
    },
    {
      key: 'acceptedAt',
      title: 'Хүлээн авсан',
      dataIndex: 'acceptedAt',
      render: (acceptedAt: Timestamp) =>
        convertDateTimeFromTimestamp(acceptedAt),
    },
  ];

  const onClickRow = (d: ApplicationRequestModel) => {
    if (
      d.status === ApplicationRequestStatus.created &&
      applicationRoles.addEditRequest.includes(userRole)
    ) {
      navigate(
        Routes.compilePath(Routes.applicationRequestDetail, {id: d.uid}),
      );
    } else {
      navigate(Routes.compilePath(Routes.applicationRequestInfo, {id: d.uid}));
    }
  };

  const onClickAdd = async () => {
    navigate(Routes.applicationRequestCreate);
  };

  const getRequestsData = async () => {
    let path = '';
    let orderfield = '';
    if (applicationRoles.addEditRequest.includes(userRole)) {
      path = `companies/${userCompany}/requests`;
      orderfield = 'createdAt';
    } else {
      path = 'applicationRequests';
      orderfield = 'sentAt';
    }
    setRequestsLoading(true);
    const data = await firestore
      .collection(path)
      .orderBy(orderfield, 'desc')
      .get();
    setRequests(
      data.docs.map(
        (d) => ({...d.data(), uid: d.id} as ApplicationRequestModel),
      ),
    );
    setShowData(
      data.docs.map(
        (d) => ({...d.data(), uid: d.id} as ApplicationRequestModel),
      ),
    );
    setRequestsLoading(false);
  };

  const search = () => {
    setShowData(
      requests.filter((d) =>
        d.requestNumber
          .toString()
          .toLowerCase()
          .includes(searchNumber.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    if (!_.isEmpty(userRole) && !_.isEmpty(userCompany)) {
      getRequestsData();
    }
  }, [userRole, userCompany]);

  return (
    <DataTable
      tableName="Өргөдөл"
      columns={columns}
      data={showData}
      loading={requestsLoading}
      reloadData={getRequestsData}
      onSearch={search}
      filter={[
        <Input
          key="phone"
          size="small"
          className="w-auto"
          placeholder="Дугаар"
          value={searchNumber}
          onChange={(e) => {
            setSearchNumber(e.target.value);
          }}
        />,
      ]}
      headerWidgers={
        applicationRoles.addEditRequest.includes(userRole)
          ? [
              <Button
                key={'add1'}
                className="hidden sm:block text-sm"
                shape="round"
                onClick={onClickAdd}
                icon={<PlusOutlined />}
              >
                Хүсэлт үүсгэх
              </Button>,
              <Button
                key={'add2'}
                className="block sm:hidden text-sm"
                shape="circle"
                onClick={onClickAdd}
                icon={<PlusOutlined />}
              />,
            ]
          : []
      }
    />
  );
};

export default ApplicationRequests;
