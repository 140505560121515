import {Button, Modal, Spin} from 'antd';
import {
  BlobProvider,
  Document,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import {Document as DocumentReader, Page as PageReader} from 'react-pdf';
import * as _ from 'lodash';
import CertificateDocument from '../pdf/Certificate';

const CertificateViewer = ({open = false, toggle, selected}) => {
  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    fontWeight: 'normal',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    fontWeight: 'medium',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 'bold',
  });

  return (
    <Modal
      open={open}
      onCancel={toggle}
      className="pdf-modal"
      width={Math.min(900, window.innerWidth)}
      closeIcon={<></>}
      centered
      footer={[]}
    >
      {!_.isEmpty(selected) && (
        <BlobProvider
          document={
            <Document>
              <CertificateDocument certificate={selected} />
            </Document>
          }
        >
          {({blob, url, loading}) => {
            return loading ? (
              <div className="mt-20 text-center">
                <Spin />
              </div>
            ) : (
              <DocumentReader file={url} loading={<></>}>
                <PageReader
                  loading={<></>}
                  renderTextLayer={false}
                  pageNumber={1}
                  width={Math.min(900, window.innerWidth)}
                />
              </DocumentReader>
            );
          }}
        </BlobProvider>
      )}
      <div className="text-right">
        <PDFDownloadLink
          className="bg-primary py-3 px-5 text-white rounded-xl hover:text-white"
          key={12}
          document={
            <Document>
              <CertificateDocument certificate={selected} />
            </Document>
          }
          fileName={`SFCS_${selected?.certificateNumber}`}
        >
          {({blob, url, loading, error}) =>
            loading ? <Spin spinning={true} /> : 'Татах'
          }
        </PDFDownloadLink>
        <Button key={1} shape="round" onClick={toggle} className="m-3">
          Буцах
        </Button>
      </div>
    </Modal>
  );
};

export default CertificateViewer;
