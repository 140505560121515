import {memo, useContext, useState} from 'react';
import {saveData, updateData} from '../../../helpers/firestore';
import {Spin, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {RcFile, UploadProps} from 'antd/es/upload';
import {uploadImageBlob} from '../../../helpers/firebase_storage';
import * as _ from 'lodash';
import {UserContext} from '../../../context/app.contexts';
import {AuthRoles} from '../../../types';

type Props = {
  auditUid: string;
  attachments: any[];
};

const AuditAttachments: React.FC<Props> = ({attachments, auditUid}) => {
  const {userRole} = useContext(UserContext);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async (file: RcFile) => {
    setUploading(true);
    const ext = file.name.split('.').pop();
    const result = await uploadImageBlob(
      file,
      `files/audits/${auditUid}/attachments`,
      _.isEmpty(ext) ? '' : `.${ext}`,
    );
    if (!_.isEmpty(result)) {
      await saveData(`audits/${auditUid}/detail`, 'report', {
        attachments: [
          ...attachments,
          {
            url: result,
            name: file.name,
          },
        ],
      });
    }
    setUploading(false);
    return '';
  };

  const props: UploadProps = {
    onRemove: async (file) => {
      const item = attachments.find((s: any) => s.url === file.url);
      const index = attachments.indexOf(item);
      const newFileList = attachments.slice();
      newFileList.splice(index, 1);
      await updateData(`audits/${auditUid}/detail/report`, {
        attachments: newFileList,
      });
    },
    listType: 'picture-card',
    fileList: attachments,
  };

  return (
    <div>
      <Upload
        {...props}
        action={handleUpload}
        fileList={attachments}
        disabled={uploading || userRole !== AuthRoles.AUDIT_AUDITOR}
      >
        <Spin spinning={uploading}>
          <PlusOutlined />
        </Spin>
      </Upload>
    </div>
  );
};

export default memo(AuditAttachments);
