import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {UserContext} from '../../../context/app.contexts';
import {useCollection, useDoc} from '../../../helpers/firestore';
import {Routes} from '../../../routes/route_helper';
import {ArrowLeftOutlined, PrinterOutlined} from '@ant-design/icons';
import {Button, Col, Row, Spin, Tabs, message} from 'antd';
import * as _ from 'lodash';
import {auditorAuditTypes} from '../../../constants/app_data';
import {convertDateTimeFromTimestamp} from '../../../helpers/utils';
import AuditReportCompany from './AuditReportCompany';
import AuditReportAuditor from './AuditReportAuditor';
import AuditReportInfo from './AuditReportInfo';
import AuditReportProgress from './AuditReportProgress';
import AuditReportResult from './AuditReportResult';
import {auditRoles} from '../../../helpers/role_helper';
import {AuditForm, AuditStatus} from '../../../types';
import DataTable from '../../../components/table/Index';
import {auditStatusTag, emptyText} from '../../../helpers/theme_helper';
import {sendAudit, sendToBoard} from '../../../helpers/firebase_functions';
import AuditBoardMembers from './AuditBoardMembers';
import AuditReportPrint from './AuditPrint';
import {firestore} from '../../../firebase';
import AuditReportPlan from './AuditReportPlan';
import AuditAttachments from './AuditAttachments';
import {tcAuditEvaluationColumns} from '../../../helpers/table_columns';

const AuditReport = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const {userRole, user} = useContext(UserContext);
  const [audit, auditLoading] = useDoc(`audits/${id}`);
  const [auditPlan] = useDoc(`audits/${id}/detail/plan`);
  const [auditProcess] = useDoc(`audits/${id}/detail/process`);
  const [auditReport] = useDoc(`audits/${id}/detail/report`);
  const [companyData] = useDoc(`companies/${audit.companyUid || '0'}`);
  const [farmerData] = useDoc(`users/${audit.farmerUid || '0'}`);
  const [sending, setSending] = useState(false);
  const [sendingToBoard, setSendingBoard] = useState(false);
  const [auditMembers, setMembers] = useState<any[]>([]);
  const [auditBoardMembers, setBoardMembers] = useState<any[]>([]);
  const [applicationRequest, applicationLoading] = useDoc(
    `applicationRequests/${audit.applicationRequestUid}`,
  );
  const [comments] = useCollection(`audits/${audit?.uid || '0'}/boardComments`);
  const [application, applLoading] = useDoc(
    `applications/${applicationRequest.applicationUid || '0'}`,
  );
  const [formQuestions, formLoading] = useCollection(
    `applications/${applicationRequest.applicationUid || '0'}/form`,
    'createdAt',
    undefined,
    undefined,
    false,
  );
  const [formAnswers, formAnswerLoading] = useDoc(`audits/${id}/evaluations/0`);
  const [formData, setFormData] = useState<AuditForm[]>([]);

  const [openPrint, setOpenPrint] = useState(false);
  const toggleOpenPrint = () => setOpenPrint(!openPrint);

  const goBack = () => {
    if (!_.isEmpty(applicationRequest.uid)) {
      navigate(
        Routes.compilePath(Routes.applicationAudits, {
          id: applicationRequest.uid,
        }),
      );
    }
  };

  const getFormData = () => {
    setFormData(
      formQuestions.map((q: any) => {
        var answer = (formAnswers.answer ?? []).find(
          (a: any) => a.questionID === q.uid,
        );
        return {
          ...q,
          evaluation: _.get(answer, 'evaluation.value', ''),
          mismatch: _.get(answer, 'mismatch.value', ''),
          desc: _.get(answer, 'description', ''),
          evidence: {
            images: _.get(answer, 'images', []),
            files: _.get(answer, 'files', []),
          },
        };
      }),
    );
  };

  const send = async () => {
    if (_.isEmpty(id)) return;
    setSending(true);
    const requestResult = await sendAudit(id || '');
    if (!requestResult.status) {
      setSending(false);
      messageApi.open({
        type: 'error',
        content: requestResult.msg,
      });
    } else {
      setSending(false);
      messageApi.open({
        type: 'success',
        content: 'Хүсэлтийг илгээлээ.',
      });
    }
  };

  const sendToBoardAction = async () => {
    if (_.isEmpty(id)) return;
    setSendingBoard(true);
    const requestResult = await sendToBoard(id || '');
    if (!requestResult.status) {
      setSendingBoard(false);
      messageApi.open({
        type: 'error',
        content: requestResult.msg,
      });
    } else {
      setSendingBoard(false);
      messageApi.open({
        type: 'success',
        content: 'Хүсэлтийг илгээлээ.',
      });
    }
  };

  const getMembers = async () => {
    const members = await firestore
      .collection('auditors')
      .where('uid', 'in', audit?.members)
      .get();
    const board = await firestore
      .collection('auditors')
      .where('uid', 'in', audit?.boardMembers)
      .get();
    setMembers(members.docs.map((m) => ({...m.data(), uid: m.id})));
    setBoardMembers(board.docs.map((m) => ({...m.data(), uid: m.id})));
  };

  useEffect(() => {
    getFormData();
  }, [formQuestions, formAnswers]);

  useEffect(() => {
    if (!_.isEmpty(audit?.members)) {
      getMembers();
    }
  }, [audit]);

  return (
    <div className="pb-16">
      {contextHolder}
      <div className="flex flex-wrap items-center gap-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Аудитын тайлан
        </Button>
        {auditRoles.send.includes(userRole) && audit.teamLead === user.uid && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Button
              type="primary"
              shape="round"
              onClick={send}
              loading={sending}
              disabled={
                audit.status !== AuditStatus.started &&
                audit.status !== AuditStatus.rejected
              }
              icon={<i className="bx bx-send"></i>}
            >
              {audit.type === 'local'
                ? 'Байгууллагад илгээх'
                : 'Тариаланчид илгээх'}
            </Button>
          </div>
        )}
        {auditRoles.sendToBoard.includes(userRole) && (
          <div className="flex flex-1 justify-end gap-x-4">
            <Button
              type="primary"
              shape="round"
              onClick={sendToBoardAction}
              loading={sendingToBoard}
              disabled={audit.status !== AuditStatus.sent}
              icon={<i className="bx bx-send mr-2"></i>}
            >
              Зөвлөлд илгээх
            </Button>
          </div>
        )}
      </div>
      <hr />
      <Spin
        spinning={
          applicationLoading ||
          auditLoading ||
          formLoading ||
          formAnswerLoading ||
          applLoading
        }
      >
        {[
          AuditStatus.sentToBoard,
          AuditStatus.finished,
          AuditStatus.rejected,
        ].includes(audit.status || '') && (
          <div className="card-container p-6 mb-5 mt-8">
            <AuditBoardMembers
              audit={audit}
              comments={comments}
              boardMembers={auditBoardMembers}
            />
          </div>
        )}
        <div className="card-container p-6 my-8">
          <div className="flex justify-between items-center w-full mt-4">
            <div className="text-xl font-medium">Ерөнхий мэдээлэл</div>
            <div className="flex gap-3">
              <Button
                onClick={toggleOpenPrint}
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </div>
          </div>
          <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
          <Row>
            <Col xs={24}>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">Статус:</div>
                <div>{auditStatusTag(audit.status, audit.type)}</div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">Гарчиг:</div>
                <div>
                  {auditorAuditTypes.find((t) => t.value === audit.auditType)
                    ?.name || ''}
                </div>
              </div>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">
                  Аудитын дугаар:
                </div>
                <div>{audit.auditNumber || ''}</div>
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">
                  Тайлангийн огноо:
                </div>
                <div>{convertDateTimeFromTimestamp(audit.startedAt)}</div>
              </div>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">
                  Аудит хийх стандарт:
                </div>
                <div>{audit.applicationName || emptyText}</div>
              </div>
            </Col>
            <Col xs={24}>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">
                  Аудитын дүгнэлт:
                </div>
                <div>{auditReport.conclusion || emptyText}</div>
              </div>
            </Col>
            <Col xs={24}>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">Тайлбар:</div>
                <div>{auditReport.description || emptyText}</div>
              </div>
            </Col>
            <Col xs={24}>
              <div className="flex flex-wrap items-center mb-3">
                <div className="font-medium w-full sm:w-48 my-1">Хавсралт:</div>
                <AuditAttachments
                  attachments={auditReport.attachments || []}
                  auditUid={id || '0'}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-container p-6 my-8">
          <Tabs
            defaultActiveKey="1"
            tabPosition={'top'}
            items={[
              {
                label: `0. Аудит төлөвлөгөө`,
                key: '0',
                children: <AuditReportPlan uid={audit.uid} />,
              },
              {
                label: `1. Хүсэлт гаргагч`,
                key: '1',
                children: (
                  <AuditReportCompany
                    companyUid={audit.companyUid}
                    farmerUid={audit.farmerUid}
                    type={audit.type}
                  />
                ),
              },
              {
                label: `2. Баталгаажуулагч`,
                key: '2',
                children: (
                  <AuditReportAuditor auditCompanyUid={audit.auditCompanyId} />
                ),
              },
              {
                label: `3. Аудитын талаар мэдээлэл`,
                key: '3',
                children: (
                  <AuditReportInfo
                    audit={audit}
                    auditPlan={auditPlan}
                    applicationRequest={applicationRequest}
                    auditMembers={auditMembers}
                    report={auditReport}
                  />
                ),
              },
              {
                label: `4. Аудитын явц`,
                key: '4',
                children: <AuditReportProgress auditProcess={auditProcess} />,
              },
              {
                label: `5. Аудитын үр дүн`,
                key: '5',
                children: <AuditReportResult report={auditReport.result} />,
              },
            ]}
          />
          <div className="bg-surface mt-8 mb-8 h-[1px]"></div>
          <DataTable
            tableName="Шалгуур үзүүлэлтийн үнэлгээ"
            columns={tcAuditEvaluationColumns}
            data={formData}
            loading={formLoading || formAnswerLoading}
            pagination={false}
          />
        </div>
      </Spin>
      <AuditReportPrint
        open={openPrint}
        toggle={toggleOpenPrint}
        audit={audit}
        farmer={farmerData}
        company={companyData}
        auditMembers={auditMembers}
        boardMembers={auditBoardMembers}
        auditPlan={auditPlan}
        application={application}
        auditProcess={auditProcess}
        auditReport={auditReport}
        evaluations={formData}
        comments={comments}
      />
    </div>
  );
};

export default AuditReport;
