import {Button, Col, Row, Spin} from 'antd';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import {ArrowLeftOutlined, PlusOutlined} from '@ant-design/icons';
import {useCollection, useDoc} from '../../helpers/firestore';
import {ApplicationRequestModel, AuditGuideModel} from '../../types';
import {appRequestAuditTypes} from '../../constants/app_data';
import {useContext, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {ColumnsType} from 'antd/es/table';
import DataTable from '../../components/table/Index';
import GuidanceEdit from './GuidanceEdit';
import _ from 'lodash';
import AuditTeam from './AuditTeam';
import AuditInfo from './AuditInfo';
import {auditRoles} from '../../helpers/role_helper';
import {emptyText} from '../../helpers/theme_helper';

const AuditDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {userRole} = useContext(UserContext);
  const [audit, auditLoading] = useDoc(`audits/${id}`);
  const [application, applicationLoading] = useDoc(
    `applicationRequests/${audit.applicationRequestUid}`,
  );
  const [guide, guideLoading] = useCollection(
    `audits/${id}/guides`,
    'createdAt',
  );

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);
  const [selectedGuide, setSelectedGuide] = useState<
    AuditGuideModel | undefined
  >();

  const columns: ColumnsType<AuditGuideModel> = [
    {
      key: 'guidanceDate',
      title: 'Тушаалын огноо',
      dataIndex: 'guidanceDate',
    },
    {
      key: 'number',
      title: 'Тушаалын дугаар',
      dataIndex: 'number',
    },
    {
      key: 'createdAt',
      title: '',
      dataIndex: 'createdAt',
      render: (_, record) => (
        <div className="flex gap-2">
          <div
            className="cursor-pointer text-info"
            onClick={() => {
              onClickGuide(record);
            }}
          >
            {auditRoles.addEditAuditGuide.includes(userRole) ? (
              <Button
                icon={<i className="bx bx-edit-alt"></i>}
                size="small"
                type="primary"
                className="bg-warning"
              />
            ) : (
              <Button
                icon={<i className="bx bx-detail"></i>}
                size="small"
                type="primary"
                className="bg-lightBlue"
              />
            )}
          </div>
          <Link to={record.attachmentUrl} target="_blank" rel="noreferrer">
            <Button icon={<i className="bx bx-link"></i>} size="small" />
          </Link>
        </div>
      ),
    },
  ];

  const onClickGuide = (record: any) => {
    setSelectedGuide(record);
    toggleModal();
  };

  const addGuide = () => {
    setSelectedGuide(undefined);
    toggleModal();
  };

  const goBack = () => {
    if (!_.isEmpty(application.uid)) {
      navigate(
        Routes.compilePath(Routes.applicationAudits, {id: application.uid}),
      );
    }
  };

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Аудитын ажлын дэлгэрэнгүй
        </Button>
      </div>
      <hr />
      <Spin spinning={applicationLoading || auditLoading}>
        <Row gutter={[16, 16]} className="my-8">
          <Col xs={24}>
            <div className="card-container p-6 h-full">
              <div className="text-xl font-medium">Өргөдлийн мэдээлэл</div>
              <Row>
                <Col xs={24} sm={12}>
                  <div className="mt-5 text-sm text-gray">
                    {(application as ApplicationRequestModel).type !== 'finy'
                      ? 'Байгууллага'
                      : 'Тариаланч'}
                  </div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel).type !== 'finy'
                      ? (application as ApplicationRequestModel).companyName
                      : (application as ApplicationRequestModel).farmerName}
                  </div>
                  <div className="mt-5 text-sm text-gray">Баталгаажуулалт</div>
                  <div className="mt-1">
                    {(application as ApplicationRequestModel).applicationName ||
                      emptyText}
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  {(application as ApplicationRequestModel).type !== 'finy' ? (
                    <>
                      <div className="mt-5 text-sm text-gray">
                        Баталгаажуулалт хүсч буй хамрах хүрээ
                      </div>
                      <div className="mt-1">
                        {(application as ApplicationRequestModel).auditScope ||
                          emptyText}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mt-5 text-sm text-gray">
                        Гэрээний дугаар
                      </div>
                      <div className="mt-1">
                        {(application as ApplicationRequestModel)
                          .contractNumber || emptyText}
                      </div>
                    </>
                  )}
                  <div className="mt-5 text-sm text-gray">Аудитын төрөл</div>
                  <div className="mt-1">
                    {appRequestAuditTypes.find(
                      (a) =>
                        a.value ===
                        (application as ApplicationRequestModel).auditType,
                    )?.name || emptyText}
                  </div>
                </Col>
                {(application as ApplicationRequestModel).type === 'local' && (
                  <Col xs={24} sm={12}>
                    <div className="mt-9">
                      <Link
                        target="_blank"
                        to={Routes.compilePath(Routes.applicationRequestInfo, {
                          id: application.uid || '0',
                        })}
                      >
                        Өргөдлийн дэлгэрэнгүй харах
                      </Link>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <AuditInfo audit={audit} />
          </Col>
          <Col xs={24} md={12}>
            <AuditTeam audit={audit} />
          </Col>
        </Row>
      </Spin>
      <div className="pb-16">
        <DataTable
          tableName="Тушаал, удирдамжууд"
          columns={columns}
          data={guide}
          loading={guideLoading}
          headerWidgers={
            auditRoles.addEditAuditGuide.includes(userRole)
              ? [
                  <Button
                    key={'add1'}
                    className="hidden sm:block text-sm"
                    shape="round"
                    onClick={addGuide}
                    icon={<PlusOutlined />}
                  >
                    Тушаал нэмэх
                  </Button>,
                  <Button
                    key={'add2'}
                    className="block sm:hidden text-sm"
                    shape="circle"
                    onClick={addGuide}
                    icon={<PlusOutlined />}
                  />,
                ]
              : []
          }
        />
      </div>
      <GuidanceEdit
        open={open}
        toggle={toggleModal}
        auditUid={id || ''}
        selectedGuide={selectedGuide}
        otherGuides={(guide as AuditGuideModel[]).filter(
          (g) => g.uid !== selectedGuide?.uid,
        )}
      />
    </div>
  );
};
export default AuditDetail;
