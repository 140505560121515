import {Button, Col, Form, Input, Row, Select, notification} from 'antd';
import {useState} from 'react';
import {useCollection} from '../../helpers/firestore';
import {legalStatuses} from '../../constants/app_data';
import {cityData} from '../../constants/city_data';
import LogoImage from '../../assets/images/logo.png';
import {companyRegistrationRequest} from '../../helpers/firebase_functions';
import {AntNotificationType} from '../../types';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import {companyRequestRules} from '../../helpers/validations';

const CompanyRequestSend = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [certificates] = useCollection('certificates');

  const onSave = async (values: any) => {
    setLoading(true);
    const result = await companyRegistrationRequest({
      ...values,
      targetCertificateName: certificates.find(
        (c: any) => c.uid === values.targetCertificate,
      ).name,
    });
    setLoading(false);
    if (result.status) {
      form.resetFields();
      openNotificationWithIcon(
        'success',
        'Бүртгүүлэх хүсэлт',
        'Таны хүсэлтийг хүлээн авлаа. Хүсэлтийн хариуг имейл хаягруу илгээх болно.',
      );
    } else {
      openNotificationWithIcon(
        'error',
        'Бүртгүүлэх хүсэлт',
        result.msg ?? 'Хүсэлт илгээхэд алдаа гарлаа',
      );
    }
  };

  const openNotificationWithIcon = (
    type: AntNotificationType,
    title: string,
    desc: string,
  ) => {
    api[type]({
      message: title,
      description: desc,
    });
  };

  const login = () => {
    navigate(Routes.login);
  };

  const requireLabel = <span className="text-error">*</span>;

  return (
    <div className="container py-24 mx-auto">
      {contextHolder}
      <Row justify="center">
        <Col xs={23} md={20} lg={18} xl={16} xxl={12}>
          <div className="flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="text-xl text-center font-medium mb-5">
            Бүртгүүлэх хүсэлт илгээх
          </div>
          <div className="card-container mt-5 p-10">
            <Form form={form} onFinish={onSave} scrollToFirstError>
              <div className="text-lg font-medium mb-5">
                Байгууллагын мэдээлэл
              </div>
              <Row gutter={16}>
                <Col xs={24}>
                  <div>Байгууллагын нэр {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.companyName}
                    name="companyName"
                  >
                    <Input placeholder="Байгууллагын нэр" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div>Улсын бүртгэлийн дугаар {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.registrationNumber}
                    name="registrationNumber"
                  >
                    <Input placeholder="5805406" />
                  </Form.Item>
                  <div>Хүсч буй баталгаажуулалтын төрөл {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.targetCertificate}
                    name="targetCertificate"
                  >
                    <Select
                      placeholder="ХААЗД"
                      options={certificates.map((c: any) => ({
                        value: c.uid,
                        label: c.name,
                      }))}
                    />
                  </Form.Item>
                  <div>Аймаг, хот {requireLabel}</div>
                  <Form.Item rules={companyRequestRules.city} name="city">
                    <Select
                      placeholder="Улаанбаатар"
                      options={cityData.map((c: any) => ({
                        value: c.code,
                        label: c.name,
                      }))}
                    />
                  </Form.Item>
                  <div>Утас {requireLabel}</div>
                  <Form.Item rules={companyRequestRules.phone} name="phone">
                    <Input placeholder="99112233" />
                  </Form.Item>
                  <div>Байгууллагын хаяг</div>
                  <Form.Item name="address">
                    <Input placeholder="Байгууллагын хаяг" />
                  </Form.Item>
                  <div>Веб хуудас</div>
                  <Form.Item name="webAddress">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div>Үйл ажиллагааны чиглэл {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.activity}
                    name="activity"
                  >
                    <Input placeholder="Үйл ажиллагааны чиглэл" />
                  </Form.Item>
                  <div>Хууль эрх зүйн статус {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.legalStatus}
                    name="legalStatus"
                  >
                    <Select
                      placeholder="Хууль эрх зүйн статус"
                      options={legalStatuses.map((c: any) => ({
                        value: c,
                        label: c,
                      }))}
                    />
                  </Form.Item>
                  <div>Сум, дүүрэг {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.district}
                    name="district"
                  >
                    <Input placeholder="Сүхбаатар" />
                  </Form.Item>
                  <div>Имэйл {requireLabel}</div>
                  <Form.Item rules={companyRequestRules.email} name="email">
                    <Input placeholder="email@gmail.com" />
                  </Form.Item>
                  <div>Шуудангийн хаяг, код</div>
                  <Form.Item name="postalCode">
                    <Input placeholder="Шуудангийн хаяг, код" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
              <div className="text-lg font-medium mb-5">
                Байгууллагын төлөөллийн мэдээлэл
              </div>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <div>Эцэг/эхийн нэр {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.repLastname}
                    name="repLastname"
                  >
                    <Input placeholder="Эцэг/эхийн нэр" />
                  </Form.Item>
                  <div>Нэр {requireLabel}</div>
                  <Form.Item rules={companyRequestRules.repName} name="repName">
                    <Input placeholder="Нэр" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div>Имэйл {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.repEmail}
                    name="repEmail"
                  >
                    <Input placeholder="Имэйл" />
                  </Form.Item>
                  <div>Утас {requireLabel}</div>
                  <Form.Item
                    rules={companyRequestRules.repPhone}
                    name="repPhone"
                  >
                    <Input placeholder="Утас" />
                  </Form.Item>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  type="primary"
                  shape="round"
                  className="w-64 mt-4"
                  disabled={loading}
                  loading={loading}
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Илгээх
                </Button>
                <div
                  onClick={login}
                  className="cursor-pointer text-info font-medium mt-4"
                >
                  Нэвтрэх
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyRequestSend;
