import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import TableHeader from './TableHeader';
import {TableSetting} from '../../types';
import TableSettingModal from './TableSetting';
import TableMobile from './TableMobile';
import * as _ from 'lodash';
import {SearchOutlined} from '@ant-design/icons';

type Props = {
  tableName?: string;
  columns: ColumnsType<any>;
  data: any[];
  loading?: boolean;
  filter?: JSX.Element[];
  onClickRow?: (data: any) => void;
  onSearch?: () => void;
  headerWidgers?: JSX.Element[];
  reloadData?: () => void;
  pagination?: any;
  showHeader?: boolean;
};

const DataTable: React.FC<Props> = ({
  tableName,
  columns,
  data,
  loading = false,
  filter,
  onClickRow,
  reloadData,
  onSearch,
  headerWidgers,
  pagination,
  showHeader = true,
}) => {
  const [tableSettings, setTableSettings] = useState([] as TableSetting[]);
  const [openSettings, setOpenSettings] = useState(false);
  const toggleSetting = (value: boolean) => setOpenSettings(value);

  useEffect(() => {
    if (_.isEmpty(tableSettings)) {
      setTableSettings(columns.map((c) => ({column: c, show: true})));
    }
  }, [columns]);

  const onRowClick = (d: any) => {
    if (onClickRow != null) {
      onClickRow(d);
    }
  };

  return (
    <>
      <Table
        loading={loading}
        columns={tableSettings.filter((s) => s.show).map((s) => s.column)}
        dataSource={data.map((d) => ({...d, key: d.uid}))}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              onRowClick(record);
            },
          };
        }}
        bordered
        title={() => (
          <div>
            {showHeader && (
              <TableHeader
                tableName={tableName || ''}
                headerWidgers={headerWidgers}
                toggleSetting={toggleSetting}
                reloadData={reloadData}
              />
            )}
            {filter != null && (
              <div className="p-4 flex flex-wrap gap-3">
                {filter}
                <Button
                  shape="round"
                  type="primary"
                  className="shadow-none"
                  onClick={onSearch}
                  icon={<SearchOutlined />}
                >
                  Хайх
                </Button>
              </div>
            )}
            <TableMobile
              data={data.map((d) => ({...d, key: d.uid}))}
              columns={tableSettings.filter((s) => s.show).map((s) => s.column)}
              onClick={onClickRow}
            />
          </div>
        )}
        pagination={pagination}
      />
      <TableSettingModal
        setting={tableSettings}
        changeSetting={setTableSettings}
        open={openSettings}
        toggleOpen={toggleSetting}
      />
    </>
  );
};

export default DataTable;
