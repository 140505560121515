import React, {useContext, useEffect, useState} from 'react';
import {Button, Input, Modal, message, Form, Select} from 'antd';
import {UserContext} from '../../context/app.contexts';
import {getUidFirebase, saveData, useCollection} from '../../helpers/firestore';
import * as _ from 'lodash';
import {firestore} from '../../firebase';
import {ApplicationModel, CertificateModel} from '../../types';

type Props = {
  open?: boolean;
  selected?: ApplicationModel;
  toggle: (value: boolean) => void;
  onSuccess?: () => void;
};

const ApplicationEdit: React.FC<Props> = ({
  open = false,
  toggle,
  selected,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const {userCompany} = useContext(UserContext);
  const [certificatesData] = useCollection('certificates');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    form.resetFields();
    setLoadingSave(false);
    toggle(false);
  };

  const onSave = async (values: any) => {
    setLoadingSave(true);
    let docUid = selected?.uid;
    let cert = certificatesData.find(
      (c: any) => c.uid === values.certificate,
    ) as CertificateModel;
    if (_.isEmpty(selected) && _.isEmpty(docUid)) {
      const exist = await firestore
        .collection('applications')
        .where('certificate', '==', cert.uid)
        .where('companyId', '==', userCompany)
        .get();
      if (exist.docs.length > 0) {
        messageApi.open({
          type: 'error',
          content: 'Баталгаажуулалтын мэдээллийг аль хэдийн оруулсан байна',
        });
        onCancel();
        return;
      }
      docUid = await getUidFirebase();
    }
    await saveData(
      'applications',
      docUid,
      {
        ...values,
        name: cert.name,
        companyId: userCompany,
      },
      _.isEmpty(selected),
    );
    if (onSuccess != null) onSuccess();
    onCancel();
    messageApi.open({
      type: 'success',
      content: 'Баталгаажуулалтын мэдээлэл хадгалагдлаа.',
    });
  };

  useEffect(() => {
    form.setFieldsValue(selected);
  }, [selected]);

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key={1}
            type="primary"
            shape="round"
            className="w-full"
            disabled={loadingSave}
            loading={loadingSave}
            onClick={() => {
              form.submit();
            }}
          >
            Хадгалах
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="px-8">
            <div className="font-medium text-lg text-center my-5">
              Баталгаажуулалтын мэдээлэл
            </div>
            <Form form={form} onFinish={onSave} scrollToFirstError>
              <div className="font-medium">Нэр</div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Баталгаажуулалтын төрөл сонгоно уу!',
                  },
                ]}
                name="certificate"
              >
                <Select
                  options={certificatesData.map((c: any) => ({
                    value: c.uid,
                    label: c.name,
                  }))}
                />
              </Form.Item>
              <div className="font-medium">Тайлбар</div>
              <Form.Item
                rules={[{required: true, message: 'Тайлбар оруулна уу!'}]}
                name="desc"
              >
                <Input.TextArea showCount maxLength={200} />
              </Form.Item>
              <div className="font-medium">Стандарт, норматив</div>
              <Form.Item
                name="standartCode"
                rules={[
                  {required: true, message: 'Стандарт, норматив оруулна уу!'},
                ]}
              >
                <Input.TextArea
                  placeholder="MNS 6737:2018 “Хөдөө аж ахуйн зохистой дадал (ХААЗД) хэрэгжүүлэх удирдамж"
                  showCount
                  maxLength={300}
                />
              </Form.Item>
              <div className="font-medium">Аудитын шалгуур</div>
              <Form.Item
                initialValue=""
                name="shalguur"
                rules={[
                  {required: true, message: 'Аудитын шалгуур оруулна уу!'},
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={500}
                  placeholder="Хөдөө аж ахуйн зохистой дадал хэрэгжүүлэх удирдамж MNS 6737:2018 стандарт, ХХААХҮСайд, ЭМСайдын хамтарсан 2019 оны А/166, A/251 тушаал “Жимс, жимсгэнэ, хүнсний ногооны үйлдвэрлэлд ХААЗД-ыг хялбаршуулан нэвтрүүлэх заавар”"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationEdit;
