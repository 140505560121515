import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {firestore} from '../../firebase';
import DataTable from '../../components/table/Index';
import {ColumnsType} from 'antd/es/table';
import CertificateViewer from './CertificateViewer';
import * as _ from 'lodash';
import {Timestamp} from 'firebase/firestore';
import {AuthRoles,  RequestStatus} from '../../types';
import {Input,Tabs,TabsProps} from 'antd';
import {convertDateTimeFromTimestamp} from '../../helpers/utils';



const CertificateList = () => {
  const {userCompany, userRole} = useContext(UserContext);
  const [grantedCertificates, setGranteCertificates] = useState<any[]>([]);
  const [certificates, setCertificates] = useState<any[]>([]);
  const [showData, setShowData] = useState<any[]>([]);
  const [showCData, setShowCData] = useState<any[]>([]);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [crequestsLoading, setCRequestsLoading] = useState(true);
  const [searchNumber, setSearchNumber] = useState('');
  const [csearchNumber, setCSearchNumber] = useState('');
  const [selected, setSelected] = useState<any | undefined>();
  const [openViewer, setOpenViewer] = useState(false);
  const toggleModalViewer = () => setOpenViewer(!openViewer);
  type AppProps = {
    userUid: string;
  };
  const VerifiedUser = ({userUid}:AppProps)=>{
    const [userName,setUsername]=useState('')
    useEffect(() => {
      firestore
    .collection(`auditors`).doc(userUid).get().then((snapshot)=>{
      setUsername(`${snapshot.data()?.code}- ${snapshot.data()?.name}`)

    })
    }, []);

    return ( <div>{userName}</div>)
  }
  const certColumns: ColumnsType<any> = [
    {
      key: 'certNumber',
      title: 'Certificate дугаар',
      dataIndex: 'certNumber',
    },
  
    {
      key: 'certificateName',
      title: 'Баталгаажуулалт',
      dataIndex: 'certificateDesc',
      responsive: ['lg'],
    },
    {
      key: 'certValidDate',
      title: 'Огноо',
      dataIndex: 'certValidDate',
      render: (certValidDate: Timestamp) => convertDateTimeFromTimestamp(certValidDate),
      responsive: ['lg'],
    },
    {
      key: 'verifiedBy',
      title: 'Баталгаажуулалсан',
      dataIndex: 'verifiedBy',
      render:  (_,record) => ( <VerifiedUser userUid={record.verifiedBy} />) 

    },
    {
      key: 'vertifiedAt',
      title: 'Баталгаажуулсан Огноо',
      dataIndex: 'vertifiedAt',
      render: (vertifiedAt: Timestamp) => convertDateTimeFromTimestamp(vertifiedAt),
      responsive: ['lg'],
    },
    {
      key: 'uid',
      title: '',
      dataIndex: 'uid',
      render: (_, record) => (
        <div
          className="text-info cursor-pointer"
          onClick={() => {
            window.open(record.certificateImage)
          }}
        >
          Харах
        </div>
      ),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      key: 'certificateNumber',
      title: 'Гэрчилгээний дугаар',
      dataIndex: 'certificateNumber',
      render: (_, record) => (
        <div
          className="text-info cursor-pointer"
          onClick={() => {
            setSelected(record);
            toggleModalViewer();
          }}
        >
          {record.certificateNumber}
        </div>
      ),
    },
    {
      key: 'certificateName',
      title: 'Гэрчилгээ',
      dataIndex: 'certificateName',
    },
    {
      key: 'applicantName',
      title: 'Байгууллага',
      dataIndex: 'applicantName',
    },
    {
      key: 'certValidDate',
      title: 'Хүчинтэй хугацаа',
      dataIndex: 'certValidDate',
    },
    {
      key: 'date',
      title: 'Олгосон огноо',
      dataIndex: 'date',
    },
  ];


  const getCertificates = async ()=>{
    setCRequestsLoading(true);
    const certs = await firestore
    .collection(`certificateRequests`)
    .where('status', '==',  RequestStatus.verified)
    .orderBy("certificateImage", "desc").get()
    // .orderBy('sentAt', 'desc').get()

    console.log(certs.docs)
    setCertificates(certs.docs.map((d) => ({
      ...d.data(),
      uid: d.id,
    })));
    setShowCData(certs.docs.map((d) => ({
      ...d.data(),
      uid: d.id,
    })));
    
    setCRequestsLoading(false);
  }

  const getData = async () => {
    const queryField =
      userRole === AuthRoles.USER_COMPANY ? 'applicantUid' : 'auditCompanyUid';
    setRequestsLoading(true);
    const data = await firestore
      .collection(`grantedCertificates`)
      .where(queryField, '==', userCompany)
      .orderBy('createdAt', 'desc')
      .get();
    setGranteCertificates(data.docs.map((d) => ({...d.data(), uid: d.id})));
    setShowData(data.docs.map((d) => ({...d.data(), uid: d.id})));
    setRequestsLoading(false);
  };

  const search = () => {
    setShowData(
      grantedCertificates.filter((d) =>
        d.certificateNumber.toLowerCase().includes(searchNumber.toLowerCase()),
      ),
    );
  };

  const csearch = () => {
    console.log(certificates,csearchNumber)
    setShowCData(
      certificates.filter((d) =>
        d.certNumber.toLowerCase().includes(csearchNumber.toLowerCase()),
      ),
    );
  };
  useEffect(() => {
    if (!openViewer) {
      setSelected(undefined);
    }
  }, [openViewer]);

  useEffect(() => {
    const init = async ()=>{
      if (!_.isEmpty(userCompany) && !_.isEmpty(userRole)) {
        await getData();
        await getCertificates();
      }
    }
    init()

  }, [userCompany, userRole]);
  const AppCertificates = ()=>{


    return (
      <>
        <DataTable
          tableName="Гэрчилгээнүүд"
          columns={columns}
          data={showData}
          loading={requestsLoading}
          reloadData={getData}
          onSearch={search}
          filter={[
            <Input
              key="phone"
              size="small"
              className="w-auto"
              placeholder="Гэрчилгээний дугаар"
              value={searchNumber}
              onChange={(e) => {
                setSearchNumber(e.target.value);
              }}
            />,
          ]}
        />
        <CertificateViewer
          open={openViewer}
          toggle={toggleModalViewer}
          selected={selected}
        />
      </>
    );
  }
  const VerifiedCertificates = ()=>{

    return (
      <>
        <DataTable

          columns={certColumns}
          data={showCData}
          loading={crequestsLoading}
          reloadData={getCertificates}
          onSearch={csearch}
          filter={[
            <Input
              key="certificateNumber"
              size="small"
              className="w-auto"
              placeholder="Гэрчилгээний дугаар"
              value={csearchNumber}
              onChange={(e) => {
                setCSearchNumber(e.target.value);
              }}
            />,
          ]}
        />
  
      </>
    );
  }
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Гэрчилгээнүүд',
      children: <AppCertificates />,
    },
    {
      key: '2',
      label: 'Finy баталгаажуулсан гэрчилгээ',
      children: <VerifiedCertificates/>,
    }
  ];
  


return (
  <>
    <Tabs defaultActiveKey="1" items={items}  />
    </>
)

};

export default CertificateList;
