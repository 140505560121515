import React, {useState} from 'react';
import {Button, Input, Modal, Alert, message} from 'antd';
import LogoImage from '../../../assets/images/logo.png';
import * as _ from 'lodash';

type Props = {
  isAccept?: boolean;
  open?: boolean;
  toggle: (value: boolean) => void;
  onSubmit: (value: string) => any;
};

const AuditAcceptReject: React.FC<Props> = ({
  isAccept,
  open = false,
  toggle,
  onSubmit,
}) => {
  const [comment, setComment] = useState<string>('');
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCancel = () => {
    setComment('');
    setError('');
    setLoadingSave(false);
    toggle(false);
  };

  const validate = () => {
    if (_.isEmpty(comment) || loadingSave) return false;
    return true;
  };

  const onSave = async () => {
    if (!validate()) return;
    setLoadingSave(true);
    const result = await onSubmit(comment);
    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.msg,
      });
    } else {
      onCancel();
      messageApi.open({
        type: 'success',
        content: 'Санал хадгалагдлаа',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Finy App"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[
          <Button
            key="send"
            loading={loadingSave}
            disabled={!validate()}
            shape="round"
            type="primary"
            onClick={onSave}
          >
            {isAccept ? 'Батлах' : 'Буцаах'}
          </Button>,
        ]}
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            <img alt="logo" src={LogoImage} height={140} width="auto" />
          </div>
          <div className="dialog-content px-8">
            <div className="font-medium text-lg text-center mb-5">Санал</div>
            <Input.TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Санал"
              className="mb-3"
            />
            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuditAcceptReject;
