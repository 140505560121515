import {useContext, useEffect, useState} from 'react';
import {AuditModel} from '../../types';
import {UserContext} from '../../context/app.contexts';
import {Button, Select, message} from 'antd';
import {firestore} from '../../firebase';
import * as _ from 'lodash';
import {CheckCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import {auditRoles} from '../../helpers/role_helper';
import {emptyText} from '../../helpers/theme_helper';
import {assignAuditMembers} from '../../helpers/firebase_functions';

type Props = {
  audit: AuditModel;
};

const AuditTeam: React.FC<Props> = ({audit}) => {
  const {userRole, userCompany} = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [savingMembers, setSavingMember] = useState(false);

  const [members, setMembers] = useState<string[]>([]);
  const [teamLead, setTeamLead] = useState<string | undefined>();
  const [boardMembers, setBoardMembers] = useState<string[]>([]);
  const [auditors, setAuditors] = useState<any[]>([]);
  const [writePermitted, setWritePermitted] = useState(false);

  const saveAuditMembers = async () => {
    if (!validateMember()) return;
    if (!writePermitted) {
      messageApi.open({
        type: 'error',
        content: `Та аудитын багийн мэдээлэл өөрчлөх боломжгүй байна`,
      });
      return;
    }
    setSavingMember(true);
    const requestResult = await assignAuditMembers(
      audit.uid,
      [teamLead!, ...members],
      teamLead!,
      boardMembers,
      audit.applicationName,
    );
    setSavingMember(false);
    if (!requestResult.status) {
      messageApi.open({
        type: 'error',
        content: requestResult.msg ?? 'Алдаа гарлаа',
      });
    } else {
      messageApi.open({
        type: 'success',
        content: `Аудитын багийн мэдээлэл засагдлаа`,
      });
    }
  };

  const validateMember = () => {
    if (_.isEmpty(teamLead) || _.isEmpty(boardMembers)) return false;
    if (_.intersection(members, [teamLead]).length > 0) return false;
    if (_.intersection(members, boardMembers).length > 0) return false;
    if (_.intersection([teamLead], boardMembers).length > 0) return false;
    return true;
  };

  const getAuditors = async () => {
    const data = await firestore
      .collection('auditors')
      .where('companyId', '==', userCompany)
      .where('role', '==', 'audit_auditor')
      .get();
    setAuditors(data.docs.map((d) => ({...d.data(), uid: d.id})));
  };

  useEffect(() => {
    setWritePermitted(auditRoles.addEditAuditTeam.includes(userRole));
  }, [userRole]);

  useEffect(() => {
    if (!_.isEmpty(userCompany)) {
      getAuditors();
    }
  }, [userCompany]);

  useEffect(() => {
    if (!_.isEmpty(audit)) {
      setTeamLead(audit.teamLead);
      setMembers(
        (audit.members || []).filter((m: any) => m !== audit.teamLead) || [],
      );
      setBoardMembers(audit.boardMembers || []);
    }
  }, [audit]);

  return (
    <div className="card-container p-6 h-full">
      {contextHolder}
      <div className="text-xl font-medium mb-5">
        {validateMember() ? (
          <CheckCircleOutlined className="text-primary" />
        ) : (
          <InfoCircleOutlined className="text-warning" />
        )}{' '}
        <span className="ml-3">Аудитын баг</span>
      </div>
      <div className="text-gray text-sm mb-1">Багийн ахлагч</div>
      {!writePermitted ? (
        <div className="mb-6">
          {auditors.find((a) => a.uid === teamLead)?.email || emptyText}
        </div>
      ) : (
        <Select
          allowClear
          className="w-full mb-3"
          value={teamLead}
          placeholder="Багийн ахлагч"
          onChange={setTeamLead}
          options={auditors.map((a: any) => ({
            label: `${a.code} - ${a.name}`,
            value: a.uid,
          }))}
        />
      )}
      <div className="text-gray text-sm mb-1">Багийн гишүүд</div>
      {!writePermitted ? (
        <div className="mb-6">
          {members.length > 1
            ? auditors
                .filter((a) => members.includes(a.uid))
                .map((a) => a.email)
                .join(', ')
            : emptyText}
        </div>
      ) : (
        <Select
          allowClear
          mode="multiple"
          className="w-full mb-6"
          value={members}
          placeholder="Багийн гишүүд"
          onChange={setMembers}
          options={auditors.map((a: any) => ({
            label: `${a.code} - ${a.name}`,
            value: a.uid,
          }))}
        />
      )}
      <div className="text-gray text-sm mb-1">Зөвлөлийн гишүүд</div>
      {!writePermitted ? (
        <div className="mb-6">
          {boardMembers.length > 1
            ? auditors
                .filter((a) => boardMembers.includes(a.uid))
                .map((a) => a.email)
                .join(', ')
            : emptyText}
        </div>
      ) : (
        <Select
          allowClear
          mode="multiple"
          className="w-full mb-6"
          value={boardMembers}
          placeholder="Зөвлөлийн гишүүд"
          onChange={(values: any[]) => {
            setBoardMembers(values);
          }}
          options={auditors.map((a: any) => ({
            label: `${a.code} - ${a.name}`,
            value: a.uid,
          }))}
        />
      )}
      {writePermitted && (
        <div className="flex justify-center my-3">
          <Button
            loading={savingMembers}
            disabled={!validateMember()}
            onClick={saveAuditMembers}
            shape="round"
            type="primary"
          >
            Хадгалах
          </Button>
        </div>
      )}
    </div>
  );
};

export default AuditTeam;
