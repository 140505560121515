import {Timestamp} from 'firebase/firestore';

export enum AuthRoles {
  AUDIT_ADMIN = 'audit_admin',
  AUDIT_AUDITOR = 'audit_auditor',
  AUDIT_CONTRACTOR = 'audit_contractor',
  USER_COMPANY = 'user_company',
  ANONYMOUS = 'anonymous',
}

export enum LoanStatus {
  loanCreated = 'loanCreated',
  sentRequest = 'sentRequest',
  rejected = 'rejected',
  accepted = 'accepted',
}

export enum RequestStatus {
  created = 'created',
  sent = 'sent',
  confirmed = 'confirmed',
  verified = 'verified',
  rejected = 'rejected',
}

export enum ContractStatus {
  created = 'created',
  assigned = 'assigned',
  signed = 'signed',
}

export type RouteModel = {
  path: string;
  component: () => JSX.Element;
};

export type OtpEmailModel = {
  type: string;
  email: string;
  otpCode: number;
  isVerified: boolean;
  createdAt: Timestamp;
};

export type NotificationModel = {
  uid: string;
  region: string;
  regionCode: string;
  title: string;
  body: string;
  sentAt: Timestamp | undefined;
  createdAt: Timestamp;
};

export type CertificateModel = {
  uid: string;
  name: string;
  desc: string;
  image: string;
  logo: string;
  groups: FromGroup[];
};

export type ApplicationModel = {
  uid: string;
  name: string;
  desc: string;
  certificate: string;
  standartCode: string;
  shalguur: string;
  signeeId: string;
  companyId: string;
  groups: FromGroup[];
};

export type ApplicationDocument = {
  uid: string;
  name: string;
  desc: string;
};

export type ApplicationScope = {
  uid: string;
  name: string;
};

export type FromGroup = {
  id: number;
  name: string;
};

export type FromQuestion = {
  uid: string;
  groupId?: number;
  question?: string;
  mismatch?: QuestionOptions[];
  evaluation?: QuestionOptions[];
};

export type QuestionOptions = {
  id: number;
  value: string;
  score: number;
};

export type AuditCompanyModel = {
  uid: string;
  name: string;
  adminEmail: string;
  createdAt: Timestamp;
};

export type AuditorModel = {
  uid: string;
  code: string;
  name: string;
  email: string;
  role: string;
  urgiinOvog: string;
  lastname: string;
  gender: string;
  registrationNumber: string;
  imageUrl: string;
  phone: string;
  city: string;
  district: string;
  street: string;
  address: string;
  dateOfBirth: string;
  companyId: string;
  createdAt: Timestamp;
};

export type FieldRequestModel = {
  uid: string;
  year: number;
  sentAt: Timestamp;
  createdAt: Timestamp;
  openFieldData: any;
  greenhouseData: any;
  status: string;
  userUid: string;
  userCity: string;
  userDistrict: string;
  userPhone: string;
  fileUrls: string[];
  greenFileUrls: string[];
};

export type CertificateRequestModel = {
  uid: string;
  sentAt: Timestamp;
  createdAt: Timestamp;
  assignedTo: string;
  certificateName: string;
  certificateDesc: string;
  certificateUid: string;
  isContract?: boolean;
  contractType: ContractType;
  contractUid?: string;
  certNumber?: string;
  certValidDate?: Timestamp;
  certificateImage?: string;
  requestData?: any;
  status: string;
  userUid: string;
  userCity: string;
  userDistrict: string;
  userPhone: string;
};

export type ContractModel = {
  uid: string;
  contractNumber: string;
  certificateName: string;
  certificateUid: string;
  userCity: string;
  userDistrict: string;
  userName: string;
  userPhone: string;
  userRegister: string;
  userType: string;
  userUid: string;
  status: string;
  contractType: ContractType;
  farmerSignatureUrl: string;
  applicationRequestUid?: string;
  applicationRequestNumber?: string;
  createdAt: Timestamp;
};

export type TableSetting = {
  column: any;
  show: boolean;
};

export type AntNotificationType = 'success' | 'info' | 'warning' | 'error';

export type CompanyRequestModel = {
  uid: string;
  companyName: string;
  registrationNumber: string;
  activity: string;
  targetCertificate: string;
  targetCertificateName: string;
  legalStatus: string;
  city: string;
  district: string;
  webAddress: string;
  postalCode: string;
  address: string;
  phone: string;
  email: string;
  repLastname: string;
  repName: string;
  repEmail: string;
  repPhone: string;
  status: string;
  createdAt: Timestamp;
};

export type CompanyModel = {
  uid: string;
  companyName: string;
  registrationNumber: string;
  activity: string;
  legalStatus: string;
  city: string;
  district: string;
  webAddress: string;
  postalCode: string;
  address: string;
  phone: string;
  email: string;
  repLastname: string;
  repName: string;
  repEmail: string;
  repPhone: string;
  createdAt: Timestamp;
};

export type ApplicationRequestModel = {
  uid: string;
  requestNumber: string;
  certificateUid: string;
  applicationUid: string;
  applicationName: string;
  auditCompanyId: string;
  auditType: string;
  status: string;
  sentAt: Timestamp;
  createdAt: Timestamp;
  acceptedAt: Timestamp;
  rejectedAt: Timestamp;
  createdBy: string;
  type: string;

  // company request
  auditScope: string;
  advisor: string;
  ceo: string;
  formData: string;
  documents?: string[];
  organizationStructure: any;
  description: string;
  continuous: boolean;
  seasonal: boolean;
  special: boolean;
  rejectReason?: string;
  companyUid: string;
  companyName: string;

  //farmer request
  farmerName: string;
  farmerPhone: string;
  farmerRegister: string;
  farmerUid: string;
  contractUid: string;
  contractNumber: string;
};

export enum ApplicationRequestStatus {
  created = 'created',
  sent = 'sent',
  rejected = 'rejected',
  accepted = 'accepted',
}

export type AnswerModel = {
  id: string;
  question: string;
  answer: any;
  groupId: number;
};

export type AuditModel = {
  uid: string;
  auditNumber: string;
  auditScope: string;
  auditType: string;
  applicationRequestUid: string;
  applicationName: string;
  companyUid?: string;
  companyName?: string;
  farmerUid?: string;
  farmerName?: string;
  type: string;
  auditCompanyId: string;
  location: string;
  objective: string;
  createdAt: Timestamp;
  startedAt: Timestamp;
  createdBy: string;
  boardMembers: string[];
  members: string[];
  teamLead: string;
  guide: string;
  status: string;
  sentPlan: boolean;
};

export type AuditGuideModel = {
  uid: string;
  scope: string;
  number: string;
  guidanceDate: string;
  guidanceStart: string;
  guidanceEnd: string;
  attachmentUrl: string;
  createdAt: Timestamp;
  createdBy: string;
};

export enum AuditStatus {
  created = 'created',
  started = 'started',
  sent = 'sent',
  sentToBoard = 'sentToBoard',
  rejected = 'rejected',
  finished = 'finished',
}

export enum AuditCommentStatus {
  rejected = 'rejected',
  accepted = 'accepted',
}

export type AuditForm = {
  uid: string;
  question: string;
  groupId: number;
  evaluation?: string;
  mismatch?: string;
};

export type AuditPlan = {
  uid: string;
  date: string;
  startHour: string;
  endHour?: string;
  name?: string;
  location?: string;
  auditor?: string;
};

export enum ContractType {
  amlaltUurgiinGeree = 'AUG',
  batalgaajuulaltiinGeree = 'BG',
}

export type UserLogModel = {
  text: string;
  createdAt: Timestamp;
  createdBy: string;
};
