import {ColumnsType} from 'antd/es/table';
import {
  AuditForm,
  AuditModel,
  AuditStatus,
  AuditorModel,
  AuthRoles,
  OtpEmailModel,
  UserLogModel,
} from '../types';
import {Button, Image} from 'antd';
import {auditorAuditTypes} from '../constants/app_data';
import {auditStatusTag, verifiedTag} from './theme_helper';
import {Routes} from '../routes/route_helper';
import {Link} from 'react-router-dom';
import {Timestamp} from 'firebase/firestore';
import {convertDateTimeFromTimestamp} from './utils';
import {LinkOutlined} from '@ant-design/icons';

export const tcAuditEvaluationColumns: ColumnsType<AuditForm> = [
  {
    key: 'question',
    title: 'Асуулт',
    dataIndex: 'question',
  },
  {
    key: 'evaluationVal',
    title: 'Үнэлгээ',
    dataIndex: 'evaluation',
  },
  {
    key: 'mismatchVal',
    title: 'Үл тохирол',
    dataIndex: 'mismatch',
  },
  {
    key: 'evidence',
    title: 'Нотолгоо',
    dataIndex: 'evidence',
    render: (data: any) => (
      <div>
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) =>
              console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {data.images.map((i: any) => (
            <Image key={i} height={80} src={i} />
          ))}
        </Image.PreviewGroup>
        <div className="flex gap-2 mt-2 flex-wrap">
          {data.files.map((f: any) => (
            <Link key={f} to={f} target="_blank">
              <Button icon={<LinkOutlined />} />
            </Link>
          ))}
        </div>
      </div>
    ),
  },
  {
    key: 'desc',
    title: 'Тайлбар',
    dataIndex: 'desc',
  },
];

export const tcAuditListBoard: ColumnsType<AuditModel> = [
  {
    key: 'companyName',
    title: 'Компани',
    dataIndex: 'companyName',
    render: (_, record) => (
      <>{record.type === 'local' ? record.companyName : record.farmerName}</>
    ),
  },
  {
    key: 'auditNumber',
    title: 'Аудитын дугаар',
    dataIndex: 'auditNumber',
  },
  {
    key: 'auditType',
    title: 'Аудитын төрөл',
    dataIndex: 'auditType',
    render: (_, record) => (
      <>{auditorAuditTypes.find((a) => a.value === record.auditType)?.name}</>
    ),
  },
  {
    key: 'status',
    title: 'Статус',
    dataIndex: 'status',
    render: (_, record) => <>{auditStatusTag(record.status, record.type)}</>,
  },
  {
    key: 'boardSentAt',
    title: 'Илгээсэн',
    dataIndex: 'boardSentAt',
    render: (boardSentAt: Timestamp) =>
      convertDateTimeFromTimestamp(boardSentAt),
  },
  {
    key: 'auditReport',
    title: '',
    dataIndex: 'auditReport',
    render: (_, record) => (
      <>
        {record.status !== AuditStatus.created ? (
          <Link
            target="_blank"
            to={Routes.compilePath(Routes.auditReport, {id: record.uid})}
          >
            <Button
              icon={<i className="bx bx-detail"></i>}
              size="small"
              type="primary"
              className="bg-lightBlue"
            />
          </Link>
        ) : (
          <></>
        )}
      </>
    ),
  },
];

export const tcOtp: ColumnsType<OtpEmailModel> = [
  {
    key: 'email',
    title: 'Имейл',
    dataIndex: 'email',
  },
  {
    key: 'otpCode',
    title: 'OTP код',
    dataIndex: 'otpCode',
  },
  {
    key: 'isVerified',
    title: 'Баталгаажсан',
    dataIndex: 'isVerified',
    render: (isVerified: boolean) => verifiedTag(isVerified),
  },
  {
    key: 'createdAt',
    title: 'Үүсгэсэн огноо',
    dataIndex: 'createdAt',
    render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
  },
];

export const tcAuditorList: ColumnsType<AuditorModel> = [
  {
    key: 'code',
    title: 'Код',
    dataIndex: 'code',
  },
  {
    key: 'name',
    title: 'Нэр',
    dataIndex: 'name',
  },
  {
    key: 'email',
    title: 'Имейл',
    dataIndex: 'email',
  },
  {
    key: 'role',
    title: 'Эрх',
    dataIndex: 'role',
    render: (role: string) => {
      if (role === AuthRoles.AUDIT_ADMIN) return 'Админ';
      else if (role === AuthRoles.AUDIT_AUDITOR) return 'Аудитор';
      else if (role === AuthRoles.AUDIT_CONTRACTOR)
        return 'Баталгаажуулалтын ажилтан';
      else return '';
    },
  },
  {
    key: 'createdAt',
    title: 'Үүсгэсэн огноо',
    dataIndex: 'createdAt',
    render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
  },
];

export const tcUserLog: ColumnsType<UserLogModel> = [
  {
    key: 'text',
    title: 'Лог',
    dataIndex: 'text',
  },
  {
    key: 'createdAt',
    title: 'Огноо',
    dataIndex: 'createdAt',
    render: (createdAt: Timestamp) => convertDateTimeFromTimestamp(createdAt),
  },
];
