import {Menu, MenuProps} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {UserContext} from '../../context/app.contexts';
import {Routes} from '../../routes/route_helper';
import {AuthRoles} from '../../types';

type MenuItem = Required<MenuProps>['items'][number];

const auditAdminMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },
  {
    name: 'Баталгаажуулалт',
    path: 'certificate',
    children: [
      {
        name: 'Өргөдлүүд',
        path: Routes.applicationRequests,
      },
      {
        name: 'Амлалт үүргийн гэрээ',
        path: Routes.contracts,
      },
      {
        name: 'Баталгаажуулалтын гэрээ',
        path: Routes.certContracts,
      },
    ],
  },
  {
    name: 'Аудит',
    path: 'audits',
    children: [
      {
        name: 'Илгээсэн',
        path: Routes.auditSent,
      },
    ],
  },
  {
    name: 'Гэрчилгээ',
    path: Routes.certificateList,
  },
  {
    name: 'Фини',
    path: 'finyRequests',
    children: [
      {
        name: 'Хүсэлтүүд',
        path: Routes.finyRequests,
      },
    ],
  },
  {
    name: 'Хэрэглэгчид',
    path: 'users',
    children: [
      {
        name: 'Аудиторууд',
        path: Routes.auditors,
      },
      {
        name: 'Компаниуд',
        path: Routes.companyList,
      },
      {
        name: 'Бүртгэлийн хүсэлтүүд',
        path: Routes.companyRequests,
      },
    ],
  },
  {
    name: 'Тохиргоо',
    path: 'variables',
    children: [
      {
        name: 'OTP',
        path: Routes.otp,
      },
      {
        name: 'Баталгаажуулалтын төрлүүд',
        path: Routes.standarts,
      },
    ],
  },
];

const auditAuditorMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },
  {
    name: 'Баталгаажуулалт',
    path: 'certificate',
    children: [
      {
        name: 'Өргөдлүүд',
        path: Routes.applicationRequests,
      },
      {
        name: 'Амлалт үүргийн гэрээ',
        path: Routes.contracts,
      },
      {
        name: 'Баталгаажуулалтын гэрээ',
        path: Routes.certContracts,
      },
    ],
  },
  {
    name: 'Аудит',
    path: 'audits',
    children: [
      {
        name: 'Зөвлөлд илгээсэн',
        path: Routes.auditBoard,
      },
    ],
  },
];

const auditContractorMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },
  {
    name: 'Фини',
    path: 'finyRequests',
    children: [
      {
        name: 'Хүсэлтүүд',
        path: Routes.finyContractorRequests,
      },
    ],
  },
];

const companyMenuData = [
  {
    name: 'Нүүр',
    path: Routes.dashboard,
  },
  {
    name: 'Баталгаажуулалт',
    path: 'certificate',
    children: [
      {
        name: 'Өргөдлүүд',
        path: Routes.applicationRequests,
      },
    ],
  },
  {
    name: 'Гэрчилгээ',
    path: Routes.certificateList,
  },
];

const SidebarContent = () => {
  const location = useLocation();
  const {userRole} = useContext(UserContext);
  const [menuItems, setMenuItems] = useState([] as MenuItem[]);
  const [rootSubmenuKeys, setRootSubMenuKeys] = useState([] as string[]);
  const [openKeys, setOpenKeys] = useState([] as string[]);
  const [openGroupKeys, setOpenGroupKeys] = useState([] as string[]);

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  useEffect(() => {
    let data = [] as any[];
    if (userRole === AuthRoles.AUDIT_ADMIN) {
      data = auditAdminMenuData;
    } else if (userRole === AuthRoles.AUDIT_AUDITOR) {
      data = auditAuditorMenuData;
    } else if (userRole === AuthRoles.AUDIT_CONTRACTOR) {
      data = auditContractorMenuData;
    } else if (userRole === AuthRoles.USER_COMPANY) {
      data = companyMenuData;
    }
    setMenuItems(
      data.map((m) =>
        getItem(
          m.children != null ? m.name : <Link to={m.path}>{m.name}</Link>,
          m.path,
          null,
          m.children?.map((c: any) =>
            getItem(<Link to={c.path}>{c.name}</Link>, c.path),
          ),
        ),
      ),
    );
    setRootSubMenuKeys(
      data.filter((m) => m.children != null).map((m) => m.path),
    );
  }, [userRole]);

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.length > 0 ? keys[keys.length - 1] : '';
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenGroupKeys(keys);
    } else {
      setOpenGroupKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    setOpenKeys([location.pathname.toString()]);
    menuItems.forEach((m: any) => {
      var children = m.children?.map((c: any) => c.key);
      if (children?.includes(location.pathname)) {
        setOpenGroupKeys([m.key]);
      }
    });
  }, [location.pathname, menuItems]);

  return (
    <Menu
      mode="inline"
      selectedKeys={openKeys}
      openKeys={openGroupKeys}
      onOpenChange={onOpenChange}
      style={{width: 250}}
      items={menuItems}
    />
  );
};

export default SidebarContent;
