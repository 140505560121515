import {AuthRoles} from '../types';

export const isAllowedRole = (role: AuthRoles) => {
  return [
    AuthRoles.AUDIT_ADMIN,
    AuthRoles.AUDIT_AUDITOR,
    AuthRoles.AUDIT_CONTRACTOR,
    AuthRoles.USER_COMPANY,
  ].includes(role);
};

export const auditRoles = {
  viewAuditDetail: [AuthRoles.AUDIT_ADMIN, AuthRoles.AUDIT_AUDITOR],
  addEditAudit: [AuthRoles.AUDIT_ADMIN],
  addEditAuditGuide: [AuthRoles.AUDIT_ADMIN],
  addEditAuditTeam: [AuthRoles.AUDIT_ADMIN],
  editReport: [AuthRoles.AUDIT_AUDITOR],
  send: [AuthRoles.AUDIT_AUDITOR],
  viewSent: [AuthRoles.AUDIT_ADMIN, AuthRoles.AUDIT_AUDITOR],
  sendToBoard: [AuthRoles.AUDIT_ADMIN],
  viewSentToBoard: [AuthRoles.AUDIT_ADMIN, AuthRoles.AUDIT_AUDITOR],
};

export const auditorRoles = {
  addEditAuditor: [AuthRoles.AUDIT_ADMIN],
};

export const finyRequestRoles = {
  viewRequests: [AuthRoles.AUDIT_ADMIN, AuthRoles.AUDIT_CONTRACTOR],
  assign: [AuthRoles.AUDIT_ADMIN],
};

export const certificateRoles = {
  createEdit: [AuthRoles.AUDIT_ADMIN],
};

export const applicationRoles = {
  addEditRequest: [AuthRoles.USER_COMPANY],
  viewRequest: [
    AuthRoles.AUDIT_ADMIN,
    AuthRoles.AUDIT_AUDITOR,
    AuthRoles.USER_COMPANY,
  ],
  verifyRejectRequest: [AuthRoles.AUDIT_ADMIN],
};

export const companyRoles = {
  verifyRejectRequest: [AuthRoles.AUDIT_ADMIN],
  viewRequest: [AuthRoles.AUDIT_ADMIN],
};

export const contractRoles = {
  assign: [AuthRoles.AUDIT_ADMIN],
  viewRequests: [AuthRoles.AUDIT_ADMIN, AuthRoles.AUDIT_AUDITOR],
};
