import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, message} from 'antd';
import {FromGroup} from '../../types';
import {DeleteOutlined} from '@ant-design/icons';
import {updateData} from '../../helpers/firestore';
import * as _ from 'lodash';

type Props = {
  colName: string;
  docUid?: string | undefined;
  certFormGroups: FromGroup[];
};

const FormGroup: React.FC<Props> = ({certFormGroups, docUid, colName}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [formGroups, setFormGroups] = useState([] as FromGroup[]);

  const addGroups = () => {
    setFormGroups([...formGroups, {id: formGroups.length, name: ''}]);
  };

  const editGroup = (groupId: number, e: any) => {
    setFormGroups(
      formGroups.map((current) =>
        current.id === groupId ? {id: groupId, name: e.target.value} : current,
      ),
    );
  };

  const removeGroup = (groupId: number) => {
    setFormGroups(formGroups.filter((f) => f.id !== groupId));
  };

  const saveGroups = async () => {
    if (_.isEmpty(docUid) || _.isEmpty(formGroups)) {
      messageApi.open({
        type: 'error',
        content: 'Асуулгын бүлгийн мэдээлэл хадгалахад алдаа гарлаа.',
      });
      return;
    }
    setLoadingGroups(true);
    await updateData(`${colName}/${docUid}`, {
      groups: formGroups,
    });
    setLoadingGroups(false);
    messageApi.open({
      type: 'success',
      content: 'Асуулгын бүлгийн мэдээлэл хадгалагдлаа.',
    });
  };

  useEffect(() => {
    setFormGroups(certFormGroups);
  }, [certFormGroups]);

  return (
    <>
      {contextHolder}
      <div className="flex items-center justify-between">
        <div className="my-4 font-medium text-info">
          Асуулгын бүлгүүд ({formGroups.length})
        </div>
        <Button key="submit" size="small" shape="round" onClick={addGroups}>
          Нэмэх
        </Button>
      </div>
      <Row gutter={[16, 0]}>
        {formGroups.map((g) => (
          <Col key={`cert-form-group-${g.id}`} xs={24} md={12} lg={8}>
            <Input
              value={g.name}
              addonAfter={
                <DeleteOutlined
                  className="mx-2"
                  onClick={() => removeGroup(g.id)}
                />
              }
              onChange={(e) => editGroup(g.id, e)}
              placeholder="Нэр"
              className="mb-3"
            />
          </Col>
        ))}
      </Row>
      <Button
        key="save"
        shape="round"
        type="primary"
        className="w-48 my-5"
        loading={loadingGroups}
        disabled={_.isEmpty(docUid)}
        onClick={saveGroups}
      >
        Хадгалах
      </Button>
    </>
  );
};

export default FormGroup;
