import {Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import * as _ from 'lodash';
import {convertDateContract} from '../../helpers/utils';

const ContractDocument = ({download = false, contract = {}}) => {
  const styles = StyleSheet.create({
    page: {
      width: '100vw',
      height: '100vh',
      fontSize: 10,
      fontFamily: 'Roboto',
      flexDirection: 'column',
      display: 'flex',
      color: '#111811',
    },
  });

  return (
    <Page size="A4" style={styles.page}>
      {download && (
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: 20,
          }}
        ></View>
      )}
      <View
        style={{
          paddingHorizontal: 28,
          paddingVertical: 12,
          width: '100%',
        }}
      >
        {!download && (
          <View
            fixed
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              height: 20,
            }}
          ></View>
        )}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Image src="/sfcs.png" style={{height: 60}} />
          <View
            style={{
              display: 'flex',
              marginLeft: 20,
            }}
          >
            <Text style={{fontSize: 8}}>
              “ЭС ЭФ СИ ЭС” ХХК- ИЙН ГҮЙЦЭТГЭХ ЗАХИРАЛ
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{fontSize: 8, marginLeft: 2, marginTop: 10}}>
                Г. УНДАРЪЯА
              </Text>
              <Image
                src="https://cdn.finy.app/AUG%20stamp.png"
                style={{width: 80}}
              />
            </View>
          </View>
        </View>
        <Text
          style={{
            maxWidth: '50%',
            textAlign: 'center',
            marginTop: 20,
            marginHorizontal: 'auto',
            fontSize: 12,
          }}
        >
          ХӨДӨӨ АЖ АХУЙН ЗОХИСТОЙ ДАДЛЫГ (GAP) ХЭРЭГЖҮҮЛЭХ ТУХАЙ ГЭРЭЭ
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 30,
          }}
        >
          <Text style={{fontSize: 8}}>
            {convertDateContract(contract.signedAt)}
          </Text>
          <Text style={{fontSize: 8}}>Дугаар {contract.contractNumber}</Text>
          <Text style={{fontSize: 8, marginLeft: 16}}>Улаанбаатар хот</Text>
        </View>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          1.ЕРӨНХИЙ ЗҮЙЛ
        </Text>
        <Text style={{marginTop: 10}}>
          1.1. Нэг талаас Эс Эф Си Эс ХХК (цаашид “Дэмжигч буюу бойжуулагч
          байгууллага” гэх), нөгөө талаас тариалан эрхлэгч (цаашид “Өрхийн
          тариаланч” гэх) дараах зүйлийг харилцан тохиролцож 
          {convertDateContract(contract.signedAt)} энэхүү ГЭРЭЭ (цаашид “Гэрээ”
          гэх)-г байгуулав.
        </Text>
        <Text style={{marginTop: 10}}>
          1.2. Өрхийн тариаланч нь “Хөдөө аж ахуйн зохистой дадал (ХААЗД)
          хэрэгжүүлэх удирдамж” MNS 6737:2018 стандарт, ХХААХҮ-ийн сайд, Эрүүл
          мэндийн сайдын хамтарсан 2019 оны А/166, A/251 “Жимс, жимсгэнэ,
          Хүнсний ногооны үйлдвэрлэлд ХААЗД-ыг хялбаршуулан нэвтрүүлэх
          заавар”-ын дагуу ил талбайн хүнсний ногоо, хүлэмжийн тариалалт,
          хураалт, хадгалалт, тээвэрлэлтийн үйл ажиллагаанд баталгаажуулалтын
          байгууллагаар баталгаажуулалт хийлгүүлэх үүрэг амлалтыг талууд бодитой
          хэрэгжүүлэхэд оршино.
        </Text>
        <Text style={{marginTop: 10}}>
          1.3. Талууд энэхүү гэрээний дагуу үйл ажиллагаа явуулахдаа бие
          биенийхээ эрхийг хүндэтгэн, шударга байдлаар хандах ба гэрээний
          зорилгын хэрэгжилтийг хангахын тулд бүхий л зохистой арга хэмжээг
          авна. 
        </Text>
        <Text style={{marginTop: 10}}>
          1.4. Талууд гэрээний хэрэгжилтийн талаар сонирхогч талуудад тухай бүр
          мэдээлэл хүргүүлнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          1.5. Талууд энэхүү гэрээний 1.2 дахь хэсэгт заасан баталгаажуулалтад
          хамрагдах амлалт үүргийг энэхүү гэрээг байгуулсан өдрөөс хойш 3 сарын
          дотор эхлүүлсэн байна.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          2.БУСАД ЗҮЙЛ
        </Text>
        <Text style={{marginTop: 10}}>
          2.1. Гэнэтийн болон давтагдашгүй хүчний нөхцөл байдал үүссэн
          тохиолдолд талууд тохиролцон гэрээг хэрэгжүүлэх боломжит арга замыг
          хайна.
        </Text>
        <Text style={{marginTop: 10}}>
          2.2. Гэрээтэй холбоотой аливаа маргааныг талууд харилцан тохиролцож,
          эв зүйгээр шийдвэрлэхийг эрмэлзэнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          2.3. Энэхүү гэрээний 1.2-т заасан амлалт үүрэг бодитой хэрэгжсэн гэж
          талууд дүгнэсэн тохиолдолд гэрээг дуусгавар болсонд тооцно.
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            gap: 10,
          }}
        >
          <View style={{flex: 1}}>
            <Text style={{fontWeight: 'bold'}}>
              ДЭМЖИГЧ БАЙГУУЛЛАГЫГ ТӨЛӨӨЛЖ:
            </Text>
            <Text style={{marginTop: 20}}>
              Баталгаажуулалтын төвийн захирал:
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 5,
              }}
            >
              <Text>Н.Нарангэрэл</Text>
              <Image
                src="https://cdn.finy.app/files%2F1661006367591_small.jpg"
                style={{
                  height: 30,
                  width: 30,
                  objectFit: 'cover',
                  marginLeft: 20,
                }}
              />
            </View>
            <Text style={{marginTop: 10}}>
              Утас: 94999347, 94005015, 99845867
            </Text>
            <Text style={{marginTop: 5}}>
              И-мэйл: sfscsertification1@gmail.com
            </Text>
            <Text style={{marginTop: 5}}>Хаяг: Баянгол дүүрэг, 2 хороо,</Text>
            <Text>2 хороолол, 63 Б байр, 1 тоот</Text>
          </View>
          <View style={{flex: 1}}>
            <Text style={{fontWeight: 'bold'}}>
              {contract.userType === 'citizen'
                ? 'ӨРХИЙН ТАРИАЛАНЧЫГ'
                : `${_.get(
                    contract,
                    'farmerData.name',
                    '',
                  ).toUpperCase()}-ИЙГ`}{' '}
              ТӨЛӨӨЛЖ:
            </Text>
            <Text style={{marginTop: 20}}>
              {contract.userType === 'citizen' ? 'Иргэн' : 'Менежер'}:
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 5,
              }}
            >
              <Text>
                {contract.userType === 'citizen'
                  ? _.get(contract, 'farmerData.name', '')
                  : _.get(contract, 'farmerData.manager', '')}
              </Text>
              <Image
                src={contract.farmerSignatureUrl}
                style={{
                  height: 30,
                  width: 30,
                  objectFit: 'cover',
                  marginLeft: 20,
                }}
              />
            </View>
            <Text style={{marginTop: 10}}>
              Утас: {_.get(contract, 'farmerData.phoneNumber', '')}
            </Text>
            {!_.isEmpty(_.get(contract, 'farmerData.email')) && (
              <Text style={{marginTop: 5}}>
                Имейл: {_.get(contract, 'farmerData.email', '')}
              </Text>
            )}
            <Text style={{marginTop: 5}}>
              Регистрийн дугаар: {_.get(contract, 'farmerData.orgRegister', '')}
            </Text>
            <Text style={{marginTop: 5}}>
              Тариалангийн талбайн хаяг:{' '}
              {_.get(contract, 'farmerData.fieldAddress', '')}
            </Text>
          </View>
        </View>
      </View>
      {download && (
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: 40,
          }}
        ></View>
      )}
    </Page>
  );
};

export default ContractDocument;
