import {Button, Form, Input, Select, message} from 'antd';
import {AuditModel} from '../../types';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/app.contexts';
import {updateData} from '../../helpers/firestore';
import {auditRules} from '../../helpers/validations';
import {auditorAuditTypes} from '../../constants/app_data';
import * as _ from 'lodash';
import {auditRoles} from '../../helpers/role_helper';

type Props = {
  audit: AuditModel;
};

const AuditInfo: React.FC<Props> = ({audit}) => {
  const [form] = Form.useForm();
  const {user, userRole} = useContext(UserContext);
  const [savingAudit, setSavingAudit] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [writePermitted, setWritePermitted] = useState(false);

  const saveAuditData = async (values: any) => {
    if (!writePermitted) {
      messageApi.open({
        type: 'error',
        content: `Та аудитын мэдээлэл өөрчлөх боломжгүй байна`,
      });
      return;
    }
    setSavingAudit(true);
    await updateData(`audits/${audit.uid}`, {
      ...values,
      updatedBy: user.uid,
    });
    setSavingAudit(false);
    messageApi.open({
      type: 'success',
      content: `Аудитын мэдээлэл засагдлаа`,
    });
  };

  useEffect(() => {
    setWritePermitted(auditRoles.addEditAudit.includes(userRole));
  }, [userRole]);

  useEffect(() => {
    if (!_.isEmpty(audit)) {
      form.setFieldsValue(audit);
    }
  }, [audit]);

  return (
    <div className="card-container p-6 h-full">
      <div className="text-xl font-medium mb-5">Аудитын мэдээлэл</div>
      <Form
        form={form}
        onFinish={saveAuditData}
        disabled={!writePermitted}
        scrollToFirstError
      >
        {contextHolder}
        <div className="text-gray text-sm mb-1">Аудитын дугаар</div>
        {!writePermitted ? (
          <div className="mb-6">{audit.auditNumber}</div>
        ) : (
          <Form.Item rules={auditRules.auditNumber} name="auditNumber">
            <Input placeholder="Аудитын дугаар" />
          </Form.Item>
        )}
        <div className="text-gray text-sm mb-1">Аудитын төрөл</div>
        {!writePermitted ? (
          <div className="mb-6">
            {auditorAuditTypes.find((a) => a.value === audit.auditType)?.name}
          </div>
        ) : (
          <Form.Item rules={auditRules.auditType} name="auditType">
            <Select
              allowClear
              className="w-full"
              placeholder="Аудитын төрөл"
              options={auditorAuditTypes.map((a: any) => ({
                label: a.name,
                value: a.value,
              }))}
            />
          </Form.Item>
        )}
        <div className="text-gray text-sm mb-1">Хамрах хүрээ</div>
        {!writePermitted ? (
          <div className="mb-6">{audit.auditScope}</div>
        ) : (
          <Form.Item rules={auditRules.auditScope} name="auditScope">
            <Input.TextArea placeholder="Хамрах хүрээ" />
          </Form.Item>
        )}
        <div className="text-gray text-sm mb-1">Аудитын зорилго</div>
        {!writePermitted ? (
          <div className="mb-6">{audit.objective}</div>
        ) : (
          <Form.Item rules={auditRules.objective} name="objective">
            <Input.TextArea placeholder="Аудитын зорилго" />
          </Form.Item>
        )}
        {writePermitted && (
          <div className="flex justify-center">
            <Button
              loading={savingAudit}
              shape="round"
              type="primary"
              htmlType="submit"
            >
              Хадгалах
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default AuditInfo;
