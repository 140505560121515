import {Spin} from 'antd';
import {useEffect, useState} from 'react';
import {BlobProvider, Document, Font} from '@react-pdf/renderer';
import {Document as DocumentReader, Page as PageReader, pdfjs} from 'react-pdf';
import {useParams} from 'react-router-dom';
import * as _ from 'lodash';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import CertificateDocument from './Certificate';
import {firestore} from '../../firebase';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const CertificateView = () => {
  const {id} = useParams();
  const [loadingData, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getData = async () => {
    const result = await firestore.doc(`grantedCertificates/${id}`).get();
    setData({...result.data(), uid: result.id});
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    fontWeight: 'normal',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
    fontWeight: 'medium',
  });

  Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 'bold',
  });

  return (
    <div className="flex justify-center w-screen">
      <Spin spinning={loadingData}>
        {!_.isEmpty(data) && (
          <BlobProvider
            document={
              <Document>
                <CertificateDocument certificate={data} />
              </Document>
            }
          >
            {({blob, url, loading}) => {
              return loading ? (
                <div className="mt-10">
                  <Spin />
                </div>
              ) : (
                <DocumentReader file={url} loading={<></>}>
                  <PageReader
                    loading={<></>}
                    renderTextLayer={false}
                    pageNumber={1}
                    width={Math.min(900, window.innerWidth)}
                  />
                </DocumentReader>
              );
            }}
          </BlobProvider>
        )}
      </Spin>
    </div>
  );
};

export default CertificateView;
