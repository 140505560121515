import {Button, Col, Row, Spin, Tabs} from 'antd';
import {useNavigate, useParams} from 'react-router-dom';
import {Routes} from '../../routes/route_helper';
import * as _ from 'lodash';
import {useDoc, useCollection} from '../../helpers/firestore';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {CertificateModel} from '../../types';
import FormGroup from './FormGroup';
import FormQuestion from './FromQuestion';
import ApplicationDocuments from './ApplicationDocuments';
import ApplicationScope from './ApplicationScope';

const StandartDetail = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [applicationData, applicationLoading] = useDoc(
    `applications/${id ?? 0}`,
  );
  const [applicationQuestionsData] = useCollection(
    `applications/${id ?? 0}/form`,
    'createdAt',
  );
  const [documents] = useCollection(
    `applications/${id ?? 0}/documents`,
    'createdAt',
  );
  const [scope] = useCollection(`applications/${id ?? 0}/scope`, 'createdAt');
  const goBack = () => navigate(Routes.standarts);

  return (
    <div className="pb-16">
      <div className="flex items-center gap-x-4 mb-5">
        <Button
          shape="round"
          type="text"
          onClick={goBack}
          icon={<ArrowLeftOutlined />}
        >
          Баталгаажуулалтын мэдээлэл
        </Button>
      </div>
      <hr />
      <Spin spinning={applicationLoading && !_.isEmpty(id)}>
        <div className="card-container p-6 mt-8">
          <Tabs
            defaultActiveKey="1"
            tabPosition={'top'}
            items={[
              {
                label: `Шалгуур үзүүлэлтүүд`,
                key: '1',
                children: (
                  <div>
                    <FormGroup
                      docUid={id}
                      colName="applications"
                      certFormGroups={
                        (applicationData as CertificateModel).groups ?? []
                      }
                    />
                    <div className="bg-surface mt-3 mb-8 h-[1px]"></div>
                    <FormQuestion
                      docUid={id}
                      colName="applications"
                      groups={
                        (applicationData as CertificateModel).groups ?? []
                      }
                      certFormQuestions={applicationQuestionsData}
                    />
                  </div>
                ),
              },
              {
                label: `Хавсаргах баримт бичиг`,
                key: '2',
                children: (
                  <ApplicationDocuments docUid={id} documents={documents} />
                ),
              },
              {
                label: `Хамрах хүрээ`,
                key: '3',
                children: <ApplicationScope docUid={id} scope={scope} />,
              },
            ]}
          />
        </div>
      </Spin>
    </div>
  );
};

export default StandartDetail;
