import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, message} from 'antd';
import {ApplicationScope} from '../../types';
import {removeData, saveData, updateData} from '../../helpers/firestore';
import * as _ from 'lodash';

type Props = {
  docUid?: string | undefined;
  scope: ApplicationScope[];
};

const StandartScopeContainer: React.FC<Props> = ({scope, docUid}) => {
  const addScope = async () => {
    if (_.isEmpty(docUid)) return;
    await saveData(`applications/${docUid ?? 0}/scope`, undefined, {}, true);
  };

  return (
    <>
      <div className="mb-4 mt-4 font-medium text-info flex gap-2 justify-between items-center">
        Хамрах хүрээ ({scope.length})
        <Button key="submit" size="small" shape="round" onClick={addScope}>
          Нэмэх
        </Button>
      </div>
      <Row gutter={16}>
        {(scope as ApplicationScope[]).map((q) => (
          <Col key={q.uid} xs={24} md={16} lg={8}>
            <Question standartId={docUid} scope={q} />
          </Col>
        ))}
      </Row>
    </>
  );
};

type QuestionProps = {
  standartId?: string | undefined;
  scope: ApplicationScope;
};

const Question: React.FC<QuestionProps> = ({standartId, scope}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState('');

  const validate = () => !_.isEmpty(name);

  const editQuestion = async () => {
    if (!validate()) return;
    if (_.isEmpty(standartId) || _.isEmpty(scope.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Мэдээлэл хадгалахад алдаа гарлаа',
      });
      return;
    }
    setLoading(true);
    await updateData(`applications/${standartId}/scope/${scope.uid}`, {
      name,
    } as ApplicationScope);
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'success',
      content: 'Мэдээлэл хадгалагдлаа',
    });
  };

  const removeQuestion = async () => {
    if (_.isEmpty(standartId) || _.isEmpty(scope.uid)) {
      messageApi.open({
        type: 'error',
        content: 'Мэдээлэл устгахад алдаа гарлаа',
      });
      return;
    }
    setLoading(true);
    await removeData(`applications/${standartId}/scope/${scope.uid}`);
    setLoading(false);
    setExpanded(false);
    messageApi.open({
      type: 'warning',
      content: 'Мэдээлэл устгагдлаа',
    });
  };

  useEffect(() => {
    if (!_.isEmpty(scope)) {
      setName(scope.name ?? '');
    }
  }, [scope]);

  return (
    <div
      key={`cert-form-question-${scope.uid}`}
      className="border border-surface rounded-sm p-4 border-solid bg-lightGray mb-3"
    >
      {contextHolder}
      {expanded || _.isEmpty(scope.name) ? (
        <>
          <div className="flex items-center justify-between mb-4 gap-3">
            <div className="font-medium">Хамрах хүрээ</div>
            {!_.isEmpty(scope.name) && (
              <div
                className="cursor-pointer text-info text-sm"
                onClick={() => setExpanded(false)}
              >
                Хураах
              </div>
            )}
          </div>
          <Input.TextArea
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-3"
          />
          <div className="flex justify-center gap-3 mt-2">
            <Button
              key="delete"
              size="small"
              shape="round"
              disabled={_.isEmpty(scope.uid) || loading}
              onClick={removeQuestion}
              danger
            >
              Устгах
            </Button>
            <Button
              key="save"
              size="small"
              shape="round"
              type="primary"
              loading={loading}
              disabled={!validate()}
              onClick={editQuestion}
            >
              Хадгалах
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center gap-3">
            <div>{name}</div>
            <div
              className="cursor-pointer text-info text-sm"
              onClick={() => setExpanded(true)}
            >
              Засах
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StandartScopeContainer;
