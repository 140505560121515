import {Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import {convertDateFromFunction} from '../../helpers/utils';

const AuditHistoryDocument = ({data}) => {
  const styles = StyleSheet.create({
    page: {
      width: '100vw',
      fontSize: 11,
      padding: 24,
      fontFamily: 'Roboto',
    },
    title: {
      fontSize: 16,
      flexDirection: 'column',
    },
    borderView: {
      padding: 12,
      border: '1px solid #A0A7A0',
      flexDirection: 'column',
      marginBottom: 16,
    },
  });
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.borderView}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 6,
          }}
        >
          <Text>Байгууллагын мэдээлэл:</Text>
          <Text style={{fontWeight: 'medium'}}>{data.userName}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 6,
          }}
        >
          <Text>Холбоо барих:</Text>
          <Text style={{fontWeight: 'medium'}}>{data.phone}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 10,
          }}
        >
          <Text>Хаяг:</Text>
          <Text style={{fontWeight: 'medium', flex: 1, textAlign: 'right'}}>
            {data.companyAddress}
          </Text>
        </View>
        <View
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Image src={data.qrCode} style={{width: 100, height: 100}} />
        </View>
      </View>
      <View style={styles.borderView}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 6,
          }}
        >
          <Text>Баталгаажуулалтын байгууллага:</Text>
          <Text style={{fontWeight: 'medium'}}>{data.auditCompanyName}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>Холбоо барих:</Text>
          <Text style={{fontWeight: 'medium'}}>{data.contactNumber}</Text>
        </View>
        <View
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Image src="/mnas.png" style={{height: 70}} />
          <Image src="/sfcs.png" style={{height: 70}} />
        </View>
      </View>
      {data.requests?.map((r, i) => (
        <View wrap={false} key={`request-${i}`} style={styles.borderView}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 6,
            }}
          >
            <Text>Баталгаажуулалтын төрөл:</Text>
            <Text style={{fontWeight: 'medium'}}>{r.certificateName}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 6,
              paddingBottom: 12,
              borderBottom: '1px solid #EFF0EF',
            }}
          >
            <Text>Аудитын төрөл:</Text>
            <Text style={{fontWeight: 'bold'}}>{r.auditType}</Text>
          </View>
          <View>
            <Text style={{textAlign: 'right', fontSize: 10}}>Огноо</Text>
          </View>
          {r.history?.map((h, i) => (
            <View
              key={`history-${r}-${i}`}
              style={{
                marginTop: 12,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text style={{width: 20}}>{h.id}.</Text>
              <Text style={{flex: 1}}>{h.name}</Text>
              <Text style={{width: 100, textAlign: 'right'}}>
                {convertDateFromFunction(h.date)}
              </Text>
            </View>
          ))}
        </View>
      ))}
    </Page>
  );
};

export default AuditHistoryDocument;
