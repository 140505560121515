import {Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import * as _ from 'lodash';
import {convertDateContract} from '../../helpers/utils';

const CertContractDocument = ({download = false, contract = {}}) => {
  const styles = StyleSheet.create({
    page: {
      width: '100vw',
      height: '100vh',
      fontSize: 10,
      fontFamily: 'Roboto',
      flexDirection: 'column',
      display: 'flex',
      color: '#111811',
    },
  });

  return (
    <Page size="A4" style={styles.page}>
      {download && (
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: 30,
          }}
        ></View>
      )}
      <View
        style={{
          paddingHorizontal: 28,
          paddingVertical: 20,
          width: '100%',
        }}
      >
        {!download && (
          <View
            fixed
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              height: 20,
            }}
          ></View>
        )}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Image src="/sfcs.png" style={{height: 60}} />
          <View
            style={{
              display: 'flex',
              marginLeft: 20,
            }}
          >
            <Text style={{fontSize: 8}}>
              “ЭС ЭФ СИ ЭС” ХХК- ИЙН ГҮЙЦЭТГЭХ ЗАХИРАЛ
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{fontSize: 8, marginLeft: 2, marginTop: 10}}>
                Г. УНДАРЪЯА
              </Text>
              <Image
                src="https://cdn.finy.app/AUG%20stamp.png"
                style={{width: 80}}
              />
            </View>
          </View>
        </View>
        <Text
          style={{
            maxWidth: '50%',
            textAlign: 'center',
            marginTop: 20,
            marginHorizontal: 'auto',
            fontSize: 12,
          }}
        >
          ХӨДӨӨ АЖ АХУЙН ЗОХИСТОЙ ДАДЛЫГ (GAP) БАТАЛГААЖУУЛАЛТЫН АЖИЛ ГҮЙЦЭТГЭХ
          ГЭРЭЭ
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 30,
          }}
        >
          <Text style={{fontSize: 8}}>
            {convertDateContract(contract.signedAt)}
          </Text>
          <Text style={{fontSize: 8}}>Дугаар {contract.contractNumber}</Text>
          <Text style={{fontSize: 8, marginLeft: 16}}>Улаанбаатар хот</Text>
        </View>
        <Text
          style={{
            marginTop: 30,
            width: '100%',
            textAlign: 'justify',
          }}
        >
          Нэг талаас Эс Эф Си Эс ХХ (цаашид "Баталгаажуулалтын байгууллага"
          гэх), нөгөө талаас Өрхийн тариаланч (цаашид "Үйлчлүүлэгч" гэх) дараах
          зүйлийг харилцан тохиролцож {convertDateContract(contract.signedAt)}{' '}
          энэхүү ГЭРЭЭ (цаашид "Гэрээ" гэх)-г “Хөдөө аж ахуйн зохистой дадал
          (ХААЗД) хэрэгжүүлэх удирдамж” MNS 6737 : 2018 стандарт, ХХААХҮ-ийн
          сайд, Эрүүл мэндийн сайдын хамтарсан 2019 оны А/166,A/251 “Жимс,
          жимсгэнэ, Хүнсний ногооны үйлдвэрлэлд ХААЗД-ыг хялбаршуулан нэвтрүүлэх
          заавар”-ын дагуу хүнсний ногооны (хүлэмжийн) (тариалангийн, мал) аж
          ахуйд аудит хийж, баталгаажуулах ажлыг гүйцэтгэхээр байгуулав.
        </Text>
        <Text
          style={{
            marginTop: 30,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          1.ЕРӨНХИЙ ЗҮЙЛ
        </Text>
        <Text style={{marginTop: 10}}>
          1.1. Баталгаажуулалтын байгууллага нь үндэсний болон олон улсын
          байгууллагаас баталсан дүрэм, журам, стандартын хүрээнд
          баталгаажуулалт хийж, гэрчилгээ олгоно.
        </Text>
        <Text style={{marginTop: 10}}>
          1.2. Баталгаажуулалтын байгууллага нь энэхүү гэрээнд заасан
          бүтээгдэхүүний баталгаажуулалтын үйлчилгээ (цаашид "Баталгаажуулалт"
          гэх)-г үзүүлэх үүрэгтэй.
        </Text>
        <Text style={{marginTop: 10}}>
          1.3. Баталгаажуулалтын байгууллага нь мэргэжлийн, дадлага туршлагатай,
          эрх бүхий гэрчилгээтэй мэргэжилтнээр үнэлгээг гүйцэтгүүлнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          1.4. Гэрээ нь энд дурдсан Баталгаажуулалтыг гүйцэтгэх талаарх талуудын
          хоорондын эцсийн тохиролцоо болох ба урьд өмнө үйлдсэн бүх хэлцлийг
          орлоно.
        </Text>
        <Text style={{marginTop: 10}}>
          1.5. Гэрээнд холбогдох асуудлаар талууд цахим шуудангаар, албан
          бичгээр болон утсан холбоогоор харилцана.
        </Text>
        <Text style={{marginTop: 10}}>
          1.5. Гэрээний заалтуудад, тодотгол, өөрчлөлт хийх тохиолдолд нэмэлт
          гэрээ байгуулж болно.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          2. ГЭРЭЭ ЭХЛЭХ, ДУУСГАВАР БОЛГОХ, ГЭРЭЭНД ӨӨРЧЛӨЛТ ОРУУЛАХ
        </Text>
        <Text style={{marginTop: 10}}>
          2.1. Энэхүү гэрээ нь талууд гарын үсэг зурсан өдрөөс эхлэн хүчин
          төгөлдөр болно.
        </Text>
        <Text style={{marginTop: 10}}>
          2.2. Баталгаажуулалтын байгууллага нь баталгаажуулалтын ажлын
          бүрэлдэхүүнд багтсан ажлыг Үйлчлүүлэгчтэй зөвшилцсөн төлөвлөгөө,
          хуваарийн дагуу хугацаанд нь гүйцэтгэнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          2.3. Баталгаажуулалтын байгууллага нь баталгаажуулалтын багц ажлын
          бүрэлдэхүүнд дараах ажлуудыг гүйцэтгэнэ. Баталгаажуулалтын өргөдлийг
          хүлээн авч, энэхүү гэрээний 5.2 дахь заалтыг хэрэгжүүлсний дараа
          үнэлгээг хийх, баталгаажуулалтын ажлын бүрэлдэхүүнд багтах магадлах
          хяналтыг дараах хуваарийн дагуу хийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          2.4. Гэрчилгээний хүчинтэй хугацаа дуусахаас 2 сарын өмнө цаашид
          сунгуулах тухай хүсэлтийг Үйлчлүүлэгч талаас Баталгаажуулалтын
          байгууллагад ирүүлнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          2.5. Гэрээний 6.1-д зааснаас бусад тохиолдолдгэрээнд заасан ажлыг
          гүйцэтгэж дууссанаар энэхүү гэрээний хугацаа дуусгавар болно.
        </Text>
        <Text style={{marginTop: 10}}>
          2.6. Гэрээнд өөрчлөлт оруулах тохиолдолд талууд харилцан тохиролцож,
          бичгээр үйлдэнэ.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          3. БАТАЛГААЖУУЛАЛТЫН БАЙГУУЛЛАГЫН ЭРХ, ҮҮРЭГ
        </Text>
        <Text style={{marginTop: 10}}>
          3.1. Баталгаажуулалтын байгууллага нь ажлыг гэрээнд заасан бүх нөхцөл,
          шаардлагад нийцүүлэн гүйцэтгэж, холбогдох стандарт, эрх зүйн баримт
          бичгийн шаардлагад нийцэж байгаа эсэхэд дүгнэлт гаргах, гэрчилгээ
          олгох, эсхүл эс олгох, мөн түүнийг түдгэлзүүлэх, хүчингүй болгох
          талаар шийдвэр гаргах бөгөөд энэ тухай мэдээллийг тариалангийн асуудал
          хариуцсан төрийн захиргааны төв байгууллагад хүргүүлнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          3.2. Баталгаажуулалтын байгууллага нь ажлыг гэрээнд заасан бүх нөхцөл,
          шаардлагад нийцүүлэн гүйцэтгэж, баталгаажуулалтын шалгуурт нийцэж
          байгаа байдалд дүгнэлт хийж, гэрчилгээ олгох эсвэл эс олгох талаар
          шийдвэр гаргана.
        </Text>
        <Text style={{marginTop: 10}}>
          3.3. Баталгаажуулалтын байгууллага нь гэрээнд заасан аливаа асуудлаар
          ямагт Үйлчлүүлэгчийн талд ажиллах бөгөөд гуравдагч этгээдтэй хийх
          аливаа харилцаанд Үйлчлүүлэгчийн хууль ёсны ашиг сонирхлыг хамгаална.
        </Text>
        <Text style={{marginTop: 10}}>
          3.4. Баталгаажуулалтын байгууллага нь гэрээний дагуу гүйцэтгэвэл зохих
          үүрэг болон үзүүлэх үйлчилгээг нийтээр хүлээн зөвшөөрөгдсөн мэргэжлийн
          болон бодит жишигт нийцүүлэн үр ашигтай, хэмнэлттэй байдлаар гүйцэтгэх
          бөгөөд үр дүнтэй болон зүй зохист арга зүйг ашиглан үйл ажиллагааны
          шуурхай удирдлагыг ханган ажиллана.
        </Text>
        <Text style={{marginTop: 10}}>
          3.5. Баталгаажуулалтын байгууллага нь ирээдүйд хийх ажилтай холбоотой
          ямар нэгэн зүйлийг үл харгалзан Үйлчлүүлэгчийн ашиг сонирхлыг эн
          тэргүүнд тавих бөгөөд өөр төсөл арга хэмжээ буюу өөрийн байгууллагын
          эрх ашигтай, сонирхолын зөрчилд орохоос зайлсхийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          3.6. Баталгаажуулалтын байгууллага, түүний ажилтнууд нь Үйлчлүүлэгчээс
          урьдчилан зөвшөөрөл авалгүйгээр гэрээний хэрэгжилтийн явцад олж авсан
          аливаа нууц мэдээллийг ямар нэгэн хувь хүн, байгууллагад дамжуулах
          эрхгүй бөгөөд үйлчилгээний үр дүнд боловсруулсан зөвлөмжийг нийтэд
          нээлттэй болгох эрхгүй.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          4. ҮЙЛЧЛҮҮЛЭГЧИЙН ЭРХ, ҮҮРЭГ
        </Text>
        <Text style={{marginTop: 10}}>
          4.1. Үйлчлүүлэгч нь Баталгаажуулалтын байгууллагын маягтын дагуу
          өргөдөл, хүсэлтийг гаргана.
        </Text>
        <Text style={{marginTop: 10}}>
          4.2. Үйлчлүүлэгч нь өөрийн үйл ажиллагаанд хяналт хийж, өөрийн
          үнэлгээний хуудсыг бөглөн баталгаажуулалтын байгууллагад нотлох баримт
          болон шаардсан бусад баримт бичгийн хамт хүргүүлнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          4.3. Үйлчлүүлэгч нь гэрээний дагуу Баталгаажуулалтын байгууллагын хийх
          ажлыг үндэслэн гэрээний төлбөрийг Баталгаажуулалтын байгууллагад
          төлнө.
        </Text>
        <Text style={{marginTop: 10}}>
          4.4. Баталгаажсан үйлчлүүлэгч нь байгууллагын үйл ажилагаанд гарсан
          дараах өөрчлөлтүүдийг баталгаажуулалтын байгууллагад тухай бүр бичгээр
          мэдээлж байна. Үүнд:
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.4.1. хууль зүйн, худалдааны, бүтцийн эрх зүйн байдал болон эзэмшил;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.4.2. зохион байгуулалт ба менежмент (жишээлбэл гол удирдлагын
          ажилтан, шийдвэр гаргагч буюу техникийн ажилтан),
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.4.3. бүтээгдэхүүний буюу үйлдвэрлэлийн аргын сайжруулалт;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.4.4. Холбоо барих хаяг, байршил, үйлдвэрлэлийн хэсэг;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.4.5. Баталгаажсан удирдлагын тогтолцооны ажлын хүрээ;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.4.6. Үйлдвэрлэл ба үйл явцуудад гарсан гол өөрчлөлтүүд зэрэг болно.
        </Text>
        <Text style={{marginTop: 10}}>
          4.5. Баталгаажуулалтын байгууллагын зөвлөмжийн дагуу өөрийн өгсөн
          мэдээлэлд өөрчлөлт оруулах, ажлын чанар, үр дүнд муугаар нөлөөлөх
          бусад нөхцөл байдлыг арилгах талаар өөрөөс шалтгаалах арга хэмжээ авах
          үүрэгтэй ба түүнээс учирсан аливаа доголдол, хохиролыг өөрийн зардлаар
          хариуцна.
        </Text>
        <Text style={{marginTop: 10}}>
          4.6. Байгууллагын ажилтнуудад Баталгаажуулалтынталаар мэдээлэл хүргэх,
          ярилцлага хийх нөхцөл бололцоогоор хангаж ажлын зохицуулалт хийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          4.7. Үнэлгээ хийх явцад өрөө танхим, техник хэрэгслийн бэлэн байдлыг
          хангана.
        </Text>
        <Text style={{marginTop: 10}}>
          4.8. Салбар дээр очиж үнэлгээ хийх тохиолдолд унаа, байрлах байр зэрэг
          зардлуудыг хариуцна.
        </Text>
        <Text style={{marginTop: 10}}>
          4.9. Баталгаажуулалт хийлгэсэн тухайгаа олон нийтэд зарлаж мэдээлэхдээ
          МУ-ын “Зар сурталчилгааны хууль”-ийн хүрээнд сурталчилгааг хийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          4.10. Баталгаажуулалтын тэмдэгийг “Баталгаажуулалтын тэмдэг ашиглах
          заавар”-ын дагуу зориулалтын дагуу зөв ашиглана.
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.10.1. Баталгаажуулалтын тэмдэгийг бүтээгдэхүүний баглаа боодол,
          бүтээгдэхүүний шошго, бичгийн хэрэгслүүд, ажлын тайлан, зар
          сурталчилгаа, урамшууллын мэдээллүүд, байгууллагын дотор хана,
          мэдээллийн самбарууд, үзэсгэлэнгийн танилцуулга, вебсайт зэрэгт
          байршуулж мэдээллийн журмаар ашиглаж болно.
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.10.2. Баталгаажуулалтын тэмдэгийг автомашин, туг далбаа,
          бүтээгдэхүүн, бүтээгдэхүүн зэрэг дээр байршуулж болохгүй.
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.10.3. Баталгаажуулалтын байгууллагын нэр хүндийг унагаах байдлаар
          бүтээгдэхүүний баталгаажуулалтын тэмдгийг хэрэглэхгүй байх, түүнчлэн
          баталгаажуултын байгууллагыг хуурамч буюу эрх бүхий бус гэж үзэх
          тохиолдолд түүний бүтээгдэхүүний баталгаажуулалтын талаар аливаа
          мэдэгдэл хийхгүй байх;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.10.4. Баримт бичиг, танилцуулга буюу сурталчилгааны материал зэрэг
          мэдээллийн хэрэгсэлд бүтээгдэхүүний баталгаажуулалтыг иш татахдаа
          баталгаажуулалтын байгууллагын шаардлага буюу баталгаажуулалтын схемд
          заасан шаардлагыг хангах;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.10.5. Тохирлын тэмдгийн хэрэглээ болон бүтээгдэхүүний талаарх
          мэдээлэлд холбогдолтой баталгаажуулалтын схемд заасан байж болох
          аливаа шаардлагыг хангах.
        </Text>
        <Text style={{marginTop: 10}}>
          4.11. Баталгаажуулалт хийгдсэний дараа баталгаажуулалтын схемийн дагуу
          тодорхойлсон стандарт буюу бусад норматив баримт бичигт заасан тухайн
          бүтээгдэхүүнд шууд хамаарах шаардлагыг хангаж үйл ажиллагаагаа
          явуулна.
        </Text>
        <Text style={{marginTop: 10}}>
          4.12. Үйлчлүүлэгч нь баталгаажуулалтын шаардлагыг хангахад
          холбогдолтой бүх гомдлыг бүртгэх ба уг бүртгэлийг баталгаажуулалтын
          байгууллагаас шаардсан үед бэлэн байлгах:
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.12.1. эдгээр гомдлын талаар болон баталгаажуулалтын шаардлагыг
          хангахад нөлөөлж буй бүтээгдэхүүний аливаа гологдлын талаар холбогдох
          арга хэмжээ авах;
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          4.12.2. авсан арга хэмжээг баримтжуулах.
        </Text>
        <Text style={{marginTop: 10}}>
          4.13. Баталгаажуулалтыг түдгэлзүүлэх, цуцлах буюу зогсоох үед
          баталгаажуулалтыг аливаа байдлаар иш татсан агуулгатай бүх
          сурталчилгааг хэрэглэхгүй байх, баталгаажуулалтын схемийн шаардлагын
          дагуу арга хэмжээ авах (жишээлбэл, баталгаажуулалтын баримт бичгийг
          буцаах) буюу шаардлагатай бусад арга хэмжээ авна.
        </Text>
        <Text style={{marginTop: 10}}>
          4.14. Баталгаажуулалтын баримт бичгийн хуулбарыг бусдад өгөх бол
          тэдгээрийг бүрэн байдлаар буюу баталгаажуулалтын схемд заасны дагуу
          хуулбарлах үүрэгтэй.
        </Text>
        <Text style={{marginTop: 10}}>
          4.15. Үйлчлүүлэгч нь ХААЗД-ын гэрчлэх тэмдэгийг баталгаажуулалтад
          хамрагдсан талбай, таримлын төрөлд ашиглах ба баталгаажуулалтад
          хамрагдаагүй таримал бүтээгдэхүүн, үйлдвэрлэлд хамаатай мэтээр ойлголт
          өгөхийг хориглох ба гэрчлэх тэмдэгийг баталгаажуулалтын гэрчилгээнй
          хүчинтэй хугацаанд хэрэглэнэ.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          5. ГЭРЭЭНИЙ ҮНЭ, ТӨЛБӨР, ТООЦОО ХИЙХ ЖУРАМ
        </Text>
        <Text style={{marginTop: 10}}>5.1. Энэхүү гэрээний 2.3.1-т заасан</Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          - анхдагч баталгаажуулалтын үнэлгээний төлбөр{' '}
          <Text style={{fontWeight: 'bold'}}>
            1 100 000[нэг сая нэг зуун мянган(НӨАТ орсон)] төгрөг,
          </Text>
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          - 2024 оны магадлах аудитын төлбөр{' '}
          <Text style={{fontWeight: 'bold'}}>
            880 000[найман зуун наян мянган(НӨАТ орсон)] төгрөг,
          </Text>
        </Text>
        <Text style={{marginTop: 10, marginLeft: 20}}>
          - 2025 оны магадлах аудитын төлбөр{' '}
          <Text style={{fontWeight: 'bold'}}>
            704 000[долоон зуун дөрвөн мянган(НӨАТ орсон)] төгрөг
          </Text>{' '}
          тус тус байна.
        </Text>
        <Text style={{marginTop: 10}}>
          5.2. Баталгаажуулалтын үнэлгээний төлбөрийн 50%-ийн урьдчилгааг
          баталгаажуулалтын үнэлгээ хийхээс ажлын 5 хоногийн өмнө
          Баталгаажуулалтын байгууллагын дансанд хийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          5.3. Баталгаажуулалтын зөвлөлийн хурлын өмнө баталгаажуулалтын
          үнэлгээний төлбөрийн үлдэгдэл 50%-ийг Баталгаажуулалтын байгууллагын
          дансанд нэхэмжлэлийн дагуу хийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          5.4. Гэрээний 2.3.2, 2.3.3-т заасан магадлах
          үнэлгээнийтөлбөрийгмагадлах үнэлгээ хийхээс ажлын 7 хоногийн өмнө
          Баталгаажуулалтын байгууллагын дансанд нэхэмжлэлийн дагуу хийнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          5.5. Гэрээний 6.1-д заасны дагуу гэрээг цуцалсан үед Үйлчлүүлэгчгэрээг
          цуцлах өдрөөс өмнө зохих ёсоор ажил гүйцэтгэсэн бол тухайн ажлын
          гүйцэтгэлд тохирсон ажлын хөлсийгБаталгаажуулалтын байгууллагад төлнө.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          6. ГЭРЭЭ ЦУЦЛАХ
        </Text>
        <Text style={{marginTop: 10}}>
          6.1. Гэнэтийн болон давагдашгүй хүчин зүйл үүссэний улмаас
          Баталгаажуулалтын байгууллага нь 60-аас дээш хоногийн хугацаанд
          үйлчилгээний голлох хэсгийг гүйцэтгэх боломжгүй болсоннөхцөл байдал
          үүссэн тохиолдолд Үйлчлүүлэгч өөрийн санаачлагаар гэрээг цуцлах
          эрхтэй.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          7. ГЭНЭТИЙН БОЛОН ДАВАГДАШГҮЙ ХҮЧИН ЗҮЙ
        </Text>
        <Text style={{marginTop: 10}}>
          7.1. Дайн, иргэний бослого, түймэр, үер, халдварт өвчин, газар
          хөдлөлт, хорио цээр, тээврийн хориг болон бусад гэнэтийн болон
          давагдашгүй хүчний нөхцөл байдал үүссэнээс Үйлчлүүлэгчийн гэрээний
          үүргийн гүйцэтгэл хугацаа хоцорсон бол талууд тохиролцон гүйцэтгэх
          хугацааг сунгана.
        </Text>
        <Text style={{marginTop: 10}}>
          7.2. Гэнэтийн болон давагдашгүй хүчний нөхцөл байдал үүссэн тохиолдолд
          Баталгаажуулалтын байгууллага нь энэ нөхцөл байдал болон үүссэн
          шалтгааны талаар Үйлчлүүлэгчид нэн даруй бичгээр мэдэгдэнэ.
          Үйлчлүүлэгч бичгээр өөрөөр зааварлаагүй бол Баталгаажуулалтын
          байгууллага нь гэрээний үүргээ боломжтой хэмжээнд үргэлжлүүлэн
          гүйцэтгэж, гэнэтийн болон давагдашгүй хүчний нөхцөл байдлаас үл
          шалтгаалан гэрээг хэрэгжүүлэх боломжит арга замыг хайна.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          8. ШУДАРГА БАЙДАЛ
        </Text>
        <Text style={{marginTop: 10}}>
          8.1. Талууд энэхүү гэрээний дагуу үйл ажиллагаа явуулахдаа бие
          биенийхээ эрхийг хүндэтгэн, шударга байдлаар хандах ба гэрээний
          зорилгыг хэрэгжилтийг хангахын тулд бүхий л зохистой арга хэмжээг
          авна.
        </Text>
        <Text
          style={{
            marginTop: 20,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          9. МАРГААНЫГ ШИЙДВЭРЛЭХ
        </Text>
        <Text style={{marginTop: 10}}>
          9.1. Маргаанаас зайлсхийх, эсхүл түүнийг цаг алдалгүй шийдвэрлэх нь
          гэрээний гүйцэтгэлийг сайтар хангах болон төсөл арга хэмжээний
          амжилтанд ихээхэн чухал гэдгийг талууд хүлээн зөвшөөрнө.
        </Text>
        <Text style={{marginTop: 10}}>
          9.2. Гэрээтэй холбоотой аливаа маргааныг талууд харилцан тохиролцож,
          эв зүйгээр шийдвэрлэхийг эрмэлзэнэ.
        </Text>
        <Text style={{marginTop: 10}}>
          9.3. Талууд гэрээний холбоотой аливаа маргааныг эв зүйгээр шийдвэрлэж
          чадахгүй тохиолдолд аль нэг тал нь маргааныг шийдвэрлэх арга
          хэрэгслийг сонгох эрхтэй.
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            gap: 10,
          }}
        >
          <View style={{flex: 1}}>
            <Text style={{fontWeight: 'bold'}}>
              БАТАЛГААЖУУЛАЛТЫН БАЙГУУЛЛАГЫГ ТӨЛӨӨЛЖ:
            </Text>
            <Text style={{marginTop: 20}}>
              Баталгаажуулалтын төвийн захирал:
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 5,
              }}
            >
              <Text>Н.Нарангэрэл</Text>
              <Image
                src="https://cdn.finy.app/files%2F1661006367591_small.jpg"
                style={{
                  height: 30,
                  width: 30,
                  objectFit: 'cover',
                  marginLeft: 20,
                }}
              />
            </View>
            <Text style={{marginTop: 10}}>
              Утас: 94999347, 94005015, 99845867
            </Text>
            <Text style={{marginTop: 5}}>
              И-мэйл: sfscsertification1@gmail.com
            </Text>
            <Text style={{marginTop: 5}}>Хаан банк(төгрөгийн): 5434033550</Text>
            <Text style={{marginTop: 5}}>РД: 5830214</Text>
            <Text style={{marginTop: 5}}>Хаяг: Баянгол дүүрэг, 2 хороо,</Text>
            <Text>2 хороолол, 63 Б байр, 1 тоот</Text>
          </View>
          <View style={{flex: 1}}>
            <Text style={{fontWeight: 'bold'}}>
              {contract.userType === 'citizen'
                ? 'ӨРХИЙН ТАРИАЛАНЧЫГ'
                : `${_.get(
                    contract,
                    'farmerData.name',
                    '',
                  ).toUpperCase()}-ИЙГ`}{' '}
              ТӨЛӨӨЛЖ:
            </Text>
            <Text style={{marginTop: 20}}>
              {contract.userType === 'citizen' ? 'Иргэн' : 'Менежер'}:
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 5,
              }}
            >
              <Text>
                {contract.userType === 'citizen'
                  ? _.get(contract, 'farmerData.name', '')
                  : _.get(contract, 'farmerData.manager', '')}
              </Text>
              <Image
                src={contract.farmerSignatureUrl}
                style={{
                  height: 30,
                  width: 30,
                  objectFit: 'cover',
                  marginLeft: 20,
                }}
              />
            </View>
            <Text style={{marginTop: 10}}>
              Утас: {_.get(contract, 'farmerData.phoneNumber', '')}
            </Text>
            {!_.isEmpty(_.get(contract, 'farmerData.email')) && (
              <Text style={{marginTop: 5}}>
                Имейл: {_.get(contract, 'farmerData.email', '')}
              </Text>
            )}
            <Text style={{marginTop: 5}}>
              Регистрийн дугаар: {_.get(contract, 'farmerData.orgRegister', '')}
            </Text>
            <Text style={{marginTop: 5}}>
              Тариалангийн талбайн хаяг:{' '}
              {_.get(contract, 'farmerData.fieldAddress', '')}
            </Text>
          </View>
        </View>
      </View>
      {download && (
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            height: 30,
          }}
        ></View>
      )}
    </Page>
  );
};

export default CertContractDocument;
